import { useTranslation } from "react-i18next";
import { EMPTY_TIME } from "../../constants";
import { formatTime, isNullOrUndefined } from "../../utils";
import CrewInfo from "../CrewInfo";
import { getUserInfo } from "../../userUtils";
import { useMainContext } from "../../MainContext";
function TurnaroundCrew(props) {
  const {
    crewInfo,
    airportTimezone,
    selected,
    isSelectable,
    onClick,
    showCertifications,
    showActions,
    showCrewStatus,
  } = props;
  const mainContext = useMainContext();
  const { positions, turnarounds } = mainContext;
  if (isNullOrUndefined(crewInfo)) return null;
  const userInfo = getUserInfo(crewInfo.user, turnarounds, positions);

  return (
    <div
      className={`turnaround-crew${selected ? " selected" : ""}${
        isSelectable ? " selectable" : ""
      }`}
      key={userInfo?.user.uuid}
      data-uuid={userInfo?.user.uuid}
      onClick={() => {
        if (isSelectable) {
          onClick();
        }
      }}
    >
      {userInfo && userInfo.user && (
        <CrewInfo
          user={userInfo.user}
          showActions={showActions}
          details={
            <CrewDetails
              crewInfo={crewInfo}
              airportTimezone={airportTimezone}
              showCertifications={showCertifications}
              showCrewStatus={showCrewStatus}
            />
          }
          mapStatus={userInfo.mapStatus}
        />
      )}
    </div>
  );
}

// Content to insert into the "details" for the CrewInfo component
function CrewDetails(props) {
  const { crewInfo, airportTimezone, showCertifications, showCrewStatus } =
    props;
  const { t } = useTranslation();
  if (isNullOrUndefined(crewInfo)) return null;
  const hasInitiated = !isNullOrUndefined(crewInfo?.userRecord?.startTime);
  const hasCompleted = !isNullOrUndefined(crewInfo?.userRecord?.endTime);
  const hasAccepted = !isNullOrUndefined(crewInfo?.acceptedAt);

  // let statusClass
  let statusText;
  if (hasCompleted) {
    // statusClass = "completed";
    statusText = t("completed_non_cap");
  } else if (hasInitiated) {
    // statusClass = "in-progress";
    statusText = t("in_progress");
  } else if (hasAccepted) {
    // statusClass = "accepted";
    statusText = t("accepted_non_cap");
  } else {
    // statusClass = "pending";
    statusText = t("pending_non_cap");
  }
  return (
    <>
      {showCertifications && (
        <div>
          <div className="certification-name">
            <div>{crewInfo.certificationName}</div>
          </div>
        </div>
      )}
      {showCrewStatus && <span className="status">{statusText}</span>}
      <span>
        {t("arrived_cap_colon")}&nbsp;
        <span className="time-initiated">
          {hasInitiated
            ? formatTime(crewInfo?.userRecord?.startTime, airportTimezone)
            : EMPTY_TIME}
        </span>
      </span>
      <span>
        {t("departed_cap_colon")}&nbsp;
        <span className="time-completed">
          {hasCompleted
            ? formatTime(crewInfo?.userRecord?.endTime, airportTimezone)
            : EMPTY_TIME}
        </span>
      </span>
    </>
  );
}

export default TurnaroundCrew;
