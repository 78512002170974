import moment from "moment-timezone";
import { ReactComponent as Delay } from "../../assets/turnarounds/delay_small.svg";
import { formatHoursMins, isBlank, isNullOrUndefined } from "../../utils";
import {
  TurnaroundOperationStatus,
  getOperationInfoListForTurnaround,
} from "../../turnaroundUtils";
import { ReactComponent as Close } from "../../assets/close.svg";
import { EMPTY_TIME } from "../../constants";
import { Tooltip, Fade } from "@mui/material";
import { tooltipStyles } from "../../styleUtils";
import OperationStatusIcon from "./OperationStatusIcon";
import { useTranslation } from "react-i18next";

function TurnaroundTimelineBar(props) {
  const {
    item,
    airport,
    handleClick,
    isSelected,
    handleOperationClick,
    handleCloseClick,
    selectedOperation,
    timelineMetadata,
  } = props;
  const { t } = useTranslation();
  const { timezone = "" } = airport;
  const mCurrentTime = moment().tz(timezone).startOf("minute");
  const mGtatStart = !isNullOrUndefined(item.gtatStart)
    ? moment(item.gtatStart).tz(timezone).startOf("minute")
    : null;

  let mGtatEnd = !isNullOrUndefined(item.gtatEnd)
    ? moment(item.gtatEnd).tz(timezone).startOf("minute")
    : null;

  let mGtatExpectedStartTime = !isNullOrUndefined(item.gtatExpectedStartTime)
    ? moment(item.gtatExpectedStartTime).tz(timezone).startOf("minute")
    : null;

  const isTurnaroundInProgress = !isNullOrUndefined(mGtatStart)
    ? mGtatStart.isBefore(mCurrentTime) && item.percentComplete < 100
    : false;
  if (isTurnaroundInProgress) {
    // set the in-progress turnaround to appear up to the current time
    mGtatEnd = mCurrentTime;
  }

  const operationsList = getOperationInfoListForTurnaround(item, timezone);
  const hasDelayedOperations = operationsList.some(
    (operationInfo) =>
      operationInfo?.status.statusId ===
        TurnaroundOperationStatus.COMPLETED_LATE ||
      operationInfo?.status.statusId ===
        TurnaroundOperationStatus.IN_PROGRESS_LATE ||
      operationInfo?.status.statusId === TurnaroundOperationStatus.MISSING ||
      operationInfo?.status.statusId ===
        TurnaroundOperationStatus.COMPLETED_PARTIALLY
  );

  // Gate-in should already take into account the Snap-time
  const mGateIn = moment(item.gateIn).tz(timezone).startOf("minute");
  const mGateOut = moment(item.gateOut).tz(timezone).startOf("minute");
  const mGtatDiff =
    !isNullOrUndefined(mGtatStart) && !isNullOrUndefined(mGtatEnd)
      ? moment.duration(mGtatEnd.diff(mGtatStart))
      : null;
  const mGateDuration = moment.duration(mGateOut.diff(mGateIn)).as("minutes");
  let gtatDurationMinutes = !isNullOrUndefined(mGtatDiff)
    ? parseInt(mGtatDiff.as("minutes"))
    : 0;

  const durationDisplay = formatHoursMins(gtatDurationMinutes);

  /** NOTE: Disabling red bar indicators from the start of turnaround, using zero 
  const mGtatExpectedStartTimeDiff =
    !isNullOrUndefined(mGtatStart) && !isNullOrUndefined(mGtatExpectedStartTime)
      ? moment.duration(mGtatStart.diff(mGtatExpectedStartTime))
      : null;

   */
  const mGtatExpectedStartTimeDiff = null;
  const gtatDurationFromExpectedMinutes = !isNullOrUndefined(
    mGtatExpectedStartTimeDiff
  )
    ? parseInt(mGtatExpectedStartTimeDiff.as("minutes"))
    : 0;
  if (gtatDurationFromExpectedMinutes > 0) {
    gtatDurationMinutes = gtatDurationMinutes + gtatDurationFromExpectedMinutes;
  }

  const styleGtat = {
    marginLeft: !isNullOrUndefined(mGtatStart)
      ? getTimeAsPixels(mGtatStart, timelineMetadata)
      : 0,
    width: getDurationAsPixels(gtatDurationMinutes, timelineMetadata),
  };

  // If the turnaround does not have an outbound, the duration will be NaN
  // By default, these will be set to 1 hour duration
  const gateDurationMins = isNaN(mGateDuration) ? 60 : mGateDuration;
  const styleGate = {
    marginLeft: getTimeAsPixels(mGateIn, timelineMetadata),
    width: getDurationAsPixels(gateDurationMins, timelineMetadata),
  };
  const styleDelay = {
    width: 0,
  };
  if (!isSelected) {
    styleGtat.height = "27px";
    styleGtat.marginTop = "-27px";
  } else {
    styleGate.opacity = "0.5";
    styleGate.height = `${(27 + 8) * (operationsList.length + 1)}px`;
    styleGtat.position = "absolute";
    styleGtat.top = "0";
  }
  if (isTurnaroundInProgress) {
    styleGtat.borderTopRightRadius = "0";
    styleGtat.borderBottomRightRadius = "0";
  }
  if (gtatDurationFromExpectedMinutes > 0) {
    const gtatDurationFromExpectedPixels = getDurationAsPixels(
      gtatDurationFromExpectedMinutes,
      timelineMetadata
    );
    styleGtat.marginLeft = getTimeAsPixels(
      mGtatExpectedStartTime,
      timelineMetadata
    );
    if (!isSelected) {
      styleDelay.width = gtatDurationFromExpectedPixels;
      styleGtat.gridTemplateColumns = "auto 1fr";
    }
  }

  const flightTitle = item.combinedFlightName;
  const flightDetails = (
    <>
      <div>{flightTitle}</div>
      <div>
        {`\n${t("gate_in_out")}: ${
          !isBlank(item.gateIn) ? mGateIn.format("MMM DD HH:mm") : ""
        } - ${
          !isBlank(item.gateOut) ? mGateOut.format("MMM DD HH:mm") : EMPTY_TIME
        }`}
      </div>
      {!isNullOrUndefined(mGtatStart) && !isNullOrUndefined(mGtatEnd) && (
        <div>
          {`${t("ground_turnaround_time")}: ${mGtatStart.format(
            "MMM DD HH:mm"
          )} - ${mGtatEnd.format("MMM DD HH:mm")}`}
        </div>
      )}
      {!isNullOrUndefined(mGtatExpectedStartTime) &&
        gtatDurationFromExpectedMinutes > 0 && (
          <div>
            {t("expected_start")}:
            {` ${mGtatExpectedStartTime.format("MMM DD HH:mm")}`}
            <span className="danger">{` (-${formatHoursMins(
              gtatDurationFromExpectedMinutes
            )})`}</span>
          </div>
        )}
      <div>
        {t("duration")}: {durationDisplay}
      </div>
      <div>{`${t("status")}: ${item.statusDisplayName} (${
        item.percentComplete
      }%)`}</div>
    </>
  );

  return (
    <div
      id={`turnaround-${item.uuid}`}
      className={`turnaround-timeline-bar-container${
        isSelected ? " selected" : ""
      }`}
    >
      <Tooltip
        title={isSelected ? null : flightDetails}
        placement="top-start"
        componentsProps={tooltipStyles}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        arrow
      >
        <div
          className="turnaround-timeline-bar gate"
          style={styleGate}
          onClick={
            !isSelected
              ? (e) => {
                  if (!isNullOrUndefined(handleClick)) {
                    handleClick(item.uuid, getInnerElement(e));
                  }
                }
              : null
          }
        >
          <span>{gtatDurationMinutes === 0 ? flightTitle : <>&nbsp;</>}</span>
        </div>
      </Tooltip>
      {gtatDurationMinutes > 0 && (
        <Tooltip
          title={flightDetails}
          placement="top-start"
          componentsProps={tooltipStyles}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
          arrow
        >
          <div
            className={`turnaround-timeline-bar gtat ${
              isTurnaroundInProgress ? "in-progress" : ""
            }`}
            style={styleGtat}
            onClick={
              !isSelected
                ? (e) => {
                    if (!isNullOrUndefined(handleClick)) {
                      handleClick(item.uuid, getInnerElement(e));
                    }
                  }
                : null
            }
          >
            {gtatDurationFromExpectedMinutes > 0 && !isSelected && (
              <div className="turnaround-timeline-delay" style={styleDelay}>
                <span></span>
              </div>
            )}
            <div className="turnaround-timeline-main">
              <div
                className="turnaround-timeline-bar-title"
                data-turnaround-id={item.uuid}
              >
                <div>
                  <span>{flightTitle}</span>

                  {hasDelayedOperations > 0 && (
                    <div>
                      <Delay className="delay-indicator" />
                    </div>
                  )}
                </div>
                <div>
                  {isSelected && (
                    <Close
                      alt="Close"
                      onClick={() => {
                        handleCloseClick();
                      }}
                    />
                  )}
                </div>
              </div>
              {
                // Sub-bars for each operation in the turnaround
                isSelected &&
                  operationsList &&
                  operationsList.map((operation) => {
                    const gtatDelayMinutes =
                      gtatDurationFromExpectedMinutes > 0
                        ? gtatDurationFromExpectedMinutes
                        : 0;
                    const styleOperation = {
                      height: "27px",
                      marginLeft: getDurationAsPixels(
                        operation.startOffsetMinutes + gtatDelayMinutes,
                        timelineMetadata
                      ),
                      width: getDurationAsPixels(
                        operation.durationMinutes,
                        timelineMetadata
                      ),
                    };
                    if (operation.isInProgress) {
                      styleOperation.borderTopRightRadius = "0";
                      styleOperation.borderBottomRightRadius = "0";
                    }
                    const styleOperationDelay = {};
                    const styleOperationMain = {};
                    let showDelayStart = false;
                    let showDelayEnd = false;
                    // Delay
                    if (operation.minutesFromExpectedStartTime > 0) {
                      styleOperation.marginLeft = getDurationAsPixels(
                        operation.startOffsetMinutes +
                          gtatDurationFromExpectedMinutes -
                          operation.minutesFromExpectedStartTime,
                        timelineMetadata
                      );
                      styleOperation.width = getDurationAsPixels(
                        operation.durationMinutes +
                          operation.minutesFromExpectedStartTime,
                        timelineMetadata
                      );
                      styleOperation.gridTemplateColumns = "auto 1fr";

                      styleOperationDelay.width = getDurationAsPixels(
                        operation.minutesFromExpectedStartTime,
                        timelineMetadata
                      );
                      if (operation.durationMinutes === 0) {
                        styleOperationDelay.borderTopRightRadius = "6px";
                        styleOperationDelay.borderBottomRightRadius = "6px";
                      }
                      styleOperationDelay.borderTopLeftRadius = "6px";
                      styleOperationDelay.borderBottomLeftRadius = "6px";
                      showDelayStart = true;
                    } else if (operation.minutesFromExpectedEndTime > 0) {
                      styleOperation.width = getDurationAsPixels(
                        operation.durationMinutes,
                        timelineMetadata
                      );
                      styleOperation.gridTemplateColumns = "auto auto";

                      styleOperationMain.position = "relative";
                      const mainDuration =
                        operation.durationMinutes >=
                        operation.minutesFromExpectedEndTime
                          ? operation.durationMinutes -
                            operation.minutesFromExpectedEndTime
                          : 0;
                      const delayDuration =
                        operation.durationMinutes - mainDuration;
                      styleOperationMain.width = getDurationAsPixels(
                        mainDuration,
                        timelineMetadata
                      );
                      styleOperationDelay.width = getDurationAsPixels(
                        delayDuration,
                        timelineMetadata
                      );
                      styleOperationDelay.borderTopRightRadius = "6px";
                      styleOperationDelay.borderBottomRightRadius = "6px";
                      if (mainDuration === 0) {
                        styleOperationDelay.borderTopLeftRadius = "6px";
                        styleOperationDelay.borderBottomLeftRadius = "6px";
                      }
                      showDelayEnd = true;
                    }
                    return (
                      <Tooltip
                        title={operation.titleText}
                        placement="left"
                        arrow
                        componentsProps={tooltipStyles}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 500 }}
                        key={operation.uuid}
                      >
                        <div
                          id={operation.uuid}
                          className={`turnaround-operation-bar${
                            selectedOperation === operation.uuid
                              ? " selected"
                              : ""
                          }`}
                          style={styleOperation}
                          onClick={(e) => {
                            handleOperationClick(
                              operation.originalOperation,
                              getInnerElement(e)
                            );
                          }}
                        >
                          {showDelayStart && (
                            <>
                              <div
                                className="turnaround-timeline-operation-delay"
                                style={styleOperationDelay}
                              >
                                <span></span>
                              </div>
                              <div className="turnaround-timeline-operation-main">
                                <span>{operation.name}</span>
                              </div>
                            </>
                          )}
                          {showDelayEnd && (
                            <>
                              <div
                                className="turnaround-timeline-operation-main"
                                style={styleOperationMain}
                              >
                                <span>{operation.name}</span>
                              </div>
                              <div
                                className="turnaround-timeline-operation-delay"
                                style={styleOperationDelay}
                              >
                                <span></span>
                              </div>
                            </>
                          )}
                          {!showDelayStart && !showDelayEnd && (
                            <div className="turnaround-timeline-operation-main">
                              <span>{operation.name}</span>

                              {operation?.status?.statusId ===
                                TurnaroundOperationStatus.MISSING && (
                                <OperationStatusIcon
                                  status={operation?.status}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    );
                  })
              }
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

function getInnerElement(e) {
  // Return the span element as a reference element
  return e.target.tagName === "SPAN"
    ? e.target
    : e.target.getElementsByTagName("span")[0];
}

function getTimeAsPixels(mTimeVal, timelineMetadata) {
  const { pixelsPerMinute, minTime } = timelineMetadata;
  return `${
    parseInt(moment.duration(mTimeVal.diff(minTime)).as("minute")) *
    pixelsPerMinute
  }px`;
}

function getDurationAsPixels(durationMins, timelineMetadata) {
  const { pixelsPerMinute } = timelineMetadata;
  if (durationMins < 0) durationMins = 0;
  return `${(durationMins * pixelsPerMinute).toFixed(2)}px`;
}
export default TurnaroundTimelineBar;
