import { useMemo } from "react";
import { useCommsContext } from "../../CommsContext";
import { formatTime, getMapFromList, isNullOrUndefined } from "../../utils";
import CrewInfo from "../CrewInfo";
import { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import { getUserInfo } from "../../userUtils";
import { useMainContext } from "../../MainContext";
import moment from "moment-timezone";
import { isSeenByUser } from "../../commsUtils";
import ChatBubble from "./ChatBubble";
import { useTranslation } from "react-i18next";

function ChatMessage(props) {
  const { t } = useTranslation();
  const { message, channel, onImageLoaded, index } = props;
  const commsContext = useCommsContext();
  const { commsUsers, positions } = commsContext;
  const mainContext = useMainContext();
  const { currentUser, turnaroundsForDispatch } = mainContext;

  const airportTimezone = currentUser?.airport?.timezone;
  const isIncomingMessage = currentUser.uuid !== message.sentBy;
  const isOutgoingMessage = currentUser.uuid === message.sentBy;
  const isSystemMessage = isNullOrUndefined(message.sentBy);

  const commsUsersByUuid = useMemo(() => {
    if (!isNullOrUndefined(commsUsers)) {
      return getMapFromList(commsUsers, "uuid");
    }
    return {};
  }, [commsUsers]);

  const commsUser = commsUsersByUuid[message.sentBy];
  const commsUserInfo = !isNullOrUndefined(commsUser)
    ? getUserInfo(commsUser, turnaroundsForDispatch, positions)
    : null;
  let isSeenByMe = false; // incoming
  let isSeenByOthers = true; // outgoing
  if (isIncomingMessage) {
    isSeenByMe = isSeenByUser(channel, message, currentUser, airportTimezone);
  } else if (isOutgoingMessage) {
    for (let i = 0; isSeenByOthers && i < channel.members.length; i++) {
      const member = channel.members[i];
      if (member.userUuid !== currentUser.uuid) {
        // Check this user
        const lastReadTime = moment(member.lastRead).tz(airportTimezone);
        if (!lastReadTime.isAfter(message.sentAt)) {
          isSeenByOthers = false;
        }
      }
    }
  }

  return (
    <>
      {isIncomingMessage && !isNullOrUndefined(commsUserInfo) && (
        <div className="chat-message incoming">
          <div>
            <div className="chat-message-userpic">
              <CrewInfo
                user={commsUserInfo?.user}
                mapStatus={commsUserInfo?.mapStatus}
                userPicSize={UserPicSize.LARGE}
                userPicTheme={UserPicTheme.DARK}
                hideInfo={true}
              />
            </div>
          </div>
          <div className="chat-message-content">
            <div className="chat-message-sender">{commsUserInfo.fullName}</div>
            <div className="chat-message-text">
              <ChatBubble message={message} onLoaded={onImageLoaded} />
            </div>
            <div className="chat-message-timestamp">{`${formatTime(
              message.sentAt,
              airportTimezone
            )}${isSeenByMe ? ` • ${t("seen")}` : ""}`}</div>
          </div>
        </div>
      )}
      {isOutgoingMessage && (
        <div className="chat-message outgoing">
          <div className="chat-message-text">
            <div>
              <ChatBubble
                message={message}
                onLoaded={onImageLoaded}
                index={index}
              />
            </div>
          </div>
          <div className="chat-message-timestamp">{`${formatTime(
            message.sentAt,
            airportTimezone
          )}${isSeenByOthers ? ` • ${t("seen")}` : ""}`}</div>
        </div>
      )}
      {isSystemMessage && (
        <div className="chat-system">
          <div>{message.content.text}</div>
        </div>
      )}
    </>
  );
}

export default ChatMessage;
