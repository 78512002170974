import "./styles.css";
import { ReactComponent as InboundWarning } from "../../assets/alerts/StatusInbound.svg";
import { ReactComponent as Pushback } from "../../assets/alerts/StatusPushback.svg";
import { ReactComponent as ServiceInitiated } from "../../assets/alerts/StatusInitiated.svg";
import { ReactComponent as Info } from "../../assets/alerts/StatusGateChange.svg";
import { ReactComponent as Takeoff } from "../../assets/alerts/StatusOutbound.svg";

function AlertIcon(props) {
  // Sync with https://github.com/Moonware-Inc/halo/blob/develop/src/web-api/enums/user_alert_type.go
  // InboundWarning   UserAlertType = 1
  // Pushback         UserAlertType = 2
  // ServiceInitiated UserAlertType = 3
  // Info             UserAlertType = 4
  // Takeoff          UserAlertType = 5
  const { userAlertTypeId } = props;
  return (
    <>
      {userAlertTypeId === 1 && <InboundWarning />}
      {userAlertTypeId === 2 && <Pushback />}
      {userAlertTypeId === 3 && <ServiceInitiated />}
      {userAlertTypeId === 4 && <Info />}
      {userAlertTypeId === 5 && <Takeoff />}
    </>
  );
}

export default AlertIcon;
