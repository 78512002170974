import { useMainContext } from "../../MainContext";
import { useState } from "react";
import UserPic, { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import ProfileImageUpload from "./ProfileImageUpload";
import { useTranslation } from "react-i18next";

function EditProfile() {
  const { t } = useTranslation();
  const mainContext = useMainContext();
  const { currentUser, profilePictureCacheBuster } = mainContext;
  const [firstName, setFirstName] = useState(currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser.lastName);
  const [email, setEmail] = useState(currentUser.email);
  const [isDisabled] = useState(true);

  return (
    <div className="edit-profile">
      <div>
        <div>
          <div className="settings-form-header">
            <h3>{t("edit_profile")}</h3>
            <span>{t("detailed_update_contact")}</span>
            <div className="user-pic-row">
              <div>
                <UserPic
                  size={UserPicSize.XLARGE}
                  theme={UserPicTheme.DARK}
                  user={currentUser}
                  cacheBuster={profilePictureCacheBuster}
                  fullSize={true}
                />
              </div>
              <div>
                <ProfileImageUpload />
              </div>
            </div>
          </div>
        </div>
        <div className="settings-form">
          <div className="field-row two">
            <div>
              <label>{t("first_name")}</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={`${isDisabled ? "locked-input" : ""}`}
                disabled={isDisabled}
              />
            </div>
            <div>
              <label>{t("last_name")}</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={`${isDisabled ? "locked-input" : ""}`}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="field-row ">
            <div>
              <label>{t("work_email")}</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${isDisabled ? "locked-input" : ""}`}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="field-row actions">
            <div>
              <button disabled={isDisabled}>{t("save")}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
