import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { useTranslation } from "react-i18next";

function TemplateRow(props) {
  const { t } = useTranslation();
  const { template, onSelect, showEdit, showView } = props;
  const airline = template?.airline ? template?.airline.name : "-";
  const airport = template?.airport ? template?.airport.iata : "-";
  const aircraft = template?.aircraftType ? template?.aircraftType.name : "-";
  return (
    <div className="listing-row">
      <div>{aircraft}</div>
      <div>{airline}</div>
      <div>{airport}</div>
      <div className="actions">
        <div>
          <div className="button-icon">
            {showEdit && (
              <Pencil
                onClick={() => {
                  onSelect(template);
                }}
              />
            )}
            {showView && (
              <button
                className="secondary"
                onClick={() => {
                  onSelect(template);
                }}
              >
                {t("view")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateRow;
