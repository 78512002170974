import { createContext, useContext } from "react";

export const AlertContext = createContext(null);
export const AlertDispatchContext = createContext(null);

// Convenience methods that can be used in components
export function useAlertContext() {
  return useContext(AlertContext);
}

export function useAlertDispatchContext() {
  return useContext(AlertDispatchContext);
}
