import { useTranslation } from "react-i18next";
import { DEFAULT_START_TIME_OFFSET } from "../../constants";
import { selectInputFix } from "../../styleUtils";
import { OperationOffsetType } from "../../turnaroundUtils";

function StartTimeOffset(props) {
  const { t } = useTranslation();
  const { operationToEdit, disabled, onChange } = props;

  let offsetTypeRelativeTo = null;
  if (OperationOffsetType.INBOUND === operationToEdit?.offsetTypeId) {
    offsetTypeRelativeTo = "arrival";
  } else {
    offsetTypeRelativeTo = "departure";
  }
  function handleValueModifierChange(value) {
    onChange(value * offsetValue);
  }

  function handleValueChange(value) {
    onChange(offsetValueModifier * value);
  }

  const offsetValueModifier =
    operationToEdit?.startOffsetMinutes < 0 ? "-1" : "1";
  const offsetValue = Math.abs(operationToEdit?.startOffsetMinutes);

  return (
    <div className="start-time-offset">
      <input
        type="text"
        value={offsetValue}
        onKeyDown={(e) => {
          if (e.key.length === 1) {
            const keyVal = parseInt(e.key);
            if (isNaN(keyVal)) {
              e.preventDefault();
            }
          }
        }}
        onBlur={(e) => {
          const val = parseInt(e.target.value);
          if (isNaN(val)) {
            handleValueChange(DEFAULT_START_TIME_OFFSET);
          }
        }}
        onChange={(e) => {
          handleValueChange(e.target.value);
        }}
        disabled={disabled}
      />
      <select
        style={selectInputFix}
        value={offsetValueModifier}
        onChange={(e) => {
          handleValueModifierChange(e.target.value);
        }}
        disabled={disabled}
      >
        <option value="-1">
          {t("mins_before_web", { name: offsetTypeRelativeTo })}
        </option>
        <option value="1">
          {t("mins_after_web", { name: offsetTypeRelativeTo })}
        </option>
      </select>
    </div>
  );
}

export default StartTimeOffset;
