// import { useNavigate } from "react-router-dom";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { ReactComponent as AircraftMoveReminder } from "../../assets/aircraft-move-reminder.svg";
import { isNullOrUndefined } from "../../utils";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../constants";
import { getTurnaroundInfoForTurnaroundSummary } from "../../turnaroundUtils";
import { useNavigate } from "react-router-dom";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
function MoveAircraftReminderBanner(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const navigate = useNavigate();
  const { moveAircraftReminder } = mainContext;
  if (isNullOrUndefined(moveAircraftReminder)) return;
  // moveAircraftReminder will be a turnaroundSummary
  const turnaroundInfo =
    getTurnaroundInfoForTurnaroundSummary(moveAircraftReminder);
  const messageDetail = turnaroundInfo.combinedFlightName;

  return (
    <div>
      <div>
        <AircraftMoveReminder />
      </div>
      <div className="action-banner-message reminder">
        <div>
          {turnaroundInfo.needsRepositionToHardStand &&
            t("move_aircraft_to_hard_stand_reminder")}
          {turnaroundInfo.needsRepositionToDepartureStand &&
            t("move_aircraft_to_departure_stand_reminder")}
        </div>
        <div>
          <span>{messageDetail}</span>
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            dispatch({
              type: "setMoveAircraftRequest",
              value: turnaroundInfo,
            });
            dispatch({
              type: "setShowOnMap",
              value: {
                markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
                item: {
                  registration: turnaroundInfo.registration,
                },
              },
            });
            dispatch({
              type: "setAnalyticsEvent",
              value: {
                eventName: ANALYTICS_EVENTS.MOVE_AIRCRAFT_REMINDER_BANNER_CLICK,
              },
            });
            navigate("/map");
          }}
        >
          {t("move_aircraft")}
        </button>
      </div>
    </div>
  );
}

export default MoveAircraftReminderBanner;
