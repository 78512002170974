import { Modal } from "@mui/material";
import { selectInputFix } from "../../styleUtils";
import { useState } from "react";
import { isNullOrUndefined, sortByField } from "../../utils";

function TemplateSelectorModal(props) {
  const { isOpen, onClose, turnaroundTemplates, turnaroundToConfigure } = props;
  const templateList = [];

  if (!isNullOrUndefined(turnaroundTemplates)) {
    for (let i = 0; i < turnaroundTemplates.length; i++) {
      const turnaroundTemplate = turnaroundTemplates[i];
      // Only allow templates that are for this aircraftType and is not used already
      // TODO: Filter currently selected template
      if (turnaroundTemplate?.aircraftType.name === turnaroundToConfigure.aircraftTypeModel) {
        templateList.push(turnaroundTemplate);
      }
    }
    // TODO: Sort templates by NAME (does not exist yet)
    sortByField(templateList, "aircraftType.name");
  }
  const hasTemplates =
    !isNullOrUndefined(templateList) && templateList.length > 0;
  const defaultTemplateUuid = hasTemplates ? templateList[0].uuid : "";
  const [templateToApply, setTemplateToApply] = useState(defaultTemplateUuid);

  const handleApplyTemplate = () => {
    // apply the template and reload the dispatch before closing
    console.log("templateToApply", templateToApply);
    onClose();
  };
  const isApplyDisabled = isNullOrUndefined(templateToApply);

  return (
    <div>
      <Modal open={isOpen} onClose={onClose}>
        <div className="modal template-selector-modal">
          <div className="template-selector-modal-header">
            <h3>Apply turnaround template</h3>
            <span>This will override the current dispatch configuration.</span>
          </div>
          <div className="template-selector-modal-body">
            {isNullOrUndefined(templateList) ||
              (templateList?.length === 0 && (
                <div className="de-emphasize">No valid templates available</div>
              ))}
            {templateList?.length > 0 && (
              <select
                style={selectInputFix}
                onChange={(e) => {
                  setTemplateToApply(e.target.value);
                }}
              >
                {templateList &&
                  // TODO: Show template NAME
                  templateList.map((template) => (
                    <option key={template.uuid} value={template.uuid}>
                      {template?.aircraftType?.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          <div className="template-selector-modal-actions">
            <div>
              <button
                className="secondary"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>

              <button onClick={handleApplyTemplate} disabled={isApplyDisabled}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default TemplateSelectorModal;
