import { getTurnaroundInfoForTurnaroundSummary } from "./turnaroundUtils";
import { getUserInfo } from "./userUtils";
import { getByFieldValue, isBlank, isNullOrUndefined } from "./utils";
import moment from "moment-timezone";

export const CHAT_CATEGORY_TYPES = {
  TURNAROUND: 1,
  GROUP: 2,
  DIRECT: 3,
};

export const CHAT_ATTACHMENT_SIZE = {
  PORTRAIT: 200,
  LANDSCAPE: 300,
};

export function isSeenByUser(channel, message, user, airportTimezone) {
  const lastReadMember =
    !isNullOrUndefined(user) && !isNullOrUndefined(channel.members)
      ? getByFieldValue(channel.members, "userUuid", user.uuid)
      : null;
  const lastReadTime = !isNullOrUndefined(lastReadMember)
    ? moment(lastReadMember.lastRead).tz(airportTimezone)
    : null;
  return !isNullOrUndefined(lastReadTime)
    ? lastReadTime.isAfter(message.sentAt)
    : false;
}

export function getUnreadMessagesForUser(
  channel,
  currentUser,
  airportTimezone
) {
  const unreadMessages = [];
  for (let i = 0; i < channel.messages?.length; i++) {
    const message = channel.messages[i];
    if (
      !isSeenByUser(channel, message, currentUser, airportTimezone) &&
      message.sentBy !== currentUser.uuid
    ) {
      unreadMessages.push(message);
    }
  }
  return unreadMessages;
}

export function getChannelInfo(
  channel,
  currentUser,
  commsUsersByUuid,
  unreadCountByChannelId,
  turnaroundsSummary,
  positions
) {
  const channelInfo = {
    id: channel.id,
    channel: channel,
    name: "",
  };
  channelInfo.isTurnaround = channel.type === CHAT_CATEGORY_TYPES.TURNAROUND;
  channelInfo.isGroup = channel.type === CHAT_CATEGORY_TYPES.GROUP;
  channelInfo.isDirect = channel.type === CHAT_CATEGORY_TYPES.DIRECT;
  if (!isNullOrUndefined(unreadCountByChannelId)) {
    const unreadCount = unreadCountByChannelId[channel.id];
    channelInfo.unreadCount = !isNullOrUndefined(unreadCount) ? unreadCount : 0;
    channelInfo.unreadCountDisplay =
      channelInfo.unreadCount > 0
        ? channelInfo.unreadCount > 99
          ? "99+"
          : channelInfo.unreadCount
        : null;
  }
  if (channelInfo.isDirect) {
    if (!isNullOrUndefined(channel.members)) {
      for (let i = 0; i < channel.members.length; i++) {
        const member = channel.members[i];
        if (member.userUuid !== currentUser.uuid) {
          const commsUser = commsUsersByUuid[member.userUuid];
          channelInfo.commsUserInfo = !isNullOrUndefined(commsUser)
            ? getUserInfo(commsUser, turnaroundsSummary, positions)
            : null;
          channelInfo.name = !isNullOrUndefined(channelInfo.commsUserInfo)
            ? channelInfo.commsUserInfo.fullName
            : "";
        }
      }
    }
  } else if (channelInfo.isTurnaround) {
    channelInfo.turnaround =
      !isNullOrUndefined(turnaroundsSummary) &&
      !isNullOrUndefined(channel.turnaroundUuid)
        ? getByFieldValue(turnaroundsSummary, "uuid", channel.turnaroundUuid)
        : null;
    channelInfo.name = !isNullOrUndefined(channelInfo.turnaround)
      ? getTurnaroundInfoForTurnaroundSummary(channelInfo.turnaround)
          .combinedFlightName
      : "";
  } else if (channelInfo.isGroup) {
    if (!isBlank(channel.name)) {
      channelInfo.name = channel.name;
    } else {
      const memberNames = [];
      if (!isNullOrUndefined(channel.members)) {
        for (let i = 0; i < channel.members.length; i++) {
          const member = channel.members[i];
          if (member.userUuid !== currentUser.uuid) {
            const commsUser = commsUsersByUuid[member.userUuid];
            if (!isNullOrUndefined(commsUser)) {
              memberNames.push(commsUser.firstName);
            }
          }
        }
      }
      const nameString = memberNames.join(", ");
      channelInfo.name = nameString;
    }
  }
  return channelInfo;
}

export function scaleCommsImage(origWidth, origHeight) {
  // Scale image to a specific width
  let ratio = 1;
  if (origWidth > origHeight) {
    ratio = CHAT_ATTACHMENT_SIZE.LANDSCAPE / origWidth;
  } else {
    ratio = CHAT_ATTACHMENT_SIZE.PORTRAIT / origWidth;
  }
  return [origWidth * ratio, origHeight * ratio];
}

// Gets the direct message channel for the user if it exists
export function getChannelForUserUuid(channelInfoList, userUuid) {
  if (isNullOrUndefined(channelInfoList)) return null;
  let directMessageChannels = null;
  for (let i = 0; i < channelInfoList.length; i++) {
    const channelInfo = channelInfoList[i];
    if (channelInfo.category.channelGroupType === CHAT_CATEGORY_TYPES.DIRECT) {
      directMessageChannels = channelInfo.channelInfoList;
    }
  }
  if (isNullOrUndefined(directMessageChannels)) return null;
  for (let i = 0; i < directMessageChannels.length; i++) {
    const directMessageChannel = directMessageChannels[i];
    if (directMessageChannel?.commsUserInfo?.uuid === userUuid) {
      return directMessageChannel;
    }
  }
  return null;
}
