function SettingsMenuItem(props) {
  const { isSelected, label, handleClick } = props;
  return (
    <div
      className={`settings-menu-item${isSelected ? " selected" : ""}`}
      onClick={handleClick}
    >
      <div className="indicator"></div>
      <div className="menu-label">
        <div>{label}</div>
      </div>
    </div>
  );
}

export default SettingsMenuItem;
