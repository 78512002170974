import "./styles.css";
import { ReactComponent as Search } from "../../assets/search.svg";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";
function SectionToolbar(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { searchQuery, searchbarDisabled } = mainContext;
  const searchPlaceholder = searchQuery?.searchPlaceholder;
  const searchTerm = searchQuery?.searchTerm;

  return (
    <div
      className={`search-input-container${
        searchbarDisabled ? " disabled" : ""
      }`}
    >
      <Search />
      <input
        value={searchTerm || ""}
        placeholder={t(searchPlaceholder)}
        onChange={(e) => {
          const searchQueryToUpdate = {
            ...searchQuery,
            searchTerm: e.target.value,
          };
          dispatch({
            type: "setSearchQuery",
            value: searchQueryToUpdate,
          });
        }}
        disabled={searchbarDisabled}
      />
    </div>
  );
}
export default SectionToolbar;
