import { ColumnNames } from ".";
import { isBlank } from "../../utils";

export function getColumnNameObjectById(columnId) {
  for (const [key, value] of Object.entries(ColumnNames)) {
    if (value.id === columnId) return ColumnNames[key];
  }
  return null;
}

export function sortByDataColumn(dataList, sortColumn, sortDesc) {
  dataList.sort((a, b) => {
    let val1 = a;
    let val2 = b;

    // Use the column defined sort value
    const columnName = getColumnNameObjectById(sortColumn);
    val1 = columnName.sortValue(a);
    val2 = columnName.sortValue(b);
    if (isBlank(val1)) return 1;
    if (isBlank(val2)) return -1;
    if (val1 === val2) return 0;
    if (!sortDesc) {
      return val1 > val2 ? 1 : -1;
    } else {
      return val1 > val2 ? -1 : 1;
    }
  });
}

export function createSortOptions(sortColumnName, sortDesc) {
  return {
    sortColumnName: sortColumnName,
    sortDesc: sortDesc,
  };
}
