import "./styles.css";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getCertifications,
  getCrewList,
  getUserGroups,
  removeUser,
  saveUser,
} from "../../api";
import PageToolbar from "../PageToolbar";
import CrewModal from "./CrewModal";
import { isNullOrUndefined } from "../../utils";
import DataTable from "../DataTable";
import { ColumnNames } from "../DataTable";
import LoadingIndicator from "../LoadingIndicator";
import { SEARCH_QUERY_PLACEHOLDERS } from "../../constants";
import { useTranslation } from "react-i18next";

function Crew() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    certifications,
    crewList,
    crewListLoading,
    userGroups,
    userSaving,
    searchQuery,
  } = mainContext;

  const [selectedCrewMember, setSelectedCrewMember] = useState(null);
  const [isUserSaveInitiated, setIsUserSaveInitiated] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (isUserSaveInitiated && !userSaving) {
      setSelectedCrewMember(null);
      setIsUserSaveInitiated(false);

      // refetch
      getCrewList(dispatch);
    }
  }, [dispatch, userSaving, isUserSaveInitiated]);

  useEffect(() => {
    getCrewList(dispatch);
    getCertifications(dispatch);
    getUserGroups(dispatch);
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_CREW,
      },
    });
  }, [dispatch]);

  const actionButtons = [
    <button
      onClick={() => {
        const defaultUserGroupId =
          !isNullOrUndefined(userGroups) && userGroups.length > 0
            ? userGroups[0].uuid
            : "";
        setSelectedCrewMember({
          firstName: "",
          lastName: "",
          email: "",
          userGroupUuid: defaultUserGroupId,
        });
      }}
    >
      <Plus /> {t("add_user")}
    </button>,
    <button className="secondary" disabled={true}>
      {t("filter")}
    </button>,
  ];

  const handleDeleteReset = () => {
    dispatch({
      type: "setConfirmation",
      confirmation: null,
    });
    setIsDeleting(false);
    setSelectedCrewMember(null);
  };

  async function handleDelete(user) {
    setIsUserSaveInitiated(true);
    await removeUser(user, dispatch);
    handleDeleteReset();
    dispatch({
      type: "setAlertMessage",
      alertMessage: t("deleted_web", {
        name: `${user.firstName} ${user.lastName}`,
      }),
    });
  }

  async function handleSave(user) {
    setIsUserSaveInitiated(true);
    await saveUser(user, dispatch);
    dispatch({
      type: "setAlertMessage",
      alertMessage: t("saved_web", {
        name: `${user.firstName} ${user.lastName}`,
      }),
    });
  }

  const handleDeleteCrewMember = (user) => {
    setIsDeleting(true);
    setSelectedCrewMember(user);
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: t("delete_msg_web", {
          name: `${user.firstName} ${user.lastName}`,
        }),
        actionOK: () => {
          handleDelete(user);
        },
        actionCancel: () => {
          handleDeleteReset();
        },
      },
    });
  };

  const totalUsers = crewList?.length ? crewList?.length : 0;
  const titleString =
    totalUsers === 1
      ? `${totalUsers} ${t("user")}`
      : `${totalUsers} ${t("users")}`;
  return (
    <div className="crew">
      <div className="crew-container">
        <PageToolbar title={titleString} actionButtons={actionButtons} />
        {crewListLoading && <LoadingIndicator />}
        {!crewListLoading && (
          <DataTable
            dataList={crewList}
            selectedUuids={
              !isNullOrUndefined(selectedCrewMember)
                ? [selectedCrewMember.uuid]
                : []
            }
            columnNames={[
              ColumnNames.Name,
              ColumnNames.Certifications,
              ColumnNames.Email,
              ColumnNames.Confirmed,
            ]}
            rowActions={{
              editAction: (userObj) => {
                setSelectedCrewMember(userObj);
              },
              deleteAction: (userObj) => {
                handleDeleteCrewMember(userObj);
              },
            }}
            searchQuery={searchQuery}
          />
        )}
      </div>
      <CrewModal
        selectedCrewMember={!isDeleting ? selectedCrewMember : null}
        onCloseHandler={() => {
          setSelectedCrewMember(null);
        }}
        onSaveHandler={(values) => {
          handleSave(values);
        }}
        userGroups={userGroups}
        certifications={certifications}
        userSaving={userSaving}
      />
    </div>
  );
}
export default Crew;
