import ProfilePic from "../../assets/profile-pic.svg";
import ProfilePicDark from "../../assets/profile-pic-dark.svg";
import { isNullOrUndefined } from "../../utils";
import { getSignedProfileImage } from "../../mediaUtils";

export const UserPicSize = {
  SMALL: 24,
  MEDIUM: 32,
  LARGE: 40,
  XLARGE: 100,
};

export const UserPicBorderSize = {
  SMALL: 2,
  MEDIUM: 4,
};

export const UserPicTheme = {
  DEFAULT: "default",
  DARK: "dark",
};

function UserPic(props) {
  const {
    size = UserPicSize.MEDIUM,
    theme = UserPicTheme.DEFAULT,
    border = UserPicBorderSize.SMALL,
    user,
    cacheBuster,
    fullSize,
  } = props;

  const defaultPic =
    theme === UserPicTheme.DEFAULT ? ProfilePic : ProfilePicDark;

  const userImage = !isNullOrUndefined(user)
    ? getSignedProfileImage(user, cacheBuster, fullSize)
    : null;

  const profilePicStyle = {
    backgroundImage: `url(${userImage}), url(${defaultPic})`,
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: `${size}px`,
    border:
      UserPicTheme.DARK === theme
        ? `${border}px solid var(--outline-dark-color)`
        : `${border}px solid var(--base-font-color)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "hidden",
  };
  return <div className="user-pic" style={profilePicStyle}></div>;
}

export default UserPic;
