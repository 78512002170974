import { useTranslation } from "react-i18next";
import {
  TurnaroundOperationStatus,
  getTurnaroundInfoForTurnaroundSummary,
  isUpcomingTurnaround,
} from "../../turnaroundUtils";
import { isNullOrUndefined, sortByDateField } from "../../utils";
import TurnaroundCard from "../TurnaroundCard";
function TurnaroundCardsWidget(props) {
  const { turnarounds, airportTimezone } = props;
  const { t } = useTranslation();

  const missingOperationsTurnaroundsList = [];
  const delayedTurnaroundsList = [];
  const upcomingTurnaroundsList = [];
  let criticalTurnaroundsList = [];

  if (!isNullOrUndefined(turnarounds)) {
    for (let i = 0; i < turnarounds.length; i++) {
      const turnaroundInfo = getTurnaroundInfoForTurnaroundSummary(
        turnarounds[i]
      );
      // Filter out completed turnarounds
      if (turnaroundInfo?.percentComplete !== 100) {
        const turnaroundLevelOperationStatus =
          turnaroundInfo.turnaroundLevelOperationStatus;
        if (
          turnaroundLevelOperationStatus?.primaryStatus ===
          TurnaroundOperationStatus.MISSING
        ) {
          missingOperationsTurnaroundsList.push(turnaroundInfo);
        } else if (
          turnaroundLevelOperationStatus?.primaryStatus ===
          TurnaroundOperationStatus.MISSING
        ) {
          delayedTurnaroundsList.push(turnaroundInfo);
        } else if (isUpcomingTurnaround(turnaroundInfo, airportTimezone)) {
          upcomingTurnaroundsList.push(turnaroundInfo);
        }
      }
    }
    sortByDateField(missingOperationsTurnaroundsList, "gateIn");
    sortByDateField(delayedTurnaroundsList, "gateIn");
    sortByDateField(upcomingTurnaroundsList, "gateIn");
    criticalTurnaroundsList = [
      ...missingOperationsTurnaroundsList,
      ...delayedTurnaroundsList,
    ];
  }

  return (
    <>
      {criticalTurnaroundsList && criticalTurnaroundsList.length > 0 && (
        <>
          <div className="widget-header">
            <h2>{t("critical_turnarounds")}</h2>
          </div>

          <div className="widget-body">
            {!isNullOrUndefined(criticalTurnaroundsList) &&
              criticalTurnaroundsList.map((turnaroundInfo) => (
                <TurnaroundCard
                  turnaroundInfo={turnaroundInfo}
                  key={turnaroundInfo.uuid}
                />
              ))}
          </div>
        </>
      )}
      <div className="widget-header">
        <h2>{t("upcoming_turnarounds")}</h2>
      </div>

      <div className="widget-body">
        {!isNullOrUndefined(upcomingTurnaroundsList) &&
          upcomingTurnaroundsList.map((turnaroundInfo) => (
            <TurnaroundCard
              turnaroundInfo={turnaroundInfo}
              key={turnaroundInfo.uuid}
            />
          ))}
      </div>
    </>
  );
}

export default TurnaroundCardsWidget;
