import { ReactComponent as Humidity } from "../../assets/humidity.svg";
import { ReactComponent as Precipitation } from "../../assets/precipitation.svg";
import { ReactComponent as Wind } from "../../assets/wind.svg";
import { ReactComponent as Pressure } from "../../assets/pressure.svg";
import WeatherIcon from "../WeatherIcon";
import { useTranslation } from "react-i18next";
const DASHES = <>--</>;

function WeatherWidget(props) {
  const { weather } = props;
  const { t } = useTranslation();

  const precipitationProbability = weather
    ? weather.precipitationProbability
    : DASHES;
  const windSpeed = weather ? weather.windSpeed : DASHES;
  const windDirection = weather ? weather.windDirection : DASHES;
  const humidity = weather ? weather.humidity : DASHES;
  const pressureSurfaceLevel = weather ? weather.pressureSurfaceLevel : DASHES;
  const temperature = weather ? weather.temperature : DASHES;
  const weatherConditionId = weather ? weather.weatherConditionId : DASHES;
  const weatherConditionName = weather ? weather.weatherConditionName : DASHES;

  return (
    <>
      <div className="conditions">
        <div>
          <WeatherIcon weatherConditionId={weatherConditionId} />
        </div>
        <div className="conditions-detail">
          <span>{weatherConditionName}</span>
          <div>
            <h3>{temperature}&deg;</h3>
            <span>F</span>
          </div>
        </div>
      </div>
      <div className="stat">
        <div>
          <Precipitation />
        </div>
        <div>
          <span>{t("precipitation")}</span>
        </div>
        <div>
          <h3>{precipitationProbability}</h3>
          <span>%</span>
        </div>
      </div>
      <div className="stat">
        <div>
          <Wind />
        </div>
        <div>
          <span>{t("wind")}</span>
        </div>
        <div>
          <h3>{windSpeed}</h3>
          <span>mph</span>&nbsp;
          <h3>{windDirection}</h3>
        </div>
      </div>
      <div className="stat">
        <div>
          <Humidity />
        </div>
        <div>
          <span>{t("humidity")}</span>
        </div>
        <div>
          <h3>{humidity}</h3>
          <span>%</span>
        </div>
      </div>
      <div className="stat">
        <div>
          <Pressure />
        </div>
        <div>
          <span>{t("pressure")}</span>
        </div>
        <div>
          <h3>{pressureSurfaceLevel}</h3>
          <span>inHg</span>
        </div>
      </div>
    </>
  );
}

export default WeatherWidget;
