import { useRef } from "react";
import { ReactComponent as RightArrow } from "../../assets/arrow-right.svg";
import { useTranslation } from "react-i18next";

function TemplateAircraftTile(props) {
  const { t } = useTranslation();
  const { onSelect, aircraft, name, templateCount } = props;
  const imageRef = useRef(null);
  return (
    <div className="template-aircraft-tile" onClick={onSelect}>
      <div>
        <div className="template-aircraft-image-container">
          <div
            className="template-aircraft-image"
            style={{ backgroundImage: `url(${aircraft})` }}
            ref={imageRef}
          ></div>
        </div>
        <div className="template-aircraft-info">
          <div>{name}</div>
          <div>
            <span>
              {templateCount === 1 && (
                <>{t("template_configured_web", { value: templateCount })}</>
              )}
              {templateCount > 1 && (
                <>{t("templates_configured_web", { value: templateCount })}</>
              )}
            </span>
          </div>
          <div>
            <RightArrow width={18} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateAircraftTile;
