import moment from "moment/moment";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { ReactComponent as Time } from "../../assets/time.svg";
import {
  deepCopy,
  formatTime,
  isNullOrUndefined,
  sortByDateField,
} from "../../utils";
import TimelineEventIcon from "./TimelineEventIcon";
import { useTranslation } from "react-i18next";
function TurnaroundAlertsPanel(props) {
  const { selectedTurnaround, onClose } = props;
  const { t } = useTranslation();
  const timelineEventsList = !isNullOrUndefined(
    selectedTurnaround?.timelineEvents
  )
    ? deepCopy(selectedTurnaround.timelineEvents)
    : [];
  sortByDateField(timelineEventsList, "timestamp");
  timelineEventsList.reverse();
  const airportTimezone = selectedTurnaround?.airportTimezone;
  const mToday = moment().tz(airportTimezone);
  return (
    <>
      <div className="turnaround-alerts-panel right-panel-content-header">
        <div>
          <h3>{t("timeline")}</h3>
        </div>
        <div className="close-btn" onClick={onClose}>
          <CloseBtn />
        </div>
      </div>
      <div className="right-panel-content-container">
        <div className="alert-timeline">
          {timelineEventsList.length === 0 && (
            <div className="empty-state">No events yet</div>
          )}
          {timelineEventsList.length > 0 && (
            <>
              <div className="vertical-line"></div>
              <div className="alert-timeline-item">
                <div>
                  <Time />
                </div>
                <div>
                  <span>{t("now")}</span>
                </div>
                <div>&nbsp;</div>
              </div>
            </>
          )}
          {timelineEventsList.length > 0 &&
            timelineEventsList.map((item, idx) => (
              <div key={idx} className="alert-timeline-item">
                <div>
                  <TimelineEventIcon
                    timelineEventType={item.timelineEventTypeId}
                  />
                </div>
                <div>
                  <span>
                    {formatTime(item.timestamp, airportTimezone, true, mToday)}
                  </span>
                </div>
                <div>{item.message}</div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
export default TurnaroundAlertsPanel;
