import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function AlertsEmpty() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
    navigate("/settings/alerts");
  }

  return (
    <div className="alerts-empty-state">
      <div>
        <div>
          <h3>{t("no_new_alerts")}</h3>
        </div>
        <div>{t("no_new_alerts_description")}</div>
        <div className="actions">
          <button onClick={handleClick}>
            {t("update_notification_settings")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AlertsEmpty;
