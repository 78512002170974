import { useCommsContext } from "../../CommsContext";
import { useMainContext } from "../../MainContext";
import { getUnreadMessagesForUser } from "../../commsUtils";
import { isNullOrUndefined } from "../../utils";

function ChatIndicator() {
  const mainContext = useMainContext();
  const { currentUser } = mainContext;
  const commsContext = useCommsContext();
  const { channels } = commsContext;

  let hasUnreadChatMessages = false;
  if (!isNullOrUndefined(currentUser) && !isNullOrUndefined(channels)) {
    const airportTimezone = currentUser?.airport?.timezone;
    const allChannels = [];
    if (!isNullOrUndefined(channels)) {
      // Combine all channels (top level is category)
      for (let i = 0; i < channels.length; i++) {
        const category = channels[i];
        if (!isNullOrUndefined(category.channels)) {
          allChannels.push.apply(allChannels, category.channels);
        }
      }

      for (let i = 0; i < allChannels.length && !hasUnreadChatMessages; i++) {
        const channel = allChannels[i];
        const unreadMessagesForChannel = getUnreadMessagesForUser(
          channel,
          currentUser,
          airportTimezone
        );
        if (unreadMessagesForChannel?.length > 0) {
          hasUnreadChatMessages = true;
        }
      }
    }
  }

  const showIndicator = hasUnreadChatMessages;
  return <>{showIndicator && <div className="status-indicator"></div>}</>;
}

export default ChatIndicator;
