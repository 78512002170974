import { useTranslation } from "react-i18next";
import StatusBadge from "../StatusBadge";
function TurnaroundCrewSlot(props) {
  const { t } = useTranslation();

  const { missingCrewRequirement, isSelectable, selected, onClick } = props;

  const turnaroundRequirement = missingCrewRequirement?.requirement;
  const requirementName = turnaroundRequirement?.certification?.name;
  return (
    <div
      className={`turnaround-crew-slot${selected ? " selected" : ""}${
        isSelectable ? " selectable" : ""
      }`}
      key={missingCrewRequirement?.uuid}
      onClick={() => {
        if (isSelectable) {
          onClick();
        }
      }}
    >
      <div>
        <div className="turnaround-crew-slot-info">
          <label>{t("allocated")}</label>
          <div>
            <StatusBadge
              isAllCap={true}
              isCritical={true}
              isFilled={true}
              message={requirementName}
            />
          </div>
        </div>
        <div>
          <div className="turnaround-crew-slot-actions">
            <button
              className="outlined"
              onClick={() => {
                if (isSelectable) {
                  onClick();
                }
              }}
            >
              {t("assign")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnaroundCrewSlot;
