import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { isBlank } from "../utils";

// BASE contains all string keys used within the webapp
import BASE from "./base.json";

import EN from "./en.json";
// Additional supported languages should go here
// import FR from "./fr.json";
// import ES from "./es.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: createStringResource(EN),
      fr: createStringResource(EN),
      es: createStringResource(EN),
    },
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export function createStringResource(sourceLanguageFile) {
  const stringResource = { translation: {} };
  const keys = Object.keys(BASE.translation);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const translation = sourceLanguageFile.translation[key];
    if (!isBlank(translation)) {
      stringResource.translation[key] = translation;
    } else {
      // Use BASE file as fallback
      stringResource.translation[key] = BASE.translation[key];
      console.log(`DEBUG INFO: translation needed for [${key}]`);
    }
  }
  return stringResource;
}

export default i18n;
