import { useState } from "react";
import { EMPTY_TIME } from "../../constants";
import { formatTime, isBlank } from "../../utils";
import GateSelector from "./GateSelector";
import { getTurnaroundsSummary, saveTurnaroundStands } from "../../api";
import { useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";

function GateScheduleRow(props) {
  const { t } = useTranslation();
  const { turnaroundStandAssignment, gates, airportTimezone } = props;
  const dispatch = useMainDispatchContext();

  const [isSaving, setIsSaving] = useState(false);
  const [arrivalStand, setArrivalStand] = useState(
    turnaroundStandAssignment?.assignedArrivalStandUuid
  );
  const [departureStand, setDepartureStand] = useState(
    turnaroundStandAssignment?.assignedDepartureStandUuid
  );
  const [hardStand, setHardStand] = useState(
    turnaroundStandAssignment?.assignedHardStandUuid
  );

  async function handleUpdateStands() {
    const standsRequest = {
      uuid: turnaroundStandAssignment.uuid,
      arrivalStandUuid: !isBlank(arrivalStand) ? arrivalStand : null,
      departureStandUuid: !isBlank(departureStand) ? departureStand : null,
      hardStandUuid: !isBlank(hardStand) ? hardStand : null,
    };
    setIsSaving(true);
    const saveResponse = await saveTurnaroundStands(dispatch, standsRequest);

    if (saveResponse) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("stand_saved_msg_web", {
          name: turnaroundStandAssignment.displayName,
        }),
      });
    }
    // Refresh the turnaroundsSummary
    getTurnaroundsSummary(dispatch);
    setIsSaving(false);
  }
  const isInboundOnly = turnaroundStandAssignment.inboundOnly;
  const isModified =
    arrivalStand !== turnaroundStandAssignment?.assignedArrivalStandUuid ||
    departureStand !== turnaroundStandAssignment?.assignedDepartureStandUuid ||
    hardStand !== turnaroundStandAssignment?.assignedHardStandUuid;
  return (
    <div className="listing-row">
      <div>
        <div className="list-column-value">
          <span>{turnaroundStandAssignment.displayName}</span>
        </div>
      </div>

      <div>
        <div className="list-column-value">
          {turnaroundStandAssignment.registration}
        </div>
      </div>
      <div>
        <div className="list-column-value">
          {turnaroundStandAssignment.aircraftVariantName}
        </div>
      </div>
      <div>
        <div className="list-column-value">
          {formatTime(
            turnaroundStandAssignment.gateInTime,
            airportTimezone,
            true
          )}
        </div>
      </div>
      <div
        className={`list-column-value${
          isBlank(turnaroundStandAssignment.gateOutTime) ? " empty-value" : ""
        }`}
      >
        {!isBlank(turnaroundStandAssignment.gateOutTime) &&
          formatTime(
            turnaroundStandAssignment.gateOutTime,
            airportTimezone,
            true
          )}
        {isBlank(turnaroundStandAssignment.gateOutTime) && <>{EMPTY_TIME}</>}
      </div>
      <div>
        <GateSelector
          onSelect={(value) => {
            setArrivalStand(value?.uuid);
          }}
          gates={gates}
          value={arrivalStand}
          allowClear={false}
        />
      </div>
      <div>
        <GateSelector
          onSelect={(value) => {
            setHardStand(value?.uuid);
          }}
          gates={gates}
          value={hardStand}
          allowClear={true}
          tooltip={t("reposition_stand_tooltip")}
        />
      </div>
      <div>
        {!isInboundOnly && (
          <GateSelector
            onSelect={(value) => {
              setDepartureStand(value?.uuid);
            }}
            gates={gates}
            value={departureStand}
            allowClear={false}
          />
        )}
        {isInboundOnly && (
          <span className="de-emphasize">{t("flight_not_available")}</span>
        )}
      </div>
      <div>
        <div>
          <button
            onClick={() => {
              handleUpdateStands();
            }}
            disabled={isSaving || !isModified}
          >
            {isSaving ? <i>{t("saving")}</i> : t("save")}
          </button>
        </div>
      </div>
    </div>
  );
}
export default GateScheduleRow;
