import "./styles.css";
import { isNullOrUndefined } from "../../utils";
import { useTranslation } from "react-i18next";

function PageToolbar(props) {
  const { title, subheading, actionButtons, isInternal = false } = props;
  const { t } = useTranslation();

  const buttonCount = !isNullOrUndefined(actionButtons)
    ? actionButtons.length
    : 0;
  const styles = {
    gridTemplateColumns: `repeat(${buttonCount}, auto)`,
  };
  return (
    <div className="page-toolbar">
      <div>
        <h2>
          {title}
          {isInternal && <div className="internal-badge">{t("internal")}</div>}
        </h2>
        {subheading && (
          <span className="page-toolbar-subheading">{subheading}</span>
        )}
      </div>
      <div className="page-toolbar-actions" style={styles}>
        {!isNullOrUndefined(actionButtons) &&
          actionButtons.map((actionButton, idx) => (
            <div key={idx}>{actionButton}</div>
          ))}
      </div>
    </div>
  );
}
export default PageToolbar;
