import { useTranslation } from "react-i18next";
import { useCommsContext, useCommsDispatchContext } from "../../CommsContext";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { ReactComponent as Attachment } from "../../assets/attachment.svg";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";

import {
  getChannels,
  getCommsAttachmentUpload,
  getCommsChannelAttachments,
  getCommsUsers,
  patchCommsChannelRead,
  postCommsChannel,
  postCommsChannelMessage,
  putCommsChannel,
  uploadCommsAttachment,
} from "../../commsApi";
import {
  CHAT_CATEGORY_TYPES,
  getChannelForUserUuid,
  getChannelInfo,
  getUnreadMessagesForUser,
} from "../../commsUtils";
import {
  deepCopy,
  getByFieldValue,
  getMapFromList,
  isBlank,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import ChannelInfo from "./ChannelInfo";
import ChatCategory from "./ChatCategory";
import ChatChannelEditor from "./ChatChannelEditor";
import ChatMessage from "./ChatMessage";
import "./styles.css";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import LoadingIndicator from "../LoadingIndicator";
import ChatBubble from "./ChatBubble";
import ChatAttachmentModal from "./ChatAttachmentModal";
import { POLLING_INTERVALS } from "../../constants";

export const ChatModuleMode = {
  STANDARD: "STANDARD",
  COMPACT: "COMPACT",
};

function ChatModule(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const commsDispatch = useCommsDispatchContext();
  const commsContext = useCommsContext();
  const mainContext = useMainContext();
  const {
    currentUser,
    turnaroundsSummary,
    selectedChannelId,
    selectedChatRequest,
    positions,
  } = mainContext;

  const { mode, onClose } = props;

  const {
    channels: categories,
    commsChannelPost,
    commsUsersLoading,
    commsUsers,
    commsAttachmentUpload,
    commsChannelReadSaving,
    commsChannelAttachments,
    commsChannelAttachmentsLoading,
    selectedAttachment,
  } = commsContext;
  const [messageText, setMessageText] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [isPostingMessage, setIsPostingMessage] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [channelEditorMode, setChannelEditorMode] = useState(null); // edit, create
  const [channelEditorType, setChannelEditorType] = useState(null);
  const [channelToEdit, setChannelToEdit] = useState(null);

  // Requests for polling
  const [commsUsersRequested, setCommsUsersRequested] = useState(false);
  const [
    commsChannelAttachmentsRequested,
    setCommsChannelAttachmentsRequested,
  ] = useState(false);
  const [commsChannelReadRequested, setCommsChannelReadRequested] =
    useState(false);
  const chatInputRef = useRef(null);
  const chatFileInputRef = useRef(null);

  async function handleSend() {
    if (isPostingMessage) return;
    setIsPostingMessage(true);
    const content = {
      content: {
        text: messageText,
      },
    };
    setMessageText("");

    // Posting the message has a slight delay so for UX purposes
    // Create a temp message in the state (which animates in)
    commsDispatch({
      type: "setTempChannelMessage",
      value: messageText,
      channelId: selectedChannelId,
      sentBy: currentUser.uuid,
    });
    setTimeout(() => {
      scrollMessages();
    }, 100);

    // The following fetch will just overwrite the temp message in state
    async function handlePostMessage() {
      await postCommsChannelMessage(commsDispatch, selectedChannelId, content);
      await getChannels(commsDispatch);
      await getCommsUsers(commsDispatch);
      setTimeout(() => {
        setIsPostingMessage(false);
        chatInputRef?.current?.focus();
        scrollMessages();
      }, 100);
    }
    handlePostMessage();
  }

  const commsUsersByUuid = useMemo(() => {
    if (!isNullOrUndefined(commsUsers)) {
      return getMapFromList(commsUsers, "uuid");
    }
    return {};
  }, [commsUsers]);

  const scrollMessages = useCallback(
    (instant) => {
      if (!isNullOrUndefined(selectedChannelId)) {
        setCommsChannelReadRequested(true);
      }
      const chatBody = document.querySelector(".chat-body");
      if (!isNullOrUndefined(chatBody)) {
        chatBody.scrollTo({
          top: document.querySelector(".chat-body").scrollHeight,
          behavior: instant ? "instant" : "smooth",
        });
      }
    },
    [selectedChannelId]
  );

  const [channelsById, unreadCountByChannelId] = useMemo(() => {
    if (isNullOrUndefined(currentUser)) return [[], {}];
    if (isNullOrUndefined(categories) || categories.length === 0)
      return [[], {}];
    const airportTimezone = currentUser?.airport?.timezone;
    const allChannels = [];
    const unreadCounts = {};
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      if (!isNullOrUndefined(category.channels)) {
        allChannels.push.apply(allChannels, category.channels);
      }
    }
    for (let i = 0; i < allChannels.length; i++) {
      const channel = allChannels[i];
      const unreadMessagesForChannel = getUnreadMessagesForUser(
        channel,
        currentUser,
        airportTimezone
      );
      unreadCounts[channel.id] = unreadMessagesForChannel.length;
    }
    return [getMapFromList(allChannels, "id"), unreadCounts];
  }, [currentUser, categories]);

  // Create the list of channel infos for rendering everywhere
  const categoriesInfoList = useMemo(() => {
    const categoriesList = [];
    if (
      !isNullOrUndefined(categories) &&
      !isNullOrUndefined(currentUser) &&
      !isNullOrUndefined(commsUsersByUuid) &&
      !isNullOrUndefined(unreadCountByChannelId) &&
      !isNullOrUndefined(turnaroundsSummary) &&
      !isNullOrUndefined(positions)
    ) {
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        const channelInfoList = category.channels.map((item) =>
          getChannelInfo(
            item,
            currentUser,
            commsUsersByUuid,
            unreadCountByChannelId,
            turnaroundsSummary,
            positions
          )
        );
        sortByField(channelInfoList, "name");
        categoriesList.push({
          category: category,
          channelInfoList: channelInfoList,
        });
      }
    }
    return categoriesList;
  }, [
    categories,
    currentUser,
    commsUsersByUuid,
    unreadCountByChannelId,
    turnaroundsSummary,
    positions,
  ]);

  // Automatically set the first channel selected if none selected
  useEffect(() => {
    if (
      categoriesInfoList?.length > 0 &&
      isNullOrUndefined(selectedChannelId)
    ) {
      let channelToSelect = null;
      for (
        let i = 0;
        i < categoriesInfoList.length && isNullOrUndefined(channelToSelect);
        i++
      ) {
        const channelInfoList = categoriesInfoList[i].channelInfoList;

        if (!isNullOrUndefined(channelInfoList)) {
          for (
            let j = 0;
            j < channelInfoList.length && isNullOrUndefined(channelToSelect);
            j++
          ) {
            const channelInfo = channelInfoList[j];
            channelToSelect = channelInfo;
          }
        }
      }
      if (!isNullOrUndefined(channelToSelect)) {
        dispatch({
          type: "setSelectedChannelId",
          value: channelToSelect.id,
        });
      }
    } else if (
      categoriesInfoList?.length > 0 &&
      !isNullOrUndefined(selectedChatRequest?.userUuid) &&
      !selectedChatRequest.isComplete &&
      !selectedChatRequest.isLoading
    ) {
      const directMessageUserUuid = selectedChatRequest.userUuid;
      // first check if the channel exists
      const directMessageChannel = getChannelForUserUuid(
        categoriesInfoList,
        directMessageUserUuid
      );
      if (!isNullOrUndefined(directMessageChannel)) {
        // Channel exists, just select it
        dispatch({
          type: "setSelectedChannelId",
          value: directMessageChannel.id,
        });
        // Notify that it is completed
        const updatedRequest = deepCopy(selectedChatRequest);
        updatedRequest.isComplete = true;
        dispatch({
          type: "setSelectedChatRequest",
          value: updatedRequest,
        });
      } else {
        // Channel does not exist, create it and allow this effect to re-run
        setIsInitializing(true);
        async function createDirectMessage() {
          await postCommsChannel(commsDispatch, {
            members: [directMessageUserUuid],
            name: "",
            type: CHAT_CATEGORY_TYPES.DIRECT,
          });
          await getChannels(commsDispatch);
          await getCommsUsers(commsDispatch);
          setIsInitializing(false);
        }
        resetEditor();
        createDirectMessage();

        // Notify that it is loading/creating
        const updatedRequest = deepCopy(selectedChatRequest);
        updatedRequest.isLoading = true;
        dispatch({
          type: "setSelectedChatRequest",
          value: updatedRequest,
        });
      }
    }
  }, [
    dispatch,
    commsDispatch,
    categoriesInfoList,
    selectedChannelId,
    selectedChatRequest,
  ]);

  useEffect(() => {
    if (isNullOrUndefined(selectedChannelId)) return;
    setCommsChannelReadRequested(true);
    chatInputRef?.current?.focus();
    resetEditor();
    scrollMessages(true);
  }, [commsDispatch, selectedChannelId, scrollMessages]);

  useEffect(() => {
    if (!isNullOrUndefined(categories) && categories.length > 0) {
      setIsInitializing(false);
    }
  }, [categories]);

  useEffect(() => {
    if (!isNullOrUndefined(selectedChannelId)) {
      getCommsChannelAttachments(commsDispatch, selectedChannelId);
    }
  }, [commsDispatch, selectedChannelId]);

  // Update read state for channel
  useEffect(() => {
    if (!commsChannelReadSaving && commsChannelReadRequested) {
      patchCommsChannelRead(commsDispatch, selectedChannelId);
      setCommsChannelReadRequested(false);
    }
  }, [
    commsDispatch,
    commsChannelReadRequested,
    commsChannelReadSaving,
    selectedChannelId,
  ]);
  // NOTE: Do NOT refresh channels here, see ConsoleDataHelper
  // Refresh channel attachments
  useEffect(() => {
    if (!commsChannelAttachmentsLoading && commsChannelAttachmentsRequested) {
      // Check if attachments missing
      const channelToCheck = channelsById[selectedChannelId];
      const messagesToCheck = !isNullOrUndefined(channelToCheck)
        ? channelToCheck.messages
        : null;
      let hasMissingAttachments = false;
      if (messagesToCheck?.length > 0) {
        for (
          let i = 0;
          !hasMissingAttachments && i < messagesToCheck.length;
          i++
        ) {
          const messageToCheck = messagesToCheck[i];
          const attachments = messageToCheck?.content?.attachments;
          const attachment = attachments?.length > 0 ? attachments[0] : null;
          if (!isNullOrUndefined(attachment?.uuid)) {
            // Make sure we have the actual attachment
            const foundAttachment = getByFieldValue(
              commsChannelAttachments,
              "uuid",
              attachment.uuid
            );
            if (isNullOrUndefined(foundAttachment)) {
              hasMissingAttachments = true;
            }
          }
        }
      }
      if (hasMissingAttachments) {
        getCommsChannelAttachments(commsDispatch, selectedChannelId);
      }
      setCommsChannelAttachmentsRequested(false);
    }
  }, [
    commsDispatch,
    commsChannelAttachmentsLoading,
    commsChannelAttachmentsRequested,
    selectedChannelId,
    commsChannelAttachments,
    channelsById,
  ]);
  // Refresh users
  useEffect(() => {
    if (!commsUsersLoading && commsUsersRequested) {
      getCommsUsers(commsDispatch);
      setCommsUsersRequested(false);
    }
  }, [commsDispatch, commsUsersRequested, commsUsersLoading]);

  useEffect(() => {
    // NOTE: Do NOT refresh channels here, see ConsoleDataHelper
    setCommsUsersRequested(true);
    const interval = setInterval(() => {
      setCommsUsersRequested(true);
      setCommsChannelAttachmentsRequested(true);
    }, POLLING_INTERVALS.COMMS);
    return () => clearInterval(interval);
  }, [commsDispatch]);

  // Select the channel that was just saved/created
  useEffect(() => {
    if (isNullOrUndefined(commsChannelPost)) return;
    const channelId = commsChannelPost?.channelId;
    const channelToSelect = !isNullOrUndefined(channelId)
      ? channelsById[channelId]
      : null;
    if (!isNullOrUndefined(channelToSelect)) {
      dispatch({
        type: "setSelectedChannelId",
        value: channelId,
      });
    }
    if (channelId === selectedChannelId) {
      commsDispatch({
        type: "setCommsChannelPost",
        value: null,
      });
    }
  }, [
    dispatch,
    commsDispatch,
    commsChannelPost,
    channelsById,
    selectedChannelId,
  ]);

  const selectedChannel = !isNullOrUndefined(selectedChannelId)
    ? channelsById[selectedChannelId]
    : null;

  const selectedChannelInfo = !isNullOrUndefined(selectedChannel)
    ? getChannelInfo(
        selectedChannel,
        currentUser,
        commsUsersByUuid,
        unreadCountByChannelId,
        turnaroundsSummary,
        positions
      )
    : null;

  const isSendDisabled = isPostingMessage || isBlank(messageText);
  const selectedChannelUnreadMessages = !isBlank(selectedChannelId)
    ? unreadCountByChannelId[selectedChannelId]
    : 0;

  function handleMessagesScroll(e) {
    if (isNullOrUndefined(selectedChannelId)) return;
    const chatBody = e.target;
    if (
      Math.round(chatBody.clientHeight + chatBody.scrollTop) ===
      chatBody.scrollHeight
    ) {
      // Reached end of the chat
      setCommsChannelReadRequested(true);
    }
  }

  function handleCreate(channelType) {
    setChannelEditorMode("create");
    setChannelEditorType(channelType);
    setChannelToEdit({
      members: [],
      name: "",
      type: channelType,
    });
  }

  function handleEditGroup() {
    setChannelEditorMode("edit");
    setChannelEditorType(CHAT_CATEGORY_TYPES.GROUP);
    const channel = channelsById[selectedChannelId];
    const members = channel.members.map((member) => member.userUuid);

    setChannelToEdit({
      leave: false,
      members: members,
      name: channel.name,
    });
  }

  async function handleSaveGroup() {
    if (channelEditorMode === "edit") {
      await putCommsChannel(commsDispatch, selectedChannelId, channelToEdit);
    } else {
      await postCommsChannel(commsDispatch, channelToEdit);
    }
    await getChannels(commsDispatch);
    await getCommsUsers(commsDispatch);
    resetEditor();
  }

  async function handleLeaveGroup() {
    await putCommsChannel(commsDispatch, selectedChannelId, {
      leave: true,
    });
    await getChannels(commsDispatch);
    await getCommsUsers(commsDispatch);
    resetEditor();
  }

  function handleCancelChannel() {
    resetEditor();
  }

  async function handleChangeChannel(updatedChannel) {
    setChannelToEdit(updatedChannel);
    if (
      channelEditorType === CHAT_CATEGORY_TYPES.DIRECT &&
      channelEditorMode === "create" &&
      updatedChannel.members.length === 1
    ) {
      // Create the DM
      await postCommsChannel(commsDispatch, updatedChannel);
      await getChannels(commsDispatch);
      await getCommsUsers(commsDispatch);
      resetEditor();
    }
  }
  function resetEditor() {
    setChannelEditorMode(null);
    setChannelToEdit(null);
    setChannelEditorType(null);
  }
  function handleUploadFile() {
    setIsUploading(true);
    getCommsAttachmentUpload(commsDispatch);
    setTimeout(() => {
      scrollMessages();
    }, 250);
  }

  // Handle uploading image attachments
  useEffect(() => {
    if (!isNullOrUndefined(commsAttachmentUpload)) {
      if (!isBlank(commsAttachmentUpload.uploadUrl)) {
        function resetAll() {
          setMessageText("");
          setIsPostingMessage(false);
          setIsUploading(false);
          chatInputRef?.current?.focus();
          scrollMessages();
        }
        const file = chatFileInputRef.current?.files[0];
        if (isNullOrUndefined(file)) {
          resetAll();
        } else {
          const reader = new FileReader();
          const attachmentUuid = commsAttachmentUpload.uuid;
          const uploadUrl = commsAttachmentUpload.uploadUrl;

          const fileType = file.type;
          const imageForUpload = new Image();
          async function fileOnloadHandler() {
            var arrayBuffer = reader.result;
            var bytes = new Uint8Array(arrayBuffer);

            await uploadCommsAttachment(
              commsDispatch,
              selectedChannelId,
              uploadUrl,
              bytes,
              fileType
            );
            await postCommsChannelMessage(commsDispatch, selectedChannelId, {
              content: {
                attachments: [
                  {
                    type: 2,
                    uuid: attachmentUuid,
                    width: imageForUpload.width,
                    height: imageForUpload.height,
                    aspectRatio: imageForUpload.width / imageForUpload.height,
                  },
                ],
              },
            });
            scrollMessages();
            setIsUploading(false);
            await getChannels(commsDispatch);
            await getCommsUsers(commsDispatch);
            resetAll();
            setCommsChannelAttachmentsRequested(true);
          }
          reader.onload = fileOnloadHandler;
          async function imageOnloadHandler() {
            reader.readAsArrayBuffer(file);
          }
          imageForUpload.onload = imageOnloadHandler;
          imageForUpload.src = URL.createObjectURL(file);
        }
      }

      commsDispatch({
        type: "setCommsAttachmentUpload",
        value: null,
      });
    }
  }, [commsDispatch, commsAttachmentUpload, selectedChannelId, scrollMessages]);

  const hasChatMessages =
    !isNullOrUndefined(selectedChannel?.messages) &&
    selectedChannel.messages.length > 0;

  const isProcessing = isUploading;
  const showChatEmptyState =
    !isInitializing && !hasChatMessages && !isProcessing;

  const lastMessage =
    selectedChannel?.messages?.length > 0
      ? selectedChannel?.messages[selectedChannel?.messages?.length - 1]
      : null;
  const isLastMessageFromCurrentUser = !isNullOrUndefined(lastMessage)
    ? lastMessage.sentBy === currentUser.uuid
    : false;
  const showUnreadMessagesBanner =
    selectedChannelUnreadMessages > 0 && !isLastMessageFromCurrentUser;

  const isCompactMode = mode === ChatModuleMode.COMPACT;

  return (
    <div className={`chat${isCompactMode ? " compact-mode" : ""}`}>
      <div className="chat-module">
        {!isCompactMode && (
          <div className="chat-side-panel">
            <div className="chat-side-panel-content">
              {categoriesInfoList &&
                categoriesInfoList.map((categoryInfo, idx) => (
                  <ChatCategory
                    key={idx}
                    category={categoryInfo.category}
                    channelInfoList={categoryInfo.channelInfoList}
                    unreadCountByChannelId={unreadCountByChannelId}
                    commsUsersByUuid={commsUsersByUuid}
                    handleCreate={handleCreate}
                  />
                ))}
            </div>
          </div>
        )}
        {isNullOrUndefined(channelEditorMode) && (
          <div className="chat-main-panel">
            {showUnreadMessagesBanner && (
              <div className="chat-unread-overlay">
                <div>
                  <button onClick={scrollMessages} className="rounded-white">
                    {t("unread_messages")}
                  </button>
                </div>
              </div>
            )}
            <div className="chat-header">
              <div>
                <div>
                  {!isInitializing && selectedChannel && (
                    <ChannelInfo
                      channelInfo={selectedChannelInfo}
                      isSelected={true}
                    />
                  )}
                </div>
                <div className="actions">
                  {!isCompactMode &&
                    selectedChannel &&
                    selectedChannelInfo.isGroup && (
                      <button className="secondary" onClick={handleEditGroup}>
                        {t("edit")}
                      </button>
                    )}
                  {isCompactMode && (
                    <div className="close-btn" onClick={onClose}>
                      <CloseBtn />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="chat-body" onScroll={handleMessagesScroll}>
              {isInitializing && <LoadingIndicator />}
              {showChatEmptyState && (
                <div className="chat-messages-empty">
                  <div>{t("nothing_here_yet")}</div>
                </div>
              )}
              <div className="chat-messages">
                {hasChatMessages &&
                  selectedChannel.messages.map((message, idx) => (
                    <ChatMessage
                      key={message.id}
                      message={message}
                      channel={selectedChannel}
                      onImageLoaded={() => {
                        scrollMessages(true);
                      }}
                      index={idx}
                    />
                  ))}
                {isUploading && (
                  <div className="chat-message outgoing">
                    <div className="chat-message-text">
                      <div>
                        <ChatBubble isLoading={true} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="chat-messages-buffer"></div>
              </div>
            </div>
            <div className="chat-footer">
              <div>
                {!isNullOrUndefined(selectedChannelId) && (
                  <>
                    <div className="chat-message-input">
                      <div>
                        <input
                          ref={chatInputRef}
                          type="text"
                          placeholder={t("type_your_message_here")}
                          value={messageText}
                          onChange={(e) => {
                            setMessageText(e.target.value);
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter" && !isSendDisabled) {
                              handleSend();
                            }
                          }}
                          data-testid="chat-message-input"
                        />
                      </div>
                      <div
                        className="chat-message-input-attachment"
                        onClick={() => {
                          chatFileInputRef.current.click();
                        }}
                      >
                        <Attachment />
                        <input
                          className="not-visible"
                          type="file"
                          ref={chatFileInputRef}
                          accept=".png,.jpeg,.jpg"
                          onChange={handleUploadFile}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={handleSend}
                        disabled={isSendDisabled}
                        data-testid="chat-message-send-btn"
                      >
                        {t("send")}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {!isNullOrUndefined(channelEditorMode) && (
          <ChatChannelEditor
            channelToEdit={channelToEdit}
            onChange={handleChangeChannel}
            onCancel={handleCancelChannel}
            onSave={handleSaveGroup}
            onLeave={handleLeaveGroup}
            channelEditorMode={channelEditorMode}
            channelEditorType={channelEditorType}
          />
        )}
      </div>
      {!isNullOrUndefined(selectedAttachment) && (
        <ChatAttachmentModal attachment={selectedAttachment} />
      )}
    </div>
  );
}

export default ChatModule;
