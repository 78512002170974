import "./styles.css";
import AlertIcon from "./AlertIcon";

function AlertBadge(props) {
  const { userAlertTypeId, title } = props;
  return (
    <div className="alert-badge">
      <AlertIcon userAlertTypeId={userAlertTypeId} />
      <span>{title}</span>
    </div>
  );
}

export default AlertBadge;
