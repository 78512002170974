import { useRef, useState } from "react";
import { useCommsContext, useCommsDispatchContext } from "../../CommsContext";
import { getByFieldValue, isBlank, isNullOrUndefined } from "../../utils";
import { scaleCommsImage } from "../../commsUtils";
import LoadingIndicator from "../LoadingIndicator";

function ChatBubble(props) {
  const { message, isLoading, onLoaded, index } = props;
  const commsDispatch = useCommsDispatchContext();
  const commsContext = useCommsContext();
  const { commsChannelAttachments } = commsContext;
  const imageRef = useRef(null);
  const bubbleRef = useRef(null);
  const [dimensions, setDimensions] = useState(null);

  const attachments = message?.content?.attachments;
  const attachment = attachments?.length > 0 ? attachments[0] : null;
  const commsChannelAttachment = !isNullOrUndefined(attachment)
    ? getByFieldValue(commsChannelAttachments, "uuid", attachment.uuid)
    : null;
  let imageWidth = 200;
  let imageHeight = 200;

  // Attachments *should* have dimensions set on them, but just in case
  const hasAttachmentDimensions =
    !isNullOrUndefined(commsChannelAttachment?.width) &&
    !isNullOrUndefined(commsChannelAttachment?.height);

  if (hasAttachmentDimensions) {
    const [scaledWidth, scaledHeight] = scaleCommsImage(
      commsChannelAttachment.width,
      commsChannelAttachment.height
    );
    imageWidth = scaledWidth;
    imageHeight = scaledHeight;
  }

  const bubbleStyle =
    !isNullOrUndefined(commsChannelAttachment) || !isNullOrUndefined(attachment)
      ? {
          backgroundImage: !isBlank(commsChannelAttachment?.url)
            ? `url(${commsChannelAttachment.url})`
            : "",
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
        }
      : {};

  function handleImageLoad() {
    if (!hasAttachmentDimensions) {
      // No image dimensions, try getting them by loading it into the browser
      imageRef.current.style.display = "";
      const size = imageRef.current.getBoundingClientRect();
      imageRef.current.style.display = "none";
      const [scaledWidth, scaledHeight] = scaleCommsImage(
        size.width,
        size.height
      );
      bubbleRef.current.style.width = `${scaledWidth}px`;
      bubbleRef.current.style.height = `${scaledHeight}px`;
      setDimensions([scaledWidth, scaledHeight]);
    } else {
      setDimensions([imageWidth, imageHeight]);
    }
    onLoaded();
  }

  const hasValidAttachment = !isNullOrUndefined(commsChannelAttachment);
  function handleClick() {
    if (hasValidAttachment) {
      commsDispatch({
        type: "setSelectedAttachment",
        value: {
          ...commsChannelAttachment,
          dimensions: dimensions,
        },
      });
    }
  }

  const testId = !isNullOrUndefined(index) ? `chat-bubble-${index}` : null;
  return (
    <div
      className={`chat-message-bubble${
        hasValidAttachment ? " attachment" : ""
      }${message?.isTemp ? " animate" : " chat-bubble"}`}
      style={bubbleStyle}
      ref={bubbleRef}
      onClick={handleClick}
      data-testid={testId}
    >
      <div>
        {message?.content.text}
        {!isNullOrUndefined(commsChannelAttachment) && (
          <div className="hideout">
            <img
              ref={imageRef}
              src={commsChannelAttachment.url}
              onLoad={handleImageLoad}
              alt=""
              style={{ display: "none" }}
            />
          </div>
        )}
        {isLoading && <LoadingIndicator />}
      </div>
    </div>
  );
}
export default ChatBubble;
