import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  isBlank,
  isNullOrUndefined,
  isValidEmail,
  sortByField,
} from "../../utils";
import { ReactComponent as Cross } from "../../assets/cross.svg";
import { selectInputFix } from "../../styleUtils";
import Checkbox from "../Checkbox";
import { useTranslation } from "react-i18next";

export default function CrewModal(props) {
  const {
    onCloseHandler,
    onSaveHandler,
    selectedCrewMember,
    userGroups,
    certifications,
    userSaving,
  } = props;
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userGroupUuid, setUserGroupUuid] = useState("");
  const [userCertifications, setUserCertifications] = useState([]);

  useEffect(() => {
    if (!isNullOrUndefined(selectedCrewMember)) {
      setFirstName(selectedCrewMember.firstName);
      setLastName(selectedCrewMember.lastName);
      setEmail(selectedCrewMember.email);
      setUserGroupUuid(selectedCrewMember.userGroupUuid);
      const selectedCrewMemberCertifications = !isNullOrUndefined(
        selectedCrewMember.certifications
      )
        ? selectedCrewMember.certifications.map((item) => item.uuid)
        : [];
      setUserCertifications(selectedCrewMemberCertifications);
    }
  }, [selectedCrewMember]);

  const isExistingUser = !isNullOrUndefined(selectedCrewMember)
    ? !isBlank(selectedCrewMember.uuid)
    : null;

  const handleClose = () => {
    // reset fields
    setFirstName("");
    setLastName("");
    setEmail("");
    setUserGroupUuid("");
    setUserCertifications([]);

    if (!isNullOrUndefined(onCloseHandler)) {
      onCloseHandler();
    }
  };
  sortByField(certifications, "name");

  const handleSave = () => {
    if (!isNullOrUndefined(onSaveHandler)) {
      onSaveHandler({
        uuid: selectedCrewMember.uuid,
        firstName: firstName,
        lastName: lastName,
        email: email,
        userGroupUuid: userGroupUuid,
        certifications: userCertifications,
      });
    }
  };

  const toggleUserCertification = (certificationId) => {
    const userCertificationsUpdated = [...userCertifications];
    const certificationIdx = userCertificationsUpdated.indexOf(certificationId);
    if (certificationIdx >= 0) {
      userCertificationsUpdated.splice(certificationIdx, 1);
    } else {
      userCertificationsUpdated.push(certificationId);
    }
    setUserCertifications(userCertificationsUpdated);
  };

  const isValid = () => {
    const firstNameValid = !isBlank(firstName);
    const lastNameValid = !isBlank(lastName);
    const emailValid = isValidEmail(email);
    const userGroupUuidValid = !isBlank(userGroupUuid);
    return firstNameValid && lastNameValid && emailValid && userGroupUuidValid;
  };

  return (
    <div>
      <Modal
        open={!isNullOrUndefined(selectedCrewMember)}
        onClose={handleClose}
      >
        <div className="modal">
          <div>
            <div className="modal-header">
              <h2>{isExistingUser ? t("edit_user") : t("add_user")}</h2>
              <div className="button-icon" onClick={handleClose}>
                <Cross />
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div
              className="modal-container"
              style={{
                gridTemplateRows: "repeat(4, auto) 1fr",
              }}
            >
              <div className="modal-field-row">
                <label>{t("first_name")}</label>
                <input
                  type="text"
                  placeholder={t("first_name")}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="modal-field-row">
                <label>{t("last_name")}</label>
                <input
                  type="text"
                  placeholder={t("last_name")}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
              <div className="modal-field-row">
                <label>{t("work_email")}</label>
                <input
                  type="text"
                  placeholder={t("work_email")}
                  value={email}
                  disabled={isExistingUser}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="modal-field-row">
                <label>{t("user_group")}</label>
                <select
                  value={userGroupUuid}
                  onChange={(e) => {
                    setUserGroupUuid(e.target.value);
                  }}
                  style={selectInputFix}
                >
                  {!isNullOrUndefined(userGroups) &&
                    userGroups.map((userGroup) => (
                      <option key={userGroup.uuid} value={userGroup.uuid}>
                        {userGroup.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="modal-field-row">
                <div className="certifications-header">
                  <div>
                    <label>{t("certifications")}</label>
                  </div>
                  <div>
                    <Checkbox
                      changeHandler={() => {
                        if (
                          certifications?.length === userCertifications?.length
                        ) {
                          // All selected, so make all unselected
                          setUserCertifications([]);
                        } else {
                          // Not all selected, so make all selected
                          const userCertificationsUpdated = certifications.map(
                            (item) => item.uuid
                          );
                          setUserCertifications(userCertificationsUpdated);
                        }
                      }}
                      isChecked={
                        certifications?.length === userCertifications?.length
                      }
                    />
                  </div>
                </div>
                <div className="certifications-container">
                  <div className="certifications-list">
                    {!isNullOrUndefined(certifications) &&
                      certifications.map((item) => {
                        const isSelected = userCertifications.includes(
                          item.uuid
                        );
                        return (
                          <div
                            className={`option-row${
                              isSelected ? " selected" : ""
                            } `}
                            key={item.uuid}
                          >
                            <div>
                              <label
                                onClick={() => {
                                  toggleUserCertification(item.uuid);
                                }}
                              >
                                {item.name}
                              </label>
                            </div>
                            <div>
                              <Checkbox
                                changeHandler={() => {
                                  toggleUserCertification(item.uuid);
                                }}
                                isChecked={isSelected}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer">
              <div>
                <button className="secondary" onClick={handleClose}>
                  {t("cancel")}
                </button>
              </div>
              <div>
                <button
                  onClick={handleSave}
                  disabled={!isValid() || userSaving}
                >
                  {isExistingUser ? t("save") : t("add_non_cap")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
