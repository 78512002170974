import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { isNullOrUndefined } from "../../utils";

function AlertMessage(props) {
  const { message, handleClose, autoClose } = props;

  const action = (
    <IconButton
      size="small"
      onClick={handleClose}
      sx={{
        bgcolor: "#4A4C56",
        color: "#FFF",
        "&:hover": {
          backgroundColor: "#1779DA",
        },
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={autoClose}
      open={!isNullOrUndefined(message)}
      onClose={handleClose}
      message={message}
      action={action}
    />
  );
}

export default AlertMessage;
