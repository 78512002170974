import BlueCircle from "../../assets/diagram/blue-circle.svg";
import Admin from "../../assets/diagram/admin.png";
import ACTruck from "../../assets/diagram/ac-truck.png";
import AirStartUnit from "../../assets/diagram/air-start-unit.png";
import BaggageTractor from "../../assets/diagram/baggage-tractor.png";
import BeltLoader from "../../assets/diagram/belt-loader.png";
import BobtailTruck from "../../assets/diagram/bobtail-truck.png";
import CabinCleaningTruck from "../../assets/diagram/cabin-cleaning-truck.png";
import CargoTractor from "../../assets/diagram/cargo-tractor.png";
import CateringTruck from "../../assets/diagram/catering-truck.png";
import Engineering from "../../assets/diagram/engineering.png";
import Extra from "../../assets/diagram/extra.png";
import FuelTruck from "../../assets/diagram/fuel-truck.png";
import GarbageTruck from "../../assets/diagram/garbage-truck.png";
import GroundPowerUnit from "../../assets/diagram/ground-power-unit.png";
import LavatoryTruck from "../../assets/diagram/lavatory-truck.png";
import LowerDeckLoader from "../../assets/diagram/lower-deck-loader.png";
import MainDeckLoader from "../../assets/diagram/main-deck-loader.png";
import Maintenance from "../../assets/diagram/maintenance-truck.png";
import Other from "../../assets/diagram/other.png";
import PotableWaterTruck from "../../assets/diagram/potable-water-truck.png";
import PushbackTug from "../../assets/diagram/pushback-tug.png";
import Stairs from "../../assets/diagram/stairs.png";
import StairTruck from "../../assets/diagram/stair-truck.png";
import UtilityVehicle from "../../assets/diagram/utility-vehicle.png";

function TemplateDiagramLayer(props) {
  const { gseType, onHoverOver, onHoverOut, isActive } = props;
  return (
    <>
      <div
        className={`template-diagram-layer overlay ${gseType}`}
        onMouseOver={() => {
          onHoverOver(gseType);
        }}
        onMouseOut={() => {
          onHoverOut();
        }}
      ></div>
      <div
        className={`template-diagram-layer gse ${gseType} ${
          isActive ? "active" : ""
        }`}
      >
        {gseType === "admin" && (
          <img
            src={Admin}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "ac-truck" && (
          <img
            src={ACTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "air-start-unit" && (
          <img
            src={AirStartUnit}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "baggage-tractor" && (
          <img
            src={BaggageTractor}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "belt-loader" && (
          <img
            src={BeltLoader}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "bobtail-truck" && (
          <img
            src={BobtailTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "cabin-cleaning-truck" && (
          <img
            src={CabinCleaningTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "cargo-tractor" && (
          <img
            src={CargoTractor}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "catering-truck" && (
          <img
            src={CateringTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "engineering" && (
          <img
            src={Engineering}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "extra" && (
          <img
            src={Extra}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "fuel-truck" && (
          <img
            src={FuelTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "garbage-truck" && (
          <img
            src={GarbageTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "ground-power-unit" && (
          <img
            src={GroundPowerUnit}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "lavatory-truck" && (
          <img
            src={LavatoryTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "lower-deck-loader" && (
          <img
            src={LowerDeckLoader}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "main-deck-loader" && (
          <img
            src={MainDeckLoader}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "maintenance" && (
          <img
            src={Maintenance}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "other" && (
          <img
            src={Other}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "potable-water-truck" && (
          <img
            src={PotableWaterTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "pushback-tug" && (
          <img
            src={PushbackTug}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "stairs" && (
          <img
            src={Stairs}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "stair-truck" && (
          <img
            src={StairTruck}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
        {gseType === "utility-vehicle" && (
          <img
            src={UtilityVehicle}
            alt=""
            width="100%"
            height="100%"
            className="drop-shadow"
          />
        )}
      </div>
      <div
        className={`template-diagram-layer underlay ${gseType} ${
          isActive ? "active" : ""
        }`}
        style={{ backgroundImage: `url(${BlueCircle})` }}
      ></div>
    </>
  );
}

export default TemplateDiagramLayer;
