import { useTranslation } from "react-i18next";
import { CHAT_CATEGORY_TYPES } from "../../commsUtils";
import ChatChannel from "./ChatChannel";
import { isNullOrUndefined } from "../../utils";

function ChatCategory(props) {
  const { t } = useTranslation();
  const { category, channelInfoList, handleCreate } = props;

  const canCreateNew =
    category.channelGroupType === CHAT_CATEGORY_TYPES.GROUP ||
    category.channelGroupType === CHAT_CATEGORY_TYPES.DIRECT;

  let emptyStateText = null;

  if (category.channelGroupType === CHAT_CATEGORY_TYPES.GROUP) {
    emptyStateText = t("comms_group_message");
  } else if (category.channelGroupType === CHAT_CATEGORY_TYPES.DIRECT) {
    emptyStateText = t("comms_dm_message");
  } else if (category.channelGroupType === CHAT_CATEGORY_TYPES.TURNAROUND) {
    emptyStateText = t("comms_turnaround_message");
  }

  return (
    <>
      <div className="chat-side-panel-item">
        <div className="chat-category">{category.channelGroupCategory}</div>
        <div>
          {canCreateNew && (
            <div>
              <button
                className="secondary"
                onClick={() => {
                  handleCreate(category.channelGroupType);
                }}
                data-testid={`chat-create-new-${category.channelGroupType}`}
              >
                {t("create_new")}
              </button>
            </div>
          )}
        </div>
      </div>
      {(isNullOrUndefined(channelInfoList) || channelInfoList.length === 0) && (
        <div className="chat-channel-empty">
          <div>
            <div className="chat-channel-empty-text">{emptyStateText}</div>
          </div>
        </div>
      )}
      {channelInfoList &&
        channelInfoList.map((channelInfo) => (
          <ChatChannel key={channelInfo.id} channelInfo={channelInfo} />
        ))}
    </>
  );
}

export default ChatCategory;
