import "./styles.css";
import { isNullOrUndefined } from "../../utils";
import { MAPINFO_USER_STATUS } from "../../mapUtils";
import UserPic, { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import { useMainDispatchContext } from "../../MainContext";
import { CHAT_CATEGORY_TYPES } from "../../commsUtils";

function CrewInfo(props) {
  const dispatch = useMainDispatchContext();
  const {
    user,
    details,
    mapStatus,
    userPicSize,
    userPicTheme,
    hideInfo = false,
    showActions = false,
  } = props;

  const currentMapStatus = !isNullOrUndefined(mapStatus)
    ? mapStatus
    : MAPINFO_USER_STATUS.NONE;
  return (
    <div className={`crew-info${showActions ? " show-actions" : ""}`}>
      <div className="crew-info-pic">
        <UserPic
          size={userPicSize || UserPicSize.MEDIUM}
          theme={userPicTheme || UserPicTheme.DARK}
          user={user}
        />
        <div
          className={`crew-info-status status-${currentMapStatus} ${
            userPicTheme || UserPicTheme.DARK
          }`}
        ></div>
      </div>
      <div className="crew-info-label">
        {!hideInfo && (
          <>
            <div className="crew-info-name">{`${user.firstName} ${user.lastName}`}</div>
            <div className="crew-info-detail">{details}</div>
          </>
        )}
      </div>
      {showActions && (
        <div className="crew-info-actions">
          <button
            className="outlined"
            onClick={() => {
              dispatch({
                type: "setSelectedChatRequest",
                value: {
                  type: CHAT_CATEGORY_TYPES.DIRECT,
                  userUuid: user.uuid,
                },
              });
            }}
          >
            Chat
          </button>
        </div>
      )}
    </div>
  );
}

export default CrewInfo;
