import "./styles.css";

function ToggleSwitch(props) {
  const { isSelected, onChange } = props;
  return (
    <div
      className={`toggle-switch ${isSelected ? " selected" : ""}`}
      onClick={() => {
        onChange(!isSelected);
      }}
    >
      <div>
        <div className="knob"></div>
      </div>
    </div>
  );
}

export default ToggleSwitch;
