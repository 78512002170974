import "./styles.css";

function TabControl(props) {
  const { selectedTabIdx, tabNames, clickHandler } = props;

  return (
    <div className="tab-control">
      {tabNames &&
        tabNames.map((tab, idx) => (
          <div
            className={`${selectedTabIdx === idx ? "selected" : ""}`}
            onClick={() => {
              clickHandler(idx);
            }}
            key={tab}
          >
            <span>{tab}</span>
          </div>
        ))}
    </div>
  );
}

export default TabControl;
