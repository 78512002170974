import { useTranslation } from "react-i18next";
import A220 from "../../assets/aircraft/A220.png";
import A300 from "../../assets/aircraft/A300.png";
import A310 from "../../assets/aircraft/A310.png";
import A318 from "../../assets/aircraft/A318.png";
import A319 from "../../assets/aircraft/A319.png";
import A320 from "../../assets/aircraft/A320.png";
import A321 from "../../assets/aircraft/A321.png";
import A330 from "../../assets/aircraft/A330.png";
import A340 from "../../assets/aircraft/A340.png";
import A350 from "../../assets/aircraft/A350.png";
import A380 from "../../assets/aircraft/A380.png";

import B727 from "../../assets/aircraft/B727.png";
import B737 from "../../assets/aircraft/B737.png";
import B747 from "../../assets/aircraft/B747.png";
import B757 from "../../assets/aircraft/B757.png";
import B767 from "../../assets/aircraft/B767.png";
import B777 from "../../assets/aircraft/B777.png";
import B787 from "../../assets/aircraft/B787.png";

import E170 from "../../assets/aircraft/E170.png";
import E175 from "../../assets/aircraft/E175.png";
import E190 from "../../assets/aircraft/E190.png";
import E195 from "../../assets/aircraft/E195.png";

import DC8 from "../../assets/aircraft/DC8.png";
import DC9 from "../../assets/aircraft/DC9.png";
import DC10 from "../../assets/aircraft/DC10.png";
import MD80 from "../../assets/aircraft/MD80.png";
import MD90 from "../../assets/aircraft/MD90.png";
import MD11 from "../../assets/aircraft/MD11.png";

import TemplateAircraftTile from "./TemplateAircraftTile";
import { filterList, isBlank } from "../../utils";

const AircraftTypes = {
  A220: A220,
  A300: A300,
  A310: A310,
  A318: A318,
  A319: A319,
  A320: A320,
  A321: A321,
  A330: A330,
  A340: A340,
  A350: A350,
  A380: A380,
  B727: B727,
  B737: B737,
  B747: B747,
  B757: B757,
  B767: B767,
  B777: B777,
  B787: B787,
  E170: E170,
  E175: E175,
  E190: E190,
  E195: E195,
  DC8: DC8,
  DC9: DC9,
  DC10: DC10,
  MD80: MD80,
  MD90: MD90,
  MD11: MD11,
};

function TemplateAircraftSelector(props) {
  const { t } = useTranslation();
  const { onClose, templatesByAircraftType, searchQuery } = props;

  const searchTerm = searchQuery?.searchTerm?.toLowerCase().trim();
  const aircraftTypesList = !isBlank(searchTerm)
    ? filterList(Object.keys(AircraftTypes), (item) => {
        return item.toLowerCase().indexOf(searchTerm) > -1;
      })
    : Object.keys(AircraftTypes);

  return (
    <div className="template-aircraft-selector templates-content">
      <div className="templates-content-header">
        <div>
          <h3>Create turnaround template</h3>
        </div>
        <div>
          <div>
            <button onClick={onClose} className="secondary">
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
      <div className="templates-aircraft-tiles">
        {aircraftTypesList &&
          aircraftTypesList.map((key) => (
            <TemplateAircraftTile
              key={key}
              aircraft={AircraftTypes[key]}
              name={key}
              templateCount={templatesByAircraftType[key]}
            />
          ))}
      </div>
    </div>
  );
}

export default TemplateAircraftSelector;
