import Aircraft from "../../assets/diagram/aircraft.png";
import { getByFieldValue, isNullOrUndefined } from "../../utils";
import { useState } from "react";
import TemplateDiagramLayer from "./TemplateDiagramLayer";

function TemplateDiagram(props) {
  const { aircraftName, enabledGSETypes, selectedOperation } = props;
  const [details, setDetails] = useState(null);

  // Enabled => GSE is visible on the diagram
  // Selected Operation => GSE is in "active" state
  const visibleGseTypesInfo = [];
  const visibleGseTypes = [];
  const activeGseTypes = [];
  for (const key in enabledGSETypes) {
    const enabledGSEType = enabledGSETypes[key];
    visibleGseTypes.push(enabledGSEType.gseType);
    visibleGseTypesInfo.push(enabledGSEType);
    if (
      !isNullOrUndefined(selectedOperation) &&
      !isNullOrUndefined(enabledGSEType.operationsInfo[selectedOperation?.uuid])
    ) {
      activeGseTypes.push(enabledGSEType.gseType);
    }
  }

  function showDetails(gseType) {
    const gseTypeInfo = getByFieldValue(
      visibleGseTypesInfo,
      "gseType",
      gseType
    );
    const gseLayer = document.querySelector(`.${gseType}.gse`);
    const underlayLayer = document.querySelector(`.${gseType}.underlay`);
    if (!isNullOrUndefined(gseLayer)) {
      gseLayer.classList.add("hovered");
    }
    if (!isNullOrUndefined(underlayLayer)) {
      underlayLayer.classList.add("hovered");
    }
    setDetails(gseTypeInfo);
  }
  function hideDetails() {
    const gseMarker = document.querySelector(".gse.hovered");
    const underlayLayer = document.querySelector(`.underlay.hovered`);
    if (!isNullOrUndefined(gseMarker)) {
      gseMarker.classList.remove("hovered");
    }
    if (!isNullOrUndefined(underlayLayer)) {
      underlayLayer.classList.remove("hovered");
    }
    setDetails(null);
  }
  const detailGseName = details?.certification.name;
  const detailOperationsInfo = details?.operationsInfo;
  const detailOperationsUuids = detailOperationsInfo
    ? Object.keys(detailOperationsInfo)
    : null;
  const detailOperations = !isNullOrUndefined(detailOperationsUuids)
    ? detailOperationsUuids.map((uuid) => detailOperationsInfo[uuid])
    : [];
  return (
    <div className="template-diagram">
      <div>
        <div className="template-diagram-header">
          <h3>{aircraftName}</h3>
        </div>
        <div className="template-diagram-content">
          <div
            className={`template-diagram-details${
              isNullOrUndefined(details) ? "" : " active"
            }`}
          >
            <div>{detailGseName}</div>
            {detailOperations &&
              detailOperations.map((detailOperation) => (
                <div
                  className="template-diagram-details-row"
                  key={detailOperation.operation.uuid}
                >
                  <div>{detailOperation.operation.name}</div>
                  <div>{detailOperation.quantity}</div>
                </div>
              ))}
          </div>
          <div className="template-diagram-layer aircraft ">
            <img
              src={Aircraft}
              width="100%"
              height="100%"
              className="drop-shadow"
              alt=""
            />
          </div>
          {visibleGseTypes &&
            visibleGseTypes.map((gseType) => (
              <TemplateDiagramLayer
                key={gseType}
                gseType={gseType}
                onHoverOver={showDetails}
                onHoverOut={hideDetails}
                isActive={activeGseTypes.includes(gseType)}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default TemplateDiagram;
