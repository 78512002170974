import { formatDatetime, isNullOrUndefined } from "../../utils";
import UserBadge from "../UserBadge";

export const VALUE_TYPES = {
  NUMBER: "number",
  PERCENT: "percent",
  DATE: "date",
};
const LeaderboardColumns = {
  LeaderboardUser: {
    id: "crew-member",
    displayName: "crew_member",
    formatter: (item) => {
      return <UserBadge user={item.user} />;
    },
    sortValue: (item) => `${item.user.firstName} ${item.user.lastName}`,
  },
  ActiveDaysCount: {
    id: "activeDaysCount",
    displayName: "active_days",
    formatter: (item) => {
      return <>{item.activeDaysCount}</>;
    },
    sortValue: (item) => item.activeDaysCount,
    valueType: VALUE_TYPES.NUMBER,
    tooltip: "active_days_tooltip",
  },
  LastLoginTime: {
    id: "lastLoginTime",
    displayName: "last_login",
    formatter: (item) => {
      if (isNullOrUndefined(item.lastLoginTime)) return "-";
      return (
        <>
          <div title={item.airportTimezone}>
            {formatDatetime(item.lastLoginTime, item.airportTimezone)}
          </div>
        </>
      );
    },
    sortValue: (item) => new Date(item.lastLoginTime),
    valueType: VALUE_TYPES.DATE,
  },
  TurnaroundsPresentAtAircraftCount: {
    id: "turnaroundsPresentAtAircraftCount",
    displayName: "turnarounds_present_at_aircraft",
    formatter: (item) => {
      return <>{item.turnaroundsPresentAtAircraftCount}</>;
    },
    sortValue: (item) => item.turnaroundsPresentAtAircraftCount,
    valueType: VALUE_TYPES.NUMBER,
    tooltip: "turnarounds_present_at_aircraft_tooltip",
  },
  TurnaroundsServicedCount: {
    id: "turnaroundsServicedCount",
    displayName: "turnarounds_serviced",
    formatter: (item) => {
      return <>{item.turnaroundsServicedCount}</>;
    },
    sortValue: (item) => item.turnaroundsServicedCount,
    valueType: VALUE_TYPES.NUMBER,
    tooltip: "turnarounds_serviced_tooltip",
  },
  ManualOperationStartedCount: {
    id: "manualOperationStartedCount",
    displayName: "manual_operation_started",
    formatter: (item) => {
      return <>{item.manualOperationStartedCount}</>;
    },
    sortValue: (item) => item.manualOperationStartedCount,
    valueType: VALUE_TYPES.NUMBER,
    tooltip: "manual_operation_started_tooltip",
  },
  ManualOperationCompletionCount: {
    id: "manualOperationCompletionCount",
    displayName: "manual_operation_completed",
    formatter: (item) => {
      return <>{item.manualOperationCompletionCount}</>;
    },
    sortValue: (item) => item.manualOperationCompletionCount,
    valueType: VALUE_TYPES.NUMBER,
    tooltip: "manual_operation_completed_tooltip",
  },
  OperationsNotCompletedCount: {
    id: "operationsNotCompletedCount",
    displayName: "operations_not_completed",
    formatter: (item) => {
      return <>{item.operationsNotCompletedCount}</>;
    },
    sortValue: (item) => item.operationsNotCompletedCount,
    valueType: VALUE_TYPES.NUMBER,
    tooltip: "operations_not_completed_tooltip",
  },
  OperationCompletionPercent: {
    id: "operationCompletionPercent",
    displayName: "operation_completion_rate",
    formatter: (item) => {
      return <>{`${Math.round(item.operationCompletionPercent)}%`}</>;
    },
    sortValue: (item) => item.operationCompletionPercent,
    valueType: VALUE_TYPES.PERCENT,
    tooltip: "operation_completion_rate_tooltip",
  },

  OperationsDispatched: {
    id: "operationsDispatched",
    displayName: "operations_dispatched",
    formatter: (item) => {
      return <>{item.operationsDispatched}</>;
    },
    sortValue: (item) => item.operationsDispatched,
    valueType: VALUE_TYPES.NUMBER,
    tooltip: "operations_dispatched_tooltip",
  },
  DispatchesAcceptedCount: {
    id: "dispatchesAcceptedCount",
    displayName: "dispatched_accepted",
    formatter: (item) => {
      return <>{item.dispatchesAcceptedCount}</>;
    },
    sortValue: (item) => item.dispatchesAcceptedCount,
    valueType: VALUE_TYPES.NUMBER,
    tooltip: "dispatched_accepted_tooltip",
  },
  DispatchAcceptancePercent: {
    id: "dispatchAcceptancePercent",
    displayName: "dispatch_acceptance_rate",
    formatter: (item) => {
      return <>{`${Math.round(item.dispatchAcceptancePercent)}%`}</>;
    },
    sortValue: (item) => item.dispatchAcceptancePercent,
    valueType: VALUE_TYPES.PERCENT,
    tooltip: "dispatch_acceptance_rate_tooltip",
  },
};

export default LeaderboardColumns;
