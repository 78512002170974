import "./styles.css";
import moment from "moment-timezone";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useEffect, useState } from "react";
import { isNullOrUndefined } from "../../utils";
import { DEFAULT_TIMEZONE, MIN_DATE } from "../../constants";
import { getLeaderboard, getLeaderboardExport } from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import RangePicker from "../ButtonDatePicker/RangePicker";
import DataTable, { ColumnNames } from "../DataTable";
import LeaderboardChart from "./LeaderboardChart";
import { createSortOptions } from "../DataTable/utils";
import DataExport from "../DataExport";
import { useTranslation } from "react-i18next";

function Leaderboard() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, leaderboard, leaderboardSaving } = mainContext;
  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const mMinDate = moment(MIN_DATE).tz(airportTimezone).startOf("day");
  const mToday = moment().tz(airportTimezone).startOf("minute");
  const mThirtyDaysAgo = moment(mToday).add(-30, "day");
  const [selectedStartDate, setSelectedStartDate] = useState(mThirtyDaysAgo);
  const [selectedEndDate, setSelectedEndDate] = useState(mToday);
  const defaultSortOptions = createSortOptions(
    ColumnNames.TurnaroundsServicedCount,
    true
  );
  const [chartSortOptions, setChartSortOptions] = useState(defaultSortOptions);

  useEffect(() => {
    // convert to UTC
    const criteria = {
      startTime: moment.utc(selectedStartDate).format(),
      endTime: moment.utc(moment(selectedEndDate).endOf("day")).format(),
    };
    getLeaderboard(dispatch, criteria);
  }, [dispatch, airportTimezone, selectedStartDate, selectedEndDate]);

  const handleChangeDateRange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };
  const data = !isNullOrUndefined(leaderboard) ? leaderboard : [];
  const leaderboardList = !isNullOrUndefined(data)
    ? data.map((item) => {
        return {
          ...item,
          airportTimezone: airportTimezone,
        };
      })
    : [];

  function handleDataExport() {
    const criteria = {
      startTime: moment.utc(selectedStartDate).format(),
      endTime: moment.utc(moment(selectedEndDate).endOf("day")).format(),
    };
    getLeaderboardExport(dispatch, criteria);
  }

  return (
    <div className="leaderboard">
      <div className="leaderboard-container">
        <div className="metrics-filters">
          <div>
            <div>
              <h3>{t("crew_leaderboard")}</h3>
            </div>
            <div className="actions">
              <div>
                <RangePicker
                  today={mToday}
                  airportTimezone={airportTimezone}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  minDate={mMinDate}
                  maxDate={mToday}
                  maxRange={35}
                  onChange={(startValue, endValue) => {
                    handleChangeDateRange(startValue, endValue);
                  }}
                />
              </div>
              <DataExport handleDataExport={handleDataExport} />
            </div>
          </div>
        </div>
        <div className="leaderboard-content">
          {leaderboardSaving && <LoadingIndicator />}
          {!leaderboardSaving && (
            <LeaderboardChart
              sortBy={chartSortOptions}
              dataList={leaderboardList}
            />
          )}
          {!leaderboardSaving && (
            <DataTable
              initialSort={ColumnNames.TurnaroundsServicedCount.id}
              initialSortDesc={true}
              fixedWidths={["240px", "100px"]}
              dataList={leaderboardList}
              onChangeSort={(sortOptions) => {
                setChartSortOptions(sortOptions);
              }}
              showNumberedRows={true}
              columnNames={[
                ColumnNames.LeaderboardUser,
                ColumnNames.TurnaroundsServicedCount,
                ColumnNames.TurnaroundsPresentAtAircraftCount,
                ColumnNames.OperationsDispatched,
                ColumnNames.DispatchesAcceptedCount,
                ColumnNames.DispatchAcceptancePercent,
                ColumnNames.ManualOperationStartedCount,
                ColumnNames.ManualOperationCompletionCount,
                ColumnNames.OperationCompletionPercent,
                ColumnNames.OperationsNotCompletedCount,
                ColumnNames.ActiveDaysCount,
                ColumnNames.LastLoginTime,
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default Leaderboard;
