import { CookieManager } from "./cookies";
import { getByFieldValue, isBlank, isNullOrUndefined } from "./utils";

const SIGNATURE_START = "/deployments/";
const SIGNATURE_END = "profile-pictures/";

export function getSignedProfileImage(user, cacheBuster, fullSize) {
  // Use the full size image for profile pics
  // as there is some latency on the thumbnail when uploaded
  const userImage = fullSize
    ? user?.profilePicture?.fullSize
    : user?.profilePicture?.thumbnail;

  return getSignedImageSrc(userImage, cacheBuster, fullSize);
}

export function getSignedImageSrc(unsignedImage, cacheBuster, fullSize) {
  const mediaSignaturesValue = CookieManager.getCookieValue("mediaSignatures");
  const mediaSignatures = !isBlank(mediaSignaturesValue)
    ? JSON.parse(mediaSignaturesValue)
    : null;

  let userImageSigned = null;
  if (!isBlank(unsignedImage) && mediaSignatures?.length > 0) {
    const signatureStartIdx = unsignedImage.indexOf(SIGNATURE_START);
    const signatureEndIdx =
      unsignedImage.indexOf(SIGNATURE_END) + SIGNATURE_END.length;
    const signatureName = unsignedImage.substring(
      signatureStartIdx,
      signatureEndIdx
    );
    const mediaSignature = getByFieldValue(
      mediaSignatures,
      "name",
      signatureName
    );
    if (!isNullOrUndefined(mediaSignature?.signature)) {
      userImageSigned = `${unsignedImage}${mediaSignature.signature}${
        !isNullOrUndefined(cacheBuster) ? `&cb=${cacheBuster}` : ""
      }`;
    }
  }
  return userImageSigned;
}
