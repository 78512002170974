import { useState } from "react";
import { useCommsContext } from "../../CommsContext";
import { useMainContext } from "../../MainContext";
import { getUserInfo } from "../../userUtils";
import {
  deepCopy,
  filterBySearchQuery,
  getByFieldValue,
  isBlank,
  isNullOrUndefined,
  makeTestFriendlyString,
  sortByField,
} from "../../utils";
import Checkbox from "../Checkbox";
import CrewInfo from "../CrewInfo";
import { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import { useTranslation } from "react-i18next";
import { CHAT_CATEGORY_TYPES } from "../../commsUtils";

function ChatChannelEditor(props) {
  const { t } = useTranslation();
  const {
    channelToEdit,
    onChange,
    onCancel,
    onSave,
    onLeave,
    channelEditorMode,
    channelEditorType,
  } = props;
  const commsContext = useCommsContext();
  const { commsUsers, positions } = commsContext;
  const mainContext = useMainContext();
  const { currentUser, turnaroundsForDispatch } = mainContext;
  const [searchQuery, setSearchQuery] = useState("");

  const userInfoList = [];

  if (!isNullOrUndefined(channelToEdit) && !isNullOrUndefined(commsUsers)) {
    for (let i = 0; i < commsUsers.length; i++) {
      const commsUser = commsUsers[i];
      const user = getByFieldValue(commsUsers, "uuid", commsUser.uuid);
      const userInfo = !isNullOrUndefined(user)
        ? getUserInfo(user, turnaroundsForDispatch, positions)
        : null;
      if (currentUser.uuid !== commsUser.uuid && !isNullOrUndefined(userInfo)) {
        userInfoList.push(userInfo);
      }
    }
    sortByField(userInfoList, "fullName");
  }
  const filteredCommsUsers = !isBlank(searchQuery)
    ? filterBySearchQuery(searchQuery, userInfoList, "fullName")
    : userInfoList;

  function handleToggleChatMember(value) {
    const channelUpdated = deepCopy(channelToEdit);
    const idx = channelUpdated.members.indexOf(value);
    if (idx > -1) {
      channelUpdated.members.splice(idx, 1);
    } else {
      channelUpdated.members.push(value);
    }
    onChange(channelUpdated);
  }

  const isSaveButtonDisabled =
    isBlank(channelToEdit.name) || channelToEdit.members.length < 1;

  const userInfoListToRender = [];
  if (
    !isNullOrUndefined(channelToEdit?.members) &&
    channelToEdit?.members?.length > 0
  ) {
    for (let i = 0; i < channelToEdit.members.length; i++) {
      const userUuid = channelToEdit.members[i];
      const user = getByFieldValue(commsUsers, "uuid", userUuid);
      const userInfo = !isNullOrUndefined(user) ? getUserInfo(user) : null;
      if (!isNullOrUndefined(userInfo)) {
        userInfoListToRender.push(userInfo);
      }
    }
  }

  return (
    <div className="chat-channel-editor">
      <div className="chat-channel-editor-header">
        <div>
          <div>
            <button className="secondary" onClick={onCancel}>
              {t("cancel")}
            </button>
          </div>
          <div></div>
          <div>
            {channelEditorType === CHAT_CATEGORY_TYPES.GROUP &&
              channelEditorMode === "edit" && (
                <button className="danger-outline" onClick={onLeave}>
                  {t("leave_group")}
                </button>
              )}
          </div>
          <div>
            {channelEditorType === CHAT_CATEGORY_TYPES.GROUP && (
              <button disabled={isSaveButtonDisabled} onClick={onSave}>
                {t("save")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="chat-channel-editor-container">
        <div className="chat-channel-editor-details">
          <div>
            {channelEditorType === CHAT_CATEGORY_TYPES.GROUP && (
              <div>
                <input
                  type="text"
                  value={channelToEdit.name}
                  maxLength={40}
                  onChange={(e) => {
                    const channelUpdated = deepCopy(channelToEdit);
                    channelUpdated.name = e.target.value;
                    onChange(channelUpdated);
                  }}
                  placeholder={t("type_group_name_here")}
                />
              </div>
            )}
            {channelEditorType === CHAT_CATEGORY_TYPES.GROUP && (
              <div>
                <div className="chat-channel-member-badges">
                  <div>
                    <label>{t("members_colon")}</label>
                  </div>
                  <div className="chat-channel-member-badges-list">
                    {userInfoListToRender &&
                      userInfoListToRender.map((userInfo) => (
                        <div className="chat-member-badge" key={userInfo.uuid}>
                          <div>{userInfo.fullName}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className="chat-channel-member-search">
              <div>
                <label>{t("add")}</label>
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={t("search_crew")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="chat-channel-editor-body">
          <div className="chat-channel-users">
            <div className="chat-channel-user-buffer"></div>
            {filteredCommsUsers &&
              filteredCommsUsers.map((userInfo) => (
                <div className="chat-channel-user" key={userInfo.uuid}>
                  <div
                    className={`chat-channel-user-badge${
                      channelToEdit?.members.includes(userInfo.uuid)
                        ? " selected"
                        : ""
                    }`}
                  >
                    <div
                      onClick={() => {
                        handleToggleChatMember(userInfo.uuid);
                      }}
                      data-testid={`chat-channel-user-${makeTestFriendlyString(
                        userInfo.fullName
                      )}`}
                    >
                      <CrewInfo
                        user={userInfo?.user}
                        details={userInfo?.user.userGroup}
                        mapStatus={userInfo?.mapStatus}
                        userPicSize={UserPicSize.LARGE}
                        userPicTheme={UserPicTheme.DARK}
                      />
                    </div>
                    <div>
                      {channelEditorType === CHAT_CATEGORY_TYPES.GROUP && (
                        <Checkbox
                          isChecked={channelToEdit?.members.includes(
                            userInfo.uuid
                          )}
                          changeHandler={(value) => {
                            handleToggleChatMember(value);
                          }}
                          value={userInfo.uuid}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            <div className="chat-channel-user-buffer"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChatChannelEditor;
