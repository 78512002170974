import { createContext, useContext } from "react";

export const CommsContext = createContext(null);
export const CommsDispatchContext = createContext(null);

// Convenience methods that can be used in components
export function useCommsContext() {
  return useContext(CommsContext);
}

export function useCommsDispatchContext() {
  return useContext(CommsDispatchContext);
}
