import { ReactComponent as ClearSunny } from "../../assets/weather/ClearSunny.svg";
import { ReactComponent as MostlyClear } from "../../assets/weather/MostlyClear.svg";
import { ReactComponent as PartlyCloudy } from "../../assets/weather/PartlyCloudy.svg";
import { ReactComponent as MostlyCloudy } from "../../assets/weather/MostlyCloudy.svg";
import { ReactComponent as Cloudy } from "../../assets/weather/Cloudy.svg";
import { ReactComponent as Fog } from "../../assets/weather/Fog.svg";
import { ReactComponent as LightFog } from "../../assets/weather/LightFog.svg";
import { ReactComponent as Drizzle } from "../../assets/weather/Drizzle.svg";
import { ReactComponent as Rain } from "../../assets/weather/Rain.svg";
import { ReactComponent as LightRain } from "../../assets/weather/LightRain.svg";
import { ReactComponent as HeavyRain } from "../../assets/weather/HeavyRain.svg";
import { ReactComponent as Snow } from "../../assets/weather/Snow.svg";
import { ReactComponent as Flurries } from "../../assets/weather/Flurries.svg";
import { ReactComponent as LightSnow } from "../../assets/weather/LightSnow.svg";
import { ReactComponent as HeavySnow } from "../../assets/weather/HeavySnow.svg";
import { ReactComponent as FreezingDrizzle } from "../../assets/weather/FreezingDrizzle.svg";
import { ReactComponent as FreezingRain } from "../../assets/weather/FreezingRain.svg";
import { ReactComponent as LightFreezingRain } from "../../assets/weather/LightFreezingRain.svg";
import { ReactComponent as HeavyFreezingRain } from "../../assets/weather/HeavyFreezingRain.svg";
import { ReactComponent as IcePellets } from "../../assets/weather/IcePellets.svg";
import { ReactComponent as HeavyIcePellets } from "../../assets/weather/HeavyIcePellets.svg";
import { ReactComponent as LightIcePellets } from "../../assets/weather/LightIcePellets.svg";
import { ReactComponent as Thunderstorm } from "../../assets/weather/Thunderstorm.svg";

// Please sync with https://github.com/Moonware-Inc/halo/blob/develop/src/web-api/enums/weather_condition.go

function WeatherIcon(props) {
  const { weatherConditionId } = props;
  let weatherIcon = null;
  switch (weatherConditionId) {
    case 1:
      weatherIcon = <ClearSunny />;
      break;
    case 2:
      weatherIcon = <MostlyClear />;
      break;
    case 3:
      weatherIcon = <PartlyCloudy />;
      break;
    case 4:
      weatherIcon = <MostlyCloudy />;
      break;
    case 5:
      weatherIcon = <Cloudy />;
      break;
    case 6:
      weatherIcon = <Fog />;
      break;
    case 7:
      weatherIcon = <LightFog />;
      break;
    case 8:
      weatherIcon = <Drizzle />;
      break;
    case 9:
      weatherIcon = <Rain />;
      break;
    case 10:
      weatherIcon = <LightRain />;
      break;
    case 11:
      weatherIcon = <HeavyRain />;
      break;
    case 12:
      weatherIcon = <Snow />;
      break;
    case 13:
      weatherIcon = <Flurries />;
      break;
    case 14:
      weatherIcon = <LightSnow />;
      break;
    case 15:
      weatherIcon = <HeavySnow />;
      break;
    case 16:
      weatherIcon = <FreezingDrizzle />;
      break;
    case 17:
      weatherIcon = <FreezingRain />;
      break;
    case 18:
      weatherIcon = <LightFreezingRain />;
      break;
    case 19:
      weatherIcon = <HeavyFreezingRain />;
      break;
    case 20:
      weatherIcon = <IcePellets />;
      break;
    case 21:
      weatherIcon = <HeavyIcePellets />;
      break;
    case 22:
      weatherIcon = <LightIcePellets />;
      break;
    case 23:
      weatherIcon = <Thunderstorm />;
      break;
    default:
      weatherIcon = <ClearSunny />;
      break;
  }

  return <>{weatherIcon}</>;
}

export default WeatherIcon;
