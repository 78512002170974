import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { POLLING_INTERVALS } from "../../constants";
import { getBackgroundAlerts } from "../../api";
import { useAlertContext, useAlertDispatchContext } from "../../AlertContext";
import { isNullOrUndefined } from "../../utils";
import { hasUnreadAlerts } from "../../alertUtils";
function AlertIndicator() {
  const alertDispatch = useAlertDispatchContext();
  const alertContext = useAlertContext();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { alerts, currentUser } = mainContext;
  const { backgroundAlerts, backgroundAlertsLoading } = alertContext;
  const [backgroundAlertsRequested, setBackgroundAlertsRequested] =
    useState(false);
  const [showAlertIndicator, setShowAlertIndicator] = useState(false);

  useEffect(() => {
    if (backgroundAlertsRequested) {
      if (!backgroundAlertsLoading) {
        getBackgroundAlerts(alertDispatch);
      }
    }
    setBackgroundAlertsRequested(false);
  }, [alertDispatch, backgroundAlertsRequested, backgroundAlertsLoading]);

  useEffect(() => {
    if (!isNullOrUndefined(currentUser)) {
      // If the currentUser changes (switch deployments) get immediately
      getBackgroundAlerts(alertDispatch);
    }
  }, [alertDispatch, currentUser]);

  useEffect(() => {
    const hasAlertsToRead = hasUnreadAlerts(backgroundAlerts);
    let shouldUpdateMainAlerts = false;
    if (!hasAlertsToRead && showAlertIndicator) {
      // if indicator is ON and no more alerts to read, update the main list
      shouldUpdateMainAlerts = true;
      setShowAlertIndicator(false);
    } else if (hasAlertsToRead) {
      // if indicator is OFF and has alerts to read, update the main list
      shouldUpdateMainAlerts = true;
      setShowAlertIndicator(true);
    } else if (isNullOrUndefined(alerts) || alerts.length === 0) {
      shouldUpdateMainAlerts = true;
    }
    if (shouldUpdateMainAlerts) {
      // Update the main alert list
      dispatch({
        type: "setAlerts",
        value: backgroundAlerts,
      });
    }
  }, [dispatch, backgroundAlerts, alerts, showAlertIndicator]);

  useEffect(() => {
    if (POLLING_INTERVALS.ALERTS < 0) return () => {};
    const interval = setInterval(() => {
      setBackgroundAlertsRequested(true);
    }, POLLING_INTERVALS.ALERTS);
    return () => clearInterval(interval);
  }, []);

  return <>{showAlertIndicator && <div className="status-indicator"></div>}</>;
}
export default AlertIndicator;
