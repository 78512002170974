import { registerTheme } from "echarts";
import ReactECharts from "echarts-for-react";
import MoonwareTheme from "../../assets/themes/moonwareTheme.json";
import { CHART_COLORS, METRICS_SORT_FIELDS } from "../../constants";
import { formatHoursMins } from "../../utils";
import { useTranslation } from "react-i18next";
function TurnaroundOperationTile(props) {
  const { t } = useTranslation();
  const { operation, highlightMetric, wideLayout, includeMissingData } = props;

  registerTheme("Moonware", MoonwareTheme.theme);
  const completedOnTimePercent = Math.round(operation.completedOnTimePercent);
  const completedLatePercent = Math.round(operation.completedLatePercent);
  const missingPercent = Math.round(operation.missingPercent);
  const partiallyCompletedPercent = Math.round(
    operation.partiallyCompletedPercent
  );

  const data = [];
  if (completedOnTimePercent > 0) {
    data.push({
      value: completedOnTimePercent,
      name: METRICS_SORT_FIELDS.COMPLETED_ONTIME,
      itemStyle: { color: CHART_COLORS[2] },
    });
  }
  if (completedLatePercent > 0) {
    data.push({
      value: completedLatePercent,
      name: METRICS_SORT_FIELDS.COMPLETED_LATE,
      itemStyle: { color: CHART_COLORS[1] },
    });
  }
  if (partiallyCompletedPercent > 0) {
    data.push({
      value: partiallyCompletedPercent,
      name: METRICS_SORT_FIELDS.COMPLETED_PARTIALLY,
      itemStyle: { color: CHART_COLORS[3] },
    });
  }
  if (includeMissingData && missingPercent) {
    data.push({
      value: missingPercent,
      name: METRICS_SORT_FIELDS.MISSING,
      itemStyle: { color: CHART_COLORS[0] },
    });
  }

  let borderRadius = 10;
  let padAngle = -5;
  // Special logic to handle overlap and borderRadius
  // Charts don't render nicely under certain conditions like small values
  if (data.length < 2) {
    borderRadius = 0;
    padAngle = 0;
  } else if (data.some((item) => item.value < 3)) {
    borderRadius = 2;
    padAngle = -2;
  }

  const option = {
    tooltip: {
      borderColor: "#424959",
      backgroundColor: "#161721",
      trigger: "item",
      textStyle: {
        color: "#ffffff",
      },
      position: "top",
      valueFormatter: (value) => `${value}%`,
    },
    series: [
      {
        name: operation.name,
        type: "pie",
        radius: ["80%", "90%"],
        padAngle: padAngle,
        minAngle: 0,
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: borderRadius,
        },
        emptyCircleStyle: {
          color: "#4a4c56",
        },

        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
          scaleSize: 2,
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };
  let highlightMetricValue = 0;
  if (highlightMetric === METRICS_SORT_FIELDS.COMPLETED_LATE) {
    highlightMetricValue = operation.completedLatePercent;
  } else if (highlightMetric === METRICS_SORT_FIELDS.MISSING) {
    highlightMetricValue = operation.missingPercent;
  } else if (highlightMetric === METRICS_SORT_FIELDS.AVG_DELAY) {
    highlightMetricValue = operation.averageDelay;
  } else if (highlightMetric === METRICS_SORT_FIELDS.COMPLETED_PARTIALLY) {
    highlightMetricValue = operation.partiallyCompletedPercent;
  } else if (highlightMetric === METRICS_SORT_FIELDS.COMPLETED_ONTIME) {
    highlightMetricValue = operation.completedOnTimePercent;
  }

  return (
    <div className="chart-tile">
      <div>
        <div className="chart-tile-header">
          <div>
            <h2>{operation.name}</h2>
          </div>
        </div>
        <div className={`chart-tile-container${wideLayout ? " wide" : ""}`}>
          <div className="chart-tile-body">
            <div className="center-info">
              <div>
                <span className="metric-value">{`${Math.round(
                  highlightMetricValue
                )}%`}</span>
                <br />
                {highlightMetric === METRICS_SORT_FIELDS.COMPLETED_ONTIME && (
                  <>
                    {t("completed_non_cap")}
                    <br />
                    {t("on_time").toLowerCase()}
                  </>
                )}
                {highlightMetric === METRICS_SORT_FIELDS.COMPLETED_LATE && (
                  <>
                    {t("completed_non_cap")}&nbsp;{t("late").toLowerCase()}
                  </>
                )}
                {highlightMetric ===
                  METRICS_SORT_FIELDS.COMPLETED_PARTIALLY && (
                  <>
                    {t("completed_non_cap")}&nbsp;{t("partially").toLowerCase()}
                  </>
                )}
                {highlightMetric === METRICS_SORT_FIELDS.MISSING && (
                  <>{t("missing")}</>
                )}
              </div>
              {operation.totalOperations > 0 && (
                <div className="center-detail">
                  <span>
                    {operation.totalOperations === 1
                      ? t("total_operations_singular_web", {
                          value: operation.totalOperations,
                        })
                      : t("total_operations_web", {
                          value: operation.totalOperations,
                        })}
                  </span>
                </div>
              )}
            </div>
            <div className="pie-chart">
              <ReactECharts
                option={option}
                style={{
                  height: wideLayout ? "180px" : "200px",
                  width: "100%",
                }}
                theme="Moonware"
              />
            </div>
          </div>
          <div className="chart-tile-footer">
            <div className="chart-tile-legend">
              <div title={t(METRICS_SORT_FIELDS.COMPLETED_ONTIME)}>
                <div>
                  <div className="colored-dot series-1"></div>
                </div>
                <div className="ellipsis-text">
                  {t(METRICS_SORT_FIELDS.COMPLETED_ONTIME)}
                </div>
                <div>{`${completedOnTimePercent}%`}</div>
              </div>
              <div title={t(METRICS_SORT_FIELDS.COMPLETED_LATE)}>
                <div>
                  <div className="colored-dot series-2"></div>
                </div>
                <div className="ellipsis-text">
                  {t(METRICS_SORT_FIELDS.COMPLETED_LATE)}
                </div>
                <div>{`${completedLatePercent}%`}</div>
              </div>
              <div title={t(METRICS_SORT_FIELDS.COMPLETED_PARTIALLY)}>
                <div>
                  <div className="colored-dot series-4"></div>
                </div>
                <div className="ellipsis-text">
                  {t(METRICS_SORT_FIELDS.COMPLETED_PARTIALLY)}
                </div>
                <div>{`${partiallyCompletedPercent}%`}</div>
              </div>
              {includeMissingData && (
                <div title={t(METRICS_SORT_FIELDS.MISSING)}>
                  <div>
                    <div className="colored-dot series-3"></div>
                  </div>
                  <div className="ellipsis-text">
                    {t(METRICS_SORT_FIELDS.MISSING)}
                  </div>
                  <div>{`${missingPercent}%`}</div>
                </div>
              )}
            </div>
            <div className="chart-tile-legend-footnote">
              <div>
                {t("avg_delay")}:&nbsp;
                <span className="danger">{`${formatHoursMins(
                  Math.round(operation.averageDelay)
                )}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TurnaroundOperationTile;
