import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import {
  deepCopy,
  filterBySearchQuery,
  getByFieldValue,
  isBlank,
  isNullOrUndefined,
} from "../../utils";
import { tooltipSolidStyles } from "../../styleUtils";
import { Tooltip, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";

function GateSelector(props) {
  const { t } = useTranslation();
  const { onSelect, gates, value, allowClear, tooltip } = props;
  const componentRef = useRef(null);
  const inputRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  function onChangeHandler(newValue) {
    onSelect(newValue);
    setIsActive(false);
    setSearchQuery("");
  }

  const gatesList = useMemo(() => {
    return deepCopy(gates).map((item) => {
      return {
        ...item,
        searchableName:
          item?.name.split(" - ").length > 1
            ? item?.name.split(" - ")[1]
            : item.name,
      };
    });
  }, [gates]);

  const gatesFiltered = !isNullOrUndefined(gates)
    ? filterBySearchQuery(searchQuery, gatesList, "searchableName")
    : [];

  useEffect(() => {
    const handleClick = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [componentRef]);

  useEffect(() => {
    if (isActive) {
      if (!isNullOrUndefined(inputRef?.current)) {
        inputRef.current.focus();
      }
    }
  }, [isActive]);

  const currentStand = getByFieldValue(gates, "uuid", value);

  const buttonControl = (
    <div
      className="gate-selector-value"
      onClick={(e) => {
        setIsActive((prev) => !prev);
        setSearchQuery("");
        e.preventDefault();
      }}
    >
      <div>{!isNullOrUndefined(currentStand) && <>{currentStand?.name}</>}</div>
      <div>
        <Caret />
      </div>
    </div>
  );
  const buttonControlToRender = !isBlank(tooltip) ? (
    <Tooltip
      title={t("reposition_stand_tooltip")}
      placement="top"
      componentsProps={tooltipSolidStyles}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      arrow
    >
      {buttonControl}
    </Tooltip>
  ) : (
    buttonControl
  );

  return (
    <div
      className={`gate-selector${isActive ? " active" : ""}`}
      ref={componentRef}
    >
      {buttonControlToRender}
      {isActive && (
        <div className="gate-selector-menu drop-shadow">
          <div className="gate-selector-menu-container">
            <div className="gate-selector-menu-content">
              <div>
                <div
                  className={`gate-selector-menu-search${
                    gatesFiltered?.length > 0 ? " opened" : ""
                  }`}
                >
                  <input
                    type="text"
                    placeholder={t("start_typing")}
                    ref={inputRef}
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
                {gatesFiltered?.length > 0 && (
                  <div className="gate-selector-menu-results">
                    {gatesFiltered.map((item) => (
                      <div
                        key={item.uuid}
                        className="gate-selector-menu-result"
                        onClick={() => {
                          onChangeHandler(item);
                        }}
                      >
                        <div>{item.name}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="gate-selector-menu-actions">
              <div>
                <div></div>
                <div>
                  {allowClear && (
                    <button
                      className="secondary"
                      onClick={() => {
                        onChangeHandler(null);
                      }}
                    >
                      {t("clear")}
                    </button>
                  )}
                </div>
                <div>
                  <button
                    className="secondary"
                    onClick={() => {
                      setIsActive(false);
                    }}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GateSelector;
