import { useTranslation } from "react-i18next";
import { METRICS_SORT_FIELDS } from "../../constants";

function OperationSortBar(props) {
  const { t } = useTranslation();
  const { selectedSort, onChange, includeMissingData } = props;

  const sortFields = Object.values(METRICS_SORT_FIELDS);
  if (!includeMissingData) {
    sortFields.splice(sortFields.indexOf(METRICS_SORT_FIELDS.MISSING), 1);
  }
  return (
    <div className="chart-sort-bar">
      <div className="chart-sort-label">
        <div>{t("order_by")}:</div>
      </div>
      {sortFields &&
        sortFields.map((sortField) => (
          <div
            className={`chart-sort-item${
              selectedSort === sortField ? " selected" : ""
            }`}
            onClick={() => {
              onChange(sortField);
            }}
            key={sortField}
          >
            <div>{t(sortField)}</div>
          </div>
        ))}
    </div>
  );
}

export default OperationSortBar;
