import { registerTheme } from "echarts";
import ReactECharts from "echarts-for-react";
import MoonwareTheme from "../../assets/themes/moonwareTheme.json";
import { deepCopy, isNullOrUndefined } from "../../utils";
import { sortByDataColumn } from "../DataTable/utils";
import LeaderboardColumns, {
  VALUE_TYPES,
} from "../DataTable/LeaderboardColumns";
import moment from "moment";
import { useTranslation } from "react-i18next";

const PIXELS_PER_BAR = 24;
const CHART_BUFFER = 80;

function LeaderboardChart(props) {
  const { t } = useTranslation();
  const { sortBy, dataList } = props;
  registerTheme("Moonware", MoonwareTheme.theme);

  const dataListSorted = !isNullOrUndefined(dataList) ? deepCopy(dataList) : [];

  if (!isNullOrUndefined(sortBy) && !isNullOrUndefined(dataListSorted)) {
    sortByDataColumn(dataListSorted, sortBy.sortColumnName.id, sortBy.sortDesc);
    dataListSorted.reverse();
    // if (dataListSorted.length > 25) {
    //   dataListSorted.splice(25);
    // }
  }

  const chartCategories = [];
  const chartData = [];
  const today = moment().startOf("day");
  for (let i = 0; i < dataListSorted.length; i++) {
    const listItem = dataListSorted[i];
    if (sortBy.sortColumnName.valueType === VALUE_TYPES.DATE) {
      const dateVal = listItem[sortBy.sortColumnName.id];
      if (isNullOrUndefined(dateVal)) {
        chartData.push("N/A");
      } else {
        const itemDate = moment(dateVal).startOf("day");
        const daysDiff = moment.duration(today.diff(itemDate)).as("days");
        chartData.push(daysDiff);
      }
    } else {
      chartData.push(sortBy.sortColumnName.sortValue(listItem));
    }
    chartCategories.push(
      LeaderboardColumns.LeaderboardUser.sortValue(listItem)
    );
  }

  const option = {
    tooltip: {
      borderColor: "#424959",
      backgroundColor: "#161721",
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
      textStyle: {
        color: "#ffffff",
      },
    },
    xAxis: {
      type: "value",
      position: "top",
      axisLabel: {
        inside: false,
        rotate: 0,
        color: "#ffffff",
        formatter: (value) => {
          if (sortBy.sortColumnName.valueType === VALUE_TYPES.DATE) {
            return `${Math.round(value)}d`;
          } else if (sortBy.sortColumnName.valueType === VALUE_TYPES.NUMBER) {
            return `${Math.round(value)}`;
          } else if (sortBy.sortColumnName.valueType === VALUE_TYPES.PERCENT) {
            return `${Math.round(value)}%`;
          }
          return value;
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      z: 10,
    },
    yAxis: {
      type: "category",
      data: chartCategories,
      boundaryGap: ["0%", "10%"],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: 0,
        margin: 8,
        inside: true,
        color: "#fff",
        formatter: (value) => {
          //   if (sortBy.sortColumnName.valueType === VALUE_TYPES.DATE) {
          //     return `${Math.round(value)}d`;
          //   } else if (sortBy.sortColumnName.valueType === VALUE_TYPES.NUMBER) {
          //     return `${Math.round(value)}`;
          //   } else if (sortBy.sortColumnName.valueType === VALUE_TYPES.PERCENT) {
          //     return `${Math.round(value)}%`;
          //   }
          return value;
        },
      },
      z: 10,
    },
    series: [
      {
        data: chartData,
        type: "bar",
        showBackground: false,
        barWidth: "16px",
        barGap: "0",
        tooltip: {
          valueFormatter: function (value) {
            if (sortBy.sortColumnName.valueType === VALUE_TYPES.DATE) {
              return `${Math.round(value)} ${
                Math.round(value) === 1 ? "day" : "days"
              } ago`;
            } else if (sortBy.sortColumnName.valueType === VALUE_TYPES.NUMBER) {
              return `${Math.round(value)}`;
            } else if (
              sortBy.sortColumnName.valueType === VALUE_TYPES.PERCENT
            ) {
              return `${Math.round(value)}%`;
            }
            return value;
          },
        },
        itemStyle: {
          borderRadius: 5,
          opacity: "0.75",
        },
        emphasis: {
          itemStyle: {
            opacity: "1",
          },
        },
      },
    ],
  };
  return (
    <div className="leaderboard-chart">
      <div className="module">
        <div>
          <div className="leaderboard-chart-header">
            {t(sortBy.sortColumnName.displayName)}
          </div>
          <div className="leaderboard-chart-body">
            {dataList.length > 0 && (
              <ReactECharts
                option={option}
                style={{
                  height: `${
                    CHART_BUFFER + dataList.length * PIXELS_PER_BAR
                  }px`,
                  width: "100%",
                }}
                theme="Moonware"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaderboardChart;
