import "./styles.css";
import Checkbox from "../Checkbox";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import { ReactComponent as Turnarounds } from "../../assets/turnarounds.svg";
import { deepCopy, isNullOrUndefined } from "../../utils";
import { useEffect, useRef, useState } from "react";

function MetricsFilter(props) {
  const {
    filterName,
    filterItems,
    selectedFilters,
    changeHandler,
    filterItemIdField,
    filterItemNameField,
    iconName,
    menuPosition,
  } = props;
  const [isActive, setIsActive] = useState(false);

  function handleSelectTurnaroundPair(value) {
    const updatedPairs = deepCopy(selectedFilters);
    const idx = updatedPairs.indexOf(value);
    if (idx > -1) {
      updatedPairs.splice(idx, 1);
    } else if (value === ALL_METRICS_FILTER_VALUE) {
      // If all, remove everything else
      updatedPairs.splice(0);
    } else {
      updatedPairs.push(value);
    }
    changeHandler(updatedPairs);
  }
  const componentRef = useRef(null);
  useEffect(() => {
    const handleClick = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [componentRef]);

  const isEnabled =
    !isNullOrUndefined(selectedFilters) &&
    selectedFilters.length > 0 &&
    !selectedFilters.includes(ALL_METRICS_FILTER_VALUE);

  const selectedFiltersCount = !selectedFilters.includes(
    ALL_METRICS_FILTER_VALUE
  )
    ? selectedFilters.length
    : 0;

  const labelText =
    selectedFiltersCount > 0
      ? `${filterName} (${selectedFiltersCount})`
      : `All ${filterName.toLowerCase()}`;

  const filterIcon = "turnarounds" === iconName ? <Turnarounds /> : null;

  return (
    <div
      className={`metrics-filter${isActive ? " active" : ""}${
        isEnabled ? " enabled" : ""
      }${menuPosition ? ` ${menuPosition}` : "left"}`}
      ref={componentRef}
    >
      <div
        className="metrics-filter-toggle"
        onClick={() => {
          setIsActive((prev) => !prev);
        }}
      >
        <div className={`inner-label${filterIcon ? " icon" : ""}`}>
          {filterIcon && <div>{filterIcon}</div>}
          <div>{labelText}</div>
          {!filterIcon && (
            <div>
              <Caret />
            </div>
          )}
        </div>
      </div>
      {isActive && (
        <div className="metrics-filter-menu fadeIn drop-shadow">
          <div className="metrics-filter-container">
            <div className="metrics-filter-content">
              <div className="metrics-filter-option">
                <Checkbox
                  value={ALL_METRICS_FILTER_VALUE}
                  changeHandler={() => {
                    handleSelectTurnaroundPair(ALL_METRICS_FILTER_VALUE);
                  }}
                  isChecked={selectedFilters.length === 0}
                />
                <div>
                  <label
                    onClick={() => {
                      handleSelectTurnaroundPair(ALL_METRICS_FILTER_VALUE);
                    }}
                  >
                    {`All ${filterName.toLowerCase()}`}
                  </label>
                </div>
              </div>
              {filterItems &&
                filterItems.map((item) => {
                  const idValue = item[filterItemIdField];
                  const nameValue = item[filterItemNameField];
                  const isSelected = selectedFilters.includes(idValue);
                  return (
                    <div
                      className={`metrics-filter-option${
                        isSelected ? " selected" : ""
                      }`}
                      key={idValue}
                    >
                      <Checkbox
                        value={idValue}
                        changeHandler={(value) => {
                          handleSelectTurnaroundPair(value);
                        }}
                        isChecked={isSelected}
                      />
                      <div>
                        <label
                          onClick={() => {
                            handleSelectTurnaroundPair(idValue);
                          }}
                        >
                          {nameValue}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export const ALL_METRICS_FILTER_VALUE = "all";

export default MetricsFilter;
