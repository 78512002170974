import { ReactComponent as Turnaround } from "../../assets/turnarounds/turnaround.svg";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { ReactComponent as CircleInbound } from "../../assets/turnarounds/inbound_circle.svg";
import { ReactComponent as CircleOutbound } from "../../assets/turnarounds/outbound_circle.svg";
import {
  filterList,
  formatDatetime,
  formatHoursMins,
  formatTime,
  getRelativeTime,
  getRelativeTimeString,
  isBlank,
  isNullOrUndefined,
} from "../../utils";
import { useState } from "react";
import TabControl from "../TabControl";
import {
  TurnaroundOperationStatus,
  TurnaroundPhaseType,
  getCurrentTurnaroundPhase,
} from "../../turnaroundUtils";
import TurnaroundProgressBar from "./TurnaroundProgressBar";
import moment from "moment-timezone";
import {
  ANALYTICS_EVENTS,
  DEFAULT_TIMEZONE,
  EMPTY_STAND,
} from "../../constants";
import {
  ResourceActions,
  userHasResourceAction,
} from "../../resourceActionsUtils";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useNavigate } from "react-router-dom";
import { getProfileValidation } from "../../turnaroundProfileUtils";
import { useTranslation } from "react-i18next";
import {
  getFlightInfo,
  getFlightInfoForTurnaroundSummary,
} from "../../flightUtils";
import FlightProgress from "../FlightProgress";
import StatusBadge from "../StatusBadge";

function TurnaroundInfoPanel(props) {
  const {
    onClose,
    panelOpen,
    selectedTurnaroundInfo,
    selectedOperation,
    detailsClickHandler,
    selectedDate,
    showGateChangeAction,
    onGateChangeClick,
    showAlertsPanelAction,
    onShowAlertsClick,
    onShowOnMapClick,
  } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const navigate = useNavigate();
  const { currentUser } = mainContext;
  const defaultTab = getCurrentTurnaroundPhase(selectedTurnaroundInfo);
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const turnaroundAirline = !isNullOrUndefined(selectedTurnaroundInfo)
    ? selectedTurnaroundInfo.inboundFlightAirlineUuid ||
      selectedTurnaroundInfo.outboundFlightAirlineUuid
    : null;
  const airport = currentUser?.airport;

  const showDispatchActions =
    selectedTurnaroundInfo?.isReadyForDispatch &&
    userHasResourceAction(
      currentUser,
      ResourceActions.EditTurnProfile,
      turnaroundAirline
    );

  // selectedTurnaroundInfo.operations?.map((i) => {
  //   const { startTime, endTime } = getOperationStartEndTimes(i);
  //   return { startTime, endTime };
  // });

  function handleEditDispatch() {
    // Edit the dispatch on the Dipatches page
    dispatch({
      type: "setTurnaroundDispatchToEdit",
      value: selectedTurnaroundInfo.uuid,
    });
    dispatch({
      type: "setAnalyticsEvent",
      value: {
        eventName:
          ANALYTICS_EVENTS.EDIT_ALLOCATION_TURNAROUND_PANEL_BUTTON_CLICK,
      },
    });
    navigate("/allocations");
  }

  return (
    <>
      <div className="context-panel-content-header">
        <div></div>
        <div className="close-btn" onClick={onClose}>
          <CloseBtn />
        </div>
      </div>
      <div className="turnaround-navigation">
        <TabControl
          clickHandler={setSelectedTab}
          tabNames={[t("inbound"), t("turnaround"), t("outbound")]}
          selectedTabIdx={selectedTab}
        />
      </div>
      <div className="context-panel-container">
        <div className={`turnaround-content ${panelOpen ? " open" : ""}`}>
          {selectedTab === TurnaroundPhaseType.INBOUND &&
            getContentForInbound(
              t,
              selectedTurnaroundInfo,
              selectedDate,
              airport
            )}
          {selectedTab === TurnaroundPhaseType.TURNAROUND &&
            getContentForTurnaround(
              t,
              selectedTurnaroundInfo,
              selectedOperation,
              detailsClickHandler,
              selectedDate,
              showGateChangeAction,
              onGateChangeClick,
              showAlertsPanelAction,
              onShowAlertsClick,
              showDispatchActions,
              handleEditDispatch,
              onShowOnMapClick
            )}
          {selectedTab === TurnaroundPhaseType.OUTBOUND &&
            getContentForOutbound(
              t,
              selectedTurnaroundInfo,
              selectedDate,
              airport
            )}
        </div>
      </div>
    </>
  );
}

function getStatusRank(statusId) {
  if (statusId === TurnaroundOperationStatus.MISSING) {
    return 1;
  } else if (statusId === TurnaroundOperationStatus.IN_PROGRESS_LATE) {
    return 10;
  } else if (statusId === TurnaroundOperationStatus.COMPLETED_LATE) {
    return 11;
  } else if (statusId === TurnaroundOperationStatus.IN_PROGRESS) {
    return 12;
  } else if (statusId === TurnaroundOperationStatus.COMPLETED_PARTIALLY) {
    return 13;
  } else if (statusId === TurnaroundOperationStatus.COMPLETED) {
    return 14;
  }
  return 100;
}

/** Functions for obtaining content per tab **/
function getContentForTurnaround(
  t,
  selectedTurnaroundInfo,
  selectedOperation,
  detailsClickHandler,
  selectedDate,
  showGateChangeAction = false,
  onGateChangeClick,
  showAlertsPanelAction = false,
  onShowAlertsClick,
  showDispatchActions,
  handleEditDispatch,
  onShowOnMapClick
) {
  const operationsList = filterList(
    selectedTurnaroundInfo.operations,
    (item) => {
      return item.enabled;
    }
  );

  operationsList.sort((a, b) => {
    const aStatus = getStatusRank(a.status.statusId);
    const bStatus = getStatusRank(b.status.statusId);
    if (aStatus === bStatus) {
      const val1 = a.name;
      const val2 = b.name;
      if (val1 === val2) return 0;
      return val1 < val2 ? -1 : 1;
    }
    return aStatus < bStatus ? -1 : 1;
  });

  const airportTimezone =
    selectedTurnaroundInfo?.airportTimezone || DEFAULT_TIMEZONE;
  const mNow = moment().tz(airportTimezone);
  const hasAction = showGateChangeAction || showAlertsPanelAction;
  let dispatchMessage = null;
  let isDispatched = false; // past deadline
  if (showDispatchActions) {
    const mApprovalDeadline = !isNullOrUndefined(
      selectedTurnaroundInfo?.approvalDeadline
    )
      ? moment(selectedTurnaroundInfo.approvalDeadline).tz(airportTimezone)
      : null;
    isDispatched = mNow.isAfter(mApprovalDeadline);
    const automaticDispatchTime = `${getRelativeTimeString(
      mApprovalDeadline,
      mNow
    )}`;
    dispatchMessage = isDispatched
      ? `Dispatched at ${formatTime(mApprovalDeadline, airportTimezone, true)}`
      : `Automatic dispatch ${automaticDispatchTime}`;
    selectedTurnaroundInfo.profileValidation =
      selectedTurnaroundInfo.isReadyForDispatch
        ? getProfileValidation(selectedTurnaroundInfo.profile)
        : null;
  }

  const gateIn = getActualOrScheduledTime(
    t,
    selectedTurnaroundInfo.inboundActualGateIn,
    selectedTurnaroundInfo.inboundScheduledGateIn,
    selectedTurnaroundInfo.airportTimezone,
    selectedDate
  );
  const gateOut = getActualOrScheduledTime(
    t,
    selectedTurnaroundInfo.outboundActualGateOut,
    selectedTurnaroundInfo.outboundScheduledGateOut,
    selectedTurnaroundInfo.airportTimezone,
    selectedDate
  );

  // Determine the number of expected vs assigned Crew from the original profile
  // As well as expected vs actual Crew based on service records
  const expectedQuantityByOperationId = {};
  if (!isNullOrUndefined(selectedTurnaroundInfo?.originalProfile)) {
    const operations =
      selectedTurnaroundInfo.originalProfile.turnaroundOperations;
    for (let i = 0; i < operations.length; i++) {
      const operation = operations[i];
      if (operation.enabled) {
        const requirements = operation.turnaroundRequirements;
        let totalCrewQuantity = 0;
        let totalCrewAssignments = 0;

        for (let j = 0; j < requirements.length; j++) {
          const requirement = requirements[j];
          totalCrewQuantity = totalCrewQuantity + requirement.quantity;
          const crewAssignments = !isNullOrUndefined(
            requirement.crewAssignments
          )
            ? requirement.crewAssignments
            : [];
          totalCrewAssignments = totalCrewAssignments + crewAssignments.length;
        }
        expectedQuantityByOperationId[operation.uuid] = {
          operationName: operation.name,
          totalCrewQuantity: totalCrewQuantity,
          totalCrewAssignments: totalCrewAssignments,
          missingCrewAssignments:
            totalCrewAssignments < totalCrewQuantity
              ? totalCrewQuantity - totalCrewAssignments
              : 0,
        };
      }
    }
  }
  // Determine the number of expected vs actual GSE from the monitor (has service records)
  if (!isNullOrUndefined(selectedTurnaroundInfo?.originalMonitor)) {
    const operations =
      selectedTurnaroundInfo.originalMonitor.turnaroundOperations;
    for (let i = 0; i < operations.length; i++) {
      const operation = operations[i];
      if (operation.enabled) {
        const requirements = !isNullOrUndefined(
          operation.turnaroundRequirements
        )
          ? operation.turnaroundRequirements
          : [];
        const gseRecords = !isNullOrUndefined(operation.gseRecords)
          ? operation.gseRecords
          : [];

        let totalRequirementQuantity = 0;
        let totalGseQuantity = 0;
        let isCrewOnlyRequirement = true; // Crew only requirements do not have GSEs
        for (let j = 0; j < requirements.length; j++) {
          const requirement = requirements[j];
          if (
            !isNullOrUndefined(
              requirement?.certification?.groundVehicleTypeUuid
            )
          ) {
            totalGseQuantity = totalGseQuantity + requirement.quantity;
            isCrewOnlyRequirement = false;
          }
          totalRequirementQuantity =
            totalRequirementQuantity + requirement.quantity;
        }
        let expectedQuantityForOperation =
          expectedQuantityByOperationId[operation.uuid];
        if (isNullOrUndefined(expectedQuantityForOperation)) {
          expectedQuantityByOperationId[operation.uuid] = {};
          expectedQuantityForOperation =
            expectedQuantityByOperationId[operation.uuid];
        }
        const actualGseQuantity = gseRecords.length;
        expectedQuantityForOperation.totalGseQuantity = totalGseQuantity;
        expectedQuantityForOperation.actualGseQuantity = actualGseQuantity;
        expectedQuantityForOperation.missingGses =
          actualGseQuantity < totalGseQuantity
            ? totalGseQuantity - actualGseQuantity
            : 0;

        const totalUserRecords = !isNullOrUndefined(operation.userRecords)
          ? operation.userRecords.length
          : 0;
        const missingCrew =
          isCrewOnlyRequirement && totalUserRecords < totalRequirementQuantity
            ? totalRequirementQuantity - totalUserRecords
            : 0;
        expectedQuantityForOperation.missingCrew = missingCrew;
      }
    }
  }

  return (
    <>
      <div className="turnaround-general">
        <div className="turn-thirds">
          <div className="stat">
            <label>{t("inbound")}</label>
            <span>{selectedTurnaroundInfo.inboundFlightName || "-"}</span>
          </div>
          <div>
            <Turnaround />
          </div>
          <div className="stat">
            <label>{t("outbound")}</label>
            <span>{selectedTurnaroundInfo.outboundFlightName || "-"}</span>
          </div>
        </div>

        <div className="stat double">
          <label>{t("current_stand")}</label>
          <span>
            {!isNullOrUndefined(selectedTurnaroundInfo.snappedStand)
              ? selectedTurnaroundInfo.snappedStand.name
              : EMPTY_STAND}
          </span>
        </div>
        <div className="stat ">
          <label>{t("gate_in_non_cap")}</label>
          <span>{gateIn}</span>
        </div>
        <div className="stat">
          <label>{t("gate_out_non_cap")}</label>
          <span>{gateOut}</span>
        </div>
      </div>

      <TurnaroundProgressBar
        turnaroundInfo={selectedTurnaroundInfo}
        turnaroundLevelOperationStatus={true}
      />

      {operationsList.length > 0 && (
        <div className="turnaround-operations">
          {operationsList.length > 0 &&
            operationsList.map((item) => {
              let crewCount = 0;
              if (!isNullOrUndefined(item.turnaroundRequirements)) {
                for (let i = 0; i < item.turnaroundRequirements.length; i++) {
                  const turnRequirement = item.turnaroundRequirements[i];
                  if (turnRequirement.crewAssignments?.length > 0) {
                    crewCount =
                      crewCount + turnRequirement.crewAssignments.length;
                  }
                }
              }
              const expectedStartTime = item.expectedStartTime;
              let etaValue = t("na").toLowerCase();
              if (!isNullOrUndefined(expectedStartTime)) {
                const hasStarted =
                  item?.status.statusId ===
                    TurnaroundOperationStatus.IN_PROGRESS ||
                  item?.status.statusId ===
                    TurnaroundOperationStatus.IN_PROGRESS_LATE ||
                  item?.status.statusId ===
                    TurnaroundOperationStatus.COMPLETED ||
                  item?.status.statusId ===
                    TurnaroundOperationStatus.COMPLETED_LATE ||
                  item?.status.statusId ===
                    TurnaroundOperationStatus.COMPLETED_PARTIALLY;
                const timeToMins = !hasStarted
                  ? getRelativeTime(
                      moment(expectedStartTime).tz(airportTimezone),
                      moment().tz(airportTimezone)
                    )
                  : 0;
                etaValue = timeToMins > 0 ? formatHoursMins(timeToMins) : 0;
              }
              const infoText = {
                uuid: item.uuid,
                status: item.status,
                expectedStartTime: item.expectedStartTime
                  ? formatDatetime(item.expectedStartTime, airportTimezone)
                  : t("na").toUpperCase(),
              };
              const isInProgress =
                item?.status.statusId ===
                  TurnaroundOperationStatus.IN_PROGRESS ||
                item?.status.statusId ===
                  TurnaroundOperationStatus.IN_PROGRESS_LATE;

              const missingGSEs =
                expectedQuantityByOperationId[item.uuid].missingGses;
              const hasMissingGSEs = missingGSEs > 0;
              const missingGSEsMessage =
                missingGSEs === 1
                  ? t("missing_gse_web", { value: missingGSEs })
                  : t("missing_gses_web", { value: missingGSEs });
              const hasGSERecords = item?.gseRecords?.length > 0;

              const missingCrewAssignments =
                expectedQuantityByOperationId[item.uuid].missingCrewAssignments;
              const hasMissingCrewAssignments = missingCrewAssignments > 0;

              const missingCrewAssignmentsMessage =
                missingCrewAssignments === 1
                  ? t("unassigned_crew_web", {
                      value: missingCrewAssignments,
                    })
                  : t("unassigned_crew_web", {
                      value: missingCrewAssignments,
                    });

              const missingCrew =
                expectedQuantityByOperationId[item.uuid].missingCrew;
              const hasMissingCrew = missingCrew > 0;
              const missingCrewMessage =
                missingCrewAssignments === 1
                  ? t("missing_crew_web", {
                      value: missingCrew,
                    })
                  : t("missing_crew_web", {
                      value: missingCrew,
                    });

              const isPastExpectedStartTime = mNow.isAfter(expectedStartTime);
              const showMissingGses =
                !hasGSERecords &&
                (isInProgress || isPastExpectedStartTime) &&
                hasMissingGSEs;
              const showMissingCrewAssignments =
                (isInProgress || isPastExpectedStartTime) &&
                hasMissingCrewAssignments;
              const showMissingCrew =
                (isInProgress || isPastExpectedStartTime) && hasMissingCrew;

              return (
                <div
                  className={`stat clickable${
                    selectedOperation?.uuid === item.uuid ? " selected" : ""
                  }`}
                  onClick={() => {
                    if (selectedOperation?.uuid === item.uuid) {
                      detailsClickHandler(null);
                    } else {
                      detailsClickHandler(item);
                    }
                  }}
                  key={item.uuid}
                >
                  <div
                    className="stat-content"
                    data-info={JSON.stringify(infoText)}
                  >
                    <div className="status-info">
                      <label>
                        {item.name}
                        {isInProgress && (
                          <>
                            <div className="status-info-pulse fadeInLoop"></div>
                          </>
                        )}
                      </label>
                      <span>{t("eta_web", { value: etaValue })}</span>
                      <span className="allcap">
                        {t("assigned_crew_web", {
                          value: crewCount === 0 ? "-" : crewCount,
                        })}
                      </span>
                    </div>
                    <div className="status">
                      {showMissingGses && (
                        <div>
                          <StatusBadge
                            isCritical={true}
                            message={missingGSEsMessage}
                          />
                        </div>
                      )}
                      {showMissingCrew && (
                        <div>
                          <StatusBadge
                            isCritical={true}
                            message={missingCrewMessage}
                          />
                        </div>
                      )}
                      {showMissingCrewAssignments && (
                        <div>
                          <StatusBadge
                            isCritical={true}
                            message={missingCrewAssignmentsMessage}
                          />
                        </div>
                      )}
                      {item?.status.statusId ===
                        TurnaroundOperationStatus.COMPLETED && (
                        <div>
                          <StatusBadge
                            isNominal={true}
                            message={t("completed_non_cap")}
                          />
                        </div>
                      )}
                      {item?.status.statusId ===
                        TurnaroundOperationStatus.COMPLETED_LATE && (
                        <div>
                          <StatusBadge
                            isWarning={true}
                            message={t("completed_late")}
                          />
                        </div>
                      )}
                      {item?.status.statusId ===
                        TurnaroundOperationStatus.COMPLETED_PARTIALLY && (
                        <div>
                          <StatusBadge
                            isNominal={true}
                            message={t("completed_partially")}
                          />
                        </div>
                      )}
                      {item?.status.statusId ===
                        TurnaroundOperationStatus.IN_PROGRESS_LATE && (
                        <div>
                          <StatusBadge
                            isWarning={true}
                            message={t("delayed")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {showDispatchActions && (
        <div className="turnaround-action">
          <div>
            <span>{dispatchMessage}</span>
          </div>
          <button
            className="alternate"
            onClick={() => {
              handleEditDispatch();
            }}
          >
            {t("edit_dispatch")}
          </button>
        </div>
      )}
      <hr />
      <div className="turnaround-general">
        <div className="turn-locations">
          <div className="stat">
            <label>{t("assigned_arr_stand_abbr")}</label>
            <span>
              {!isBlank(selectedTurnaroundInfo?.arrivalStand?.name)
                ? selectedTurnaroundInfo.arrivalStand.name
                : EMPTY_STAND}
            </span>
          </div>
          <div className="stat">
            <label>{t("assigned_hard_stand")}</label>
            <span>
              {!isBlank(selectedTurnaroundInfo?.hardStand?.name)
                ? selectedTurnaroundInfo.hardStand.name
                : EMPTY_STAND}
            </span>
          </div>
          <div className="stat">
            <label>{t("assigned_dep_stand_abbr")}</label>
            <span>
              {!isBlank(selectedTurnaroundInfo?.departureStand?.name)
                ? selectedTurnaroundInfo.departureStand.name
                : EMPTY_STAND}
            </span>
          </div>
        </div>

        <div className="stat ">
          <label>{t("registration")}</label>
          <span>{selectedTurnaroundInfo.registration}</span>
        </div>
        <div className="stat">
          <label>{t("aircraft_type_non_cap")}</label>
          <span>{selectedTurnaroundInfo.aircraftType}</span>
        </div>
      </div>

      {hasAction && (
        <>
          {operationsList.length > 0 && <hr />}
          <div className="turnaround-action">
            {showAlertsPanelAction && (
              <button className="alternate" onClick={onShowOnMapClick}>
                {t("show_in_map")}
              </button>
            )}
            {showAlertsPanelAction && (
              <button className="alternate" onClick={onShowAlertsClick}>
                {t("view_timeline")}
              </button>
            )}
            {showGateChangeAction && (
              <button className="alternate" onClick={onGateChangeClick}>
                {t("move_aircraft")}
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
}

function getContentForInbound(
  t,
  selectedTurnaroundInfo,
  selectedDate,
  airport
) {
  const gateIn = getActualOrScheduledTime(
    t,
    selectedTurnaroundInfo.inboundActualGateIn,
    selectedTurnaroundInfo.inboundScheduledGateIn,
    airport.timezone,
    selectedDate
  );
  const landing = getActualOrScheduledTime(
    t,
    selectedTurnaroundInfo.inboundActualLanding,
    selectedTurnaroundInfo.inboundScheduledLanding,
    selectedTurnaroundInfo.airportTimezone,
    selectedDate
  );

  const sourceType = selectedTurnaroundInfo.sourceType;
  const inboundFlightInfo =
    sourceType === "summary"
      ? getFlightInfoForTurnaroundSummary(
          selectedTurnaroundInfo.inboundFlight,
          airport.iata,
          null,
          airport.timezone
        )
      : getFlightInfo(
          selectedTurnaroundInfo.inboundFlight,
          airport.iata,
          null,
          airport.timezone
        );
  return (
    <>
      <div className="turnaround-flight-header">
        <div>
          <div>
            <CircleInbound />
          </div>
          <div className="title">
            <label>{t("inbound_flight")}</label>
            <span>{selectedTurnaroundInfo.inboundFlightName}</span>
          </div>
        </div>
      </div>
      <FlightProgress flightInfo={inboundFlightInfo} />
      <div className="turnaround-general">
        <div className="stat">
          <label>{t("registration")}</label>
          <span>{selectedTurnaroundInfo.registration}</span>
        </div>
        <div className="stat">
          <label>{t("aircraft_type_non_cap")}</label>
          <span>{selectedTurnaroundInfo.inboundAircraftType}</span>
        </div>
        <div className="stat double">
          <label>{t("assigned_arr_stand")}</label>
          <span>
            {selectedTurnaroundInfo?.arrivalStand?.name || EMPTY_STAND}
          </span>
        </div>

        <div className="stat">
          <label>{t("gate_in_non_cap")}</label>
          <span title={selectedTurnaroundInfo.airportTimezone}>{gateIn}</span>
        </div>
        <div className="stat">
          <label>{t("landing")}</label>
          <span title={selectedTurnaroundInfo.airportTimezone}>{landing}</span>
        </div>
      </div>
    </>
  );
}

function getContentForOutbound(
  t,
  selectedTurnaroundInfo,
  selectedDate,
  airport
) {
  const gateOut = getActualOrScheduledTime(
    t,
    selectedTurnaroundInfo.outboundActualGateOut,
    selectedTurnaroundInfo.outboundScheduledGateOut,
    selectedTurnaroundInfo.airportTimezone,
    selectedDate
  );
  const takeoff = getActualOrScheduledTime(
    t,
    selectedTurnaroundInfo.outboundActualTakeoff,
    selectedTurnaroundInfo.outboundScheduledTakeoff,
    selectedTurnaroundInfo.airportTimezone,
    selectedDate
  );

  const sourceType = selectedTurnaroundInfo.sourceType;
  const outboundFlightInfo =
    sourceType === "summary"
      ? getFlightInfoForTurnaroundSummary(
          selectedTurnaroundInfo.outboundFlight,
          airport.iata,
          null,
          airport.timezone
        )
      : getFlightInfo(
          selectedTurnaroundInfo.outboundFlight,
          airport.iata,
          null,
          airport.timezone
        );

  return (
    <>
      <div className="turnaround-flight-header">
        <div>
          <div>
            <CircleOutbound />
          </div>
          <div className="title">
            <label>{t("outbound_flight")}</label>
            <span>{selectedTurnaroundInfo.outboundFlightName || "-"}</span>
          </div>
        </div>
      </div>
      {outboundFlightInfo && <FlightProgress flightInfo={outboundFlightInfo} />}
      <div className="turnaround-general">
        <div className="stat">
          <label>{t("registration")}</label>
          <span>{selectedTurnaroundInfo.registration}</span>
        </div>
        <div className="stat">
          <label>{t("aircraft_type_non_cap")}</label>
          <span>{selectedTurnaroundInfo.inboundAircraftType}</span>
        </div>
        <div className="stat double">
          <label>{t("assigned_dep_stand")}</label>
          <span>
            {selectedTurnaroundInfo?.departureStand?.name || EMPTY_STAND}
          </span>
        </div>
        <div className="stat">
          <label>{t("gate_out_non_cap")}</label>
          <span title={selectedTurnaroundInfo.airportTimezone}>{gateOut}</span>
        </div>
        <div className="stat">
          <label>{t("takeoff")}</label>
          <span title={selectedTurnaroundInfo.airportTimezone}>{takeoff}</span>
        </div>
      </div>
    </>
  );
}

function getActualOrScheduledTime(
  t,
  actual,
  scheduled,
  timezone,
  selectedDate
) {
  let value = "-";
  if (!isBlank(actual)) {
    value = `${t("actual_abbr").toLowerCase()} ${formatTime(
      actual,
      timezone,
      true,
      selectedDate
    )}`;
  } else if (!isBlank(scheduled)) {
    value = `${t("scheduled_abbr").toLowerCase()} ${formatTime(
      scheduled,
      timezone,
      true,
      selectedDate
    )}`;
  }
  return value;
}

// TODO: This is probably deprecated because we will show snappedStand
// function getStandNumber(stand, previousStand) {
//   if (!isBlank(stand)) {
//     return (
//       <>
//         {!isBlank(previousStand) && previousStand !== stand && (
//           <>
//             <span className="strikethrough">{previousStand}</span>
//             &nbsp;
//           </>
//         )}
//         {stand}
//       </>
//     );
//   } else {
//     return <>-</>;
//   }
// }

export default TurnaroundInfoPanel;
