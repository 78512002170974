import "./styles.css";
import { ReactComponent as AlertRed } from "../../assets/alert-small-red.svg";
import { ReactComponent as AlertYellow } from "../../assets/alert-small-yellow.svg";
function StatusBadge(props) {
  const {
    isAllCap,
    isNominal,
    isOutlined,
    isFilled,
    isCritical,
    isWarning,
    message,
  } = props;

  const hasIcon = isCritical || isWarning;
  return (
    <div
      className={`status-badge${hasIcon ? " icons" : ""}${
        isFilled ? " filled" : ""
      }${isNominal ? " nominal" : ""}${isOutlined ? " outlined" : ""}${
        isCritical ? " critical" : ""
      }${isWarning ? " warning" : ""}${isAllCap ? " allcap" : ""}`}
    >
      <div>
        {hasIcon && (
          <div className="status-badge-icon">
            {isCritical && <AlertRed />}
            {isWarning && <AlertYellow />}
          </div>
        )}
        <div className="status-badge-message">{message}</div>
      </div>
    </div>
  );
}

export default StatusBadge;
