import { useReducer } from "react";
import { AlertContext, AlertDispatchContext } from "./AlertContext";

export const initialState = {
  backgroundAlerts: [],
  backgroundAlertsLoading: false,
};

export function alertReducer(state, action) {
  switch (action.type) {
    case "setBackgroundAlerts":
      return {
        ...state,
        backgroundAlerts: action.value,
      };
    case "setBackgroundAlertsLoading":
      return {
        ...state,
        backgroundAlertsLoading: action.value,
      };
    default:
      // No-op actions
      return {
        ...state,
      };
  }
}

export default function AlertProvider({ children }) {
  const [main, dispatch] = useReducer(alertReducer, initialState);
  return (
    <AlertContext.Provider value={main}>
      <AlertDispatchContext.Provider value={dispatch}>
        {children}
      </AlertDispatchContext.Provider>
    </AlertContext.Provider>
  );
}
