import { useTranslation } from "react-i18next";
import { selectInputFix } from "../../styleUtils";
import { OperationOffsetType } from "../../turnaroundUtils";
import { deepCopy, isBlank, isNullOrUndefined, sortByField } from "../../utils";
import StartTimeOffset from "../StartTimeOffset";

function TemplateOperation(props) {
  const { t } = useTranslation();
  const { selectedOperation, isReadOnly, onChange } = props;
  const operationToEdit = !isNullOrUndefined(selectedOperation)
    ? deepCopy(selectedOperation)
    : null;

  function handleOperationFieldChange(fieldName, value, preserveNull) {
    const operationToUpdate = deepCopy(operationToEdit);
    operationToUpdate[fieldName] =
      preserveNull && isBlank(value) ? null : value;
    onChange(operationToUpdate);
  }

  function handleResourceQuantityChange(uuid, quantity) {
    const operationToUpdate = deepCopy(operationToEdit);
    for (var i = 0; i < operationToUpdate.turnaroundRequirements.length; i++) {
      const turnaroundRequirement = operationToUpdate.turnaroundRequirements[i];
      if (turnaroundRequirement.uuid === uuid) {
        turnaroundRequirement.quantity = quantity;
      }
    }
    onChange(operationToUpdate);
  }

  const typeValue = !isNullOrUndefined(operationToEdit?.offsetTypeId)
    ? operationToEdit?.offsetTypeId.toString()
    : "";
  const durationValue = !isNullOrUndefined(operationToEdit?.duration)
    ? operationToEdit?.duration?.toString()
    : "";

  const vehiclesList = [];
  const crewList = [];
  let totalVehicles = 0;
  let totalCrew = 0;
  for (let i = 0; i < operationToEdit?.turnaroundRequirements.length; i++) {
    const turnaroundRequirement = operationToEdit?.turnaroundRequirements[i];
    if (!isBlank(turnaroundRequirement.certification.groundVehicleTypeUuid)) {
      vehiclesList.push(turnaroundRequirement);
      totalVehicles = totalVehicles + turnaroundRequirement.quantity;
    } else {
      crewList.push(turnaroundRequirement);
      totalCrew = totalCrew + turnaroundRequirement.quantity;
    }
  }
  sortByField(vehiclesList, "certification.name");
  sortByField(crewList, "certification.name");

  return (
    <>
      <div className="main-fields module">
        <div>
          <div>
            <div>
              <h3>{operationToEdit?.name}</h3>
            </div>
          </div>
          <div className="field-row">
            <div className="field-values">
              <label>{t("type")}</label>
              <div>
                <select
                  value={typeValue}
                  style={selectInputFix}
                  disabled={isReadOnly}
                  onChange={(e) => {
                    handleOperationFieldChange(
                      "offsetTypeId",
                      parseInt(e.target.value)
                    );
                  }}
                >
                  <option value={OperationOffsetType.INBOUND}>
                    {t("inbound")}
                  </option>
                  <option value={OperationOffsetType.OUTBOUND}>
                    {t("outbound")}
                  </option>
                </select>
              </div>
            </div>
            <div className="field-values">
              <label>{t("start_time_offset")}</label>
              <div>
                <StartTimeOffset
                  operationToEdit={operationToEdit}
                  onChange={(value) => {
                    handleOperationFieldChange("startOffsetMinutes", value);
                  }}
                  disabled={isReadOnly}
                />
              </div>
            </div>
            <div className="field-values">
              <label>{t("duration")}</label>
              <div className="operation-duration">
                <select
                  value={durationValue}
                  style={selectInputFix}
                  onChange={(e) => {
                    handleOperationFieldChange(
                      "duration",
                      isBlank(e.target.value) ? "" : parseInt(e.target.value),
                      true
                    );
                  }}
                  disabled={isReadOnly}
                >
                  <option value="">{t("no_duration")}</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="35">35</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                  <option value="50">50</option>
                  <option value="55">55</option>
                  <option value="60">60</option>
                </select>
                <label>{t("minutes").toLowerCase()}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="resource-fields">
        <div className="module">
          <div>
            <div className="resource-fields-header">
              <div>
                <h3>{t("vehicles")}</h3>
              </div>
              <div>
                {`${t("total")}: ${totalVehicles} ${
                  totalVehicles === 1 ? t("gse") : t("vehicles")
                }`}
              </div>
            </div>
            <div className="resource-fields-rows">
              {vehiclesList.length === 0 && (
                <div className="empty-state de-emphasize">
                  {t("no_gse_requirements")}
                </div>
              )}
              {vehiclesList.length > 0 &&
                vehiclesList.map((item) => (
                  <div className="resource-fields-row" key={item.uuid}>
                    <div>
                      <select
                        value={item.quantity.toString()}
                        style={selectInputFix}
                        onChange={(e) => {
                          handleResourceQuantityChange(
                            item.uuid,
                            parseInt(e.target.value)
                          );
                        }}
                        disabled={isReadOnly}
                      >
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </select>
                    </div>
                    <div>{item.certification.name}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="module">
          <div>
            <div className="resource-fields-header">
              <div>
                <h3>{t("crew_members")}</h3>
              </div>
              <div>
                {`${t("total")}: ${totalCrew} ${
                  totalCrew === 1
                    ? t("crew_member").toLowerCase()
                    : t("crew_members").toLowerCase()
                }`}
              </div>
            </div>
            <div className="resource-fields-rows">
              {crewList.length === 0 && (
                <div className="empty-state de-emphasize">
                  {t("no_crew_requirements")}
                </div>
              )}
              {crewList.length > 0 &&
                crewList.map((item) => (
                  <div className="resource-fields-row" key={item.uuid}>
                    <div>
                      <select
                        value={item.quantity.toString()}
                        style={selectInputFix}
                        onChange={(e) => {
                          handleResourceQuantityChange(
                            item.uuid,
                            parseInt(e.target.value)
                          );
                        }}
                        disabled={isReadOnly}
                      >
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                      </select>
                    </div>
                    <div>{item.certification.name}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplateOperation;
