import { ReactComponent as Completed } from "../../assets/operations/status-completed.svg";
import { ReactComponent as CompletedLate } from "../../assets/operations/status-completed-late.svg";
import { ReactComponent as CompletedPartially } from "../../assets/operations/status-completed-partially.svg";
import { ReactComponent as InProgressLate } from "../../assets/operations/status-in-progress-late.svg";
import { ReactComponent as Missing } from "../../assets/operations/status-missing.svg";
import { TurnaroundOperationStatus } from "../../turnaroundUtils";

function OperationStatusIcon(props) {
  const { status } = props;
  const altText = status?.displayName;

  return (
    <>
      {status?.statusId === TurnaroundOperationStatus.PLANNED && (
        <div>{/* Show nothing */}</div>
      )}
      {status?.statusId === TurnaroundOperationStatus.IN_PROGRESS && (
        <div>{/* Show nothing */}</div>
      )}
      {status?.statusId === TurnaroundOperationStatus.IN_PROGRESS_LATE && (
        <InProgressLate alt={altText} />
      )}
      {status?.statusId === TurnaroundOperationStatus.COMPLETED_LATE && (
        <CompletedLate alt={altText} />
      )}
      {status?.statusId === TurnaroundOperationStatus.COMPLETED && (
        <Completed alt={altText} />
      )}
      {status?.statusId === TurnaroundOperationStatus.COMPLETED_PARTIALLY && (
        <CompletedPartially alt={altText} />
      )}
      {status?.statusId === TurnaroundOperationStatus.MISSING && <Missing />}
    </>
  );
}

export default OperationStatusIcon;
