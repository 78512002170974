import { useMainContext, useMainDispatchContext } from "../../MainContext";
import ChannelInfo from "./ChannelInfo";

function ChatChannel(props) {
  const { channelInfo } = props;
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { selectedChannelId } = mainContext;
  const isSelected = selectedChannelId === channelInfo.id;

  return (
    <div
      className={`chat-side-panel-item channel${isSelected ? " selected" : ""}`}
      onClick={() => {
        dispatch({
          type: "setSelectedChannelId",
          value: channelInfo.id,
        });
      }}
    >
      <div className="chat-channel">
        <div className="chat-channel-name">
          <ChannelInfo channelInfo={channelInfo} isSelected={isSelected} />
        </div>
      </div>
      <div>
        {channelInfo.unreadCount > 0 && (
          <div className="count-badge">
            <div>{channelInfo.unreadCountDisplay}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatChannel;
