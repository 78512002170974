import { registerTheme } from "echarts";
import ReactECharts from "echarts-for-react";
import MoonwareTheme from "../../assets/themes/moonwareTheme.json";
import moment from "moment-timezone";
import { isNullOrUndefined } from "../../utils";
import { useTranslation } from "react-i18next";
const HOUR_FORMAT = "HH:mm";

function TurnaroundChartWidget(props) {
  const { airportTimezone, flights } = props;
  const { t } = useTranslation();
  registerTheme("Moonware", MoonwareTheme.theme);

  if (isNullOrUndefined(flights) || flights.length === 0) return null;
  // only need flights within a 24-hour window
  // 6 hours back and 18 hours ahead of now
  const now = moment().tz(airportTimezone);
  const mStart = moment(now);
  mStart.add(-6, "hour").startOf("hour");

  const mEnd = moment(now);
  mEnd.add(18, "hour").startOf("hour");

  const mBuckets = moment(now);
  mBuckets.add(-6, "hour").startOf("hour");
  const hourBuckets = [];
  for (let i = 0; i < 24; i++) {
    hourBuckets[i] = {
      hour: mBuckets.format(HOUR_FORMAT),
      count: 0,
    };
    mBuckets.add(1, "hour");
  }

  let maxValue = 0;
  if (!isNullOrUndefined(flights) && flights.length > 0) {
    for (let i = 0; i < flights.length; i++) {
      const { filedDepartureTime, filedArrivalTime } = flights[i];
      if (!isNullOrUndefined(filedDepartureTime)) {
        const bucketName = getBucketForFlight(filedDepartureTime, mStart, mEnd);
        incrementBucketCount(hourBuckets, bucketName);
      }
      if (!isNullOrUndefined(filedArrivalTime)) {
        const bucketName = getBucketForFlight(filedArrivalTime, mStart, mEnd);
        incrementBucketCount(hourBuckets, bucketName);
      }
    }
  }
  const chartDateValues = hourBuckets.map((hourBucket) => hourBucket.hour);
  const chartSeries1 = hourBuckets.map((hourBucket) => hourBucket.count);
  for (let i = 0; i < chartSeries1.length; i++) {
    const value = chartSeries1[i];
    if (value > maxValue) {
      maxValue = value;
    }
  }

  const yAxisInterval = Math.ceil(maxValue / 6);

  const option = {
    animation: false,
    axisPointer: {
      shadowStyle: {
        color: "rgba(23, 121, 218, 0.25)",
      },
      label: {
        backgroundColor: "rgba(23, 121, 218, 0.5)",
      },
    },
    tooltip: {
      borderColor: "#424959",
      backgroundColor: "#161721",
      trigger: "axis",
      textStyle: {
        color: "#ffffff",
      },
    },
    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: false, type: ["line", "bar"] },
        restore: { show: false },
        saveAsImage: { show: false },
      },
      right: "64px",
    },
    xAxis: [
      {
        type: "category",
        data: chartDateValues,
        axisPointer: {
          type: "shadow",
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#232A37",
          },
        },
        axisLabel: {
          color: "#B0B7C3",
          fontWeight: 600,
          fontSize: "14px",
        },
        boundaryGap: true,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "",
        min: 0,
        max: maxValue,
        interval: yAxisInterval,
        show: true,
        position: "right",
        axisLabel: {
          show: true,
          interval: 1,
          color: "#B0B7C3",
          fontWeight: 600,
          fontSize: "14px",
          formatter: (value) => `${value}`,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: t("turnarounds"),
        type: "line",
        smooth: 0.25,
        lineStyle: {
          color: "#0062FF",
          width: 3,
        },
        symbolSize: 8,
        showSymbol: false,
        tooltip: {
          valueFormatter: function (value) {
            return value;
          },
        },
        data: chartSeries1,
        itemStyle: {
          borderRadius: 5,
          opacity: "0.75",
        },
        emphasis: {
          itemStyle: {
            opacity: "1.0",
          },
        },
        markLine: {
          data: [{ name: "Now", xAxis: now.startOf("hour").format("HH:mm") }],
          symbol: "none",
          label: {
            show: false,
          },
          lineStyle: {
            color: "#ffffff",
            type: "solid",
            width: 1,
          },
          emphasis: {
            disabled: true,
          },
        },
      },
    ],
  };
  return (
    <div className="chart-container">
      {chartDateValues.length > 0 && (
        <ReactECharts option={option} theme="Moonware" />
      )}
    </div>
  );
}

// Increments the bucket by bucketName if present
function incrementBucketCount(hourBuckets, bucketName) {
  if (isNullOrUndefined(bucketName)) return;
  const bucket = hourBuckets.find((b) => b.hour === bucketName);
  if (!isNullOrUndefined(bucket)) {
    bucket.count = bucket.count + 1;
  }
}

// Returns the bucket that it should belong or null if not in the range
function getBucketForFlight(flightTime, windowStart, windowEnd) {
  const dFlight = !isNullOrUndefined(flightTime) ? new Date(flightTime) : null;
  if (!isNullOrUndefined(dFlight)) {
    const mFlight = moment(dFlight);
    const isInWindow = mFlight.isBetween(windowStart, windowEnd, null, "[]");
    if (isInWindow) {
      // round to nearest hour bucket
      mFlight.minute() >= 30
        ? mFlight.add(1, "hour").startOf("hour")
        : mFlight.startOf("hour");
      return mFlight.format("HH:mm");
    }
  }
  return null;
}

export default TurnaroundChartWidget;
