import ReleaseNotesBG from "../../assets/ornament_large.png";
import { ReactComponent as ReleaseNote } from "../../assets/release-note.svg";
import { ReactComponent as CloseBtn } from "../../assets/circle_cross.svg";
import { useTranslation } from "react-i18next";

function ReleaseNotes(props) {
  const { releaseNotes, onClose } = props;
  const { t } = useTranslation();

  return (
    <div
      className="widget release-notes"
      style={{
        backgroundImage: `url(${ReleaseNotesBG})`,
      }}
    >
      <div>
        <div className="release-notes-content">
          <div>
            <div className="release-notes-title">
              <div>
                <ReleaseNote />
              </div>
              <div>
                <div>
                  <h3>{t("release_note")}</h3>
                </div>
              </div>
              <div>
                <div className="badge">{releaseNotes.title}</div>
              </div>
            </div>
          </div>
          {releaseNotes?.notes?.length > 0 && (
            <div>
              <ul>
                {releaseNotes &&
                  releaseNotes.notes.map((note, idx) => (
                    <li key={idx}>{note}</li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div>
        <CloseBtn className="button-icon" onClick={onClose} />
      </div>
    </div>
  );
}

export default ReleaseNotes;
