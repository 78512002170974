import "./styles.css";
import { useEffect } from "react";
import PageToolbar from "../PageToolbar";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import DataTable, { ColumnNames } from "../DataTable";
import { saveAlertsLastRead } from "../../api";
import AlertsEmpty from "./AlertsEmpty";

function Alerts() {
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { alerts } = mainContext;

  useEffect(() => {
    // Update the read timestamp after the page loaded for first time and 5 sec has passed
    const st = setTimeout(() => {
      saveAlertsLastRead(dispatch);
    }, 5000);

    // clean up
    return () => {
      // When exiting the page also mark everything as read
      clearTimeout(st);
      saveAlertsLastRead(dispatch);
    };
  }, [dispatch]);

  const actionButtons = [];
  return (
    <div className="alerts show-new">
      <div className="alerts-container">
        <PageToolbar title="Alerts" actionButtons={actionButtons} />

        <DataTable
          dataList={alerts}
          columnNames={[
            ColumnNames.AlertTitle,
            ColumnNames.AlertMessage,
            ColumnNames.Datetime,
            ColumnNames.Status,
          ]}
          initialSort={ColumnNames.Datetime.id}
          initialSortDesc={true}
          emptyState={<AlertsEmpty />}
        />
      </div>
    </div>
  );
}
export default Alerts;
