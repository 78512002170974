import { useNavigate } from "react-router-dom";
import { useMainDispatchContext } from "../../MainContext";
import { ReactComponent as CriticalIcon } from "../../assets/critical.svg";
function CriticalBanner(props) {
  const { notification } = props;
  const dispatch = useMainDispatchContext();
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <CriticalIcon />
      </div>
      <div className="action-banner-message">
        <div>{notification.message}</div>
        <div>
          <span>{notification.messageDetail}</span>
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            dispatch({
              type: "setAnalyticsEvent",
              value: {
                eventName: notification.analyticsEvent,
              },
            });
            navigate(notification.actionLocation);
          }}
        >
          {notification.actionLabel}
        </button>
      </div>
    </div>
  );
}

export default CriticalBanner;
