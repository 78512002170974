import "./styles.css";
import { ReactComponent as Battery } from "../../assets/battery.svg";
import { useTranslation } from "react-i18next";

function BatteryPower(props) {
  const { battery = 0, hasPower } = props;
  const { t } = useTranslation();
  const batteryAmt = Math.floor(battery);

  const fillWidth = Math.round((batteryAmt / 100) * 18);
  const style = {
    width: `${fillWidth}px`,
  };
  if (fillWidth > 16) {
    style.borderRadius = "2px";
  }
  return (
    <div className="battery-power">
      <div className="battery-power-box">
        <div
          className={`battery-power-icon${
            batteryAmt < 20 && !hasPower ? " danger" : ""
          }`}
        >
          <Battery />
          <div className="battery-power-fill" style={style}></div>
        </div>
        <div>
          <span>
            {hasPower ? t("power_abbr").toUpperCase() : `${batteryAmt}%`}
          </span>
        </div>
      </div>
    </div>
  );
}
export default BatteryPower;
