import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { formatTime, isBlank } from "../../utils";
import { EMPTY_STAND, EMPTY_TIME } from "../../constants";
import { useTranslation } from "react-i18next";

function DispatchInfo(props) {
  const { turnaroundInfo } = props;
  const { t } = useTranslation();

  const inboundFlightName = !isBlank(turnaroundInfo.inboundFlightName)
    ? turnaroundInfo.inboundFlightName
    : "-";
  const outboundFlightName = !isBlank(turnaroundInfo.outboundFlightName)
    ? turnaroundInfo.outboundFlightName
    : "-";
  const gateIn = !isBlank(turnaroundInfo.gateIn)
    ? formatTime(turnaroundInfo.gateIn, turnaroundInfo.airportTimezone, true)
    : EMPTY_TIME;
  const gateOut = !isBlank(turnaroundInfo.gateOut)
    ? formatTime(turnaroundInfo.gateOut, turnaroundInfo.airportTimezone, true)
    : EMPTY_TIME;
  const stand = !isBlank(turnaroundInfo.stand)
    ? turnaroundInfo.stand
    : EMPTY_STAND;

  const originAirport = !isBlank(turnaroundInfo.originAirport)
    ? turnaroundInfo.originAirport
    : "-";
  const destinationAirport = !isBlank(turnaroundInfo.destinationAirport)
    ? turnaroundInfo.destinationAirport
    : "-";
  return (
    <div className="dispatch-info">
      <div className="dispatch-info-row">
        <div>
          <Arrival />
        </div>
        <div className="label-value-pair">
          <label>{t("inbound")}</label>
          <div>{inboundFlightName}</div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <label>{t("gate_in_non_cap")}</label>
            <div>{gateIn}</div>
          </div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <label>{t("stand_non_cap")}</label>
            <div>{stand}</div>
          </div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <label>{t("origin_non_cap")}</label>
            <div>{originAirport}</div>
          </div>
        </div>
      </div>
      <div className="dispatch-info-row">
        <div>
          <Departure />
        </div>
        <div className="label-value-pair">
          <label>{t("outbound")}</label>
          <div>{outboundFlightName}</div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <label>{t("gate_out_non_cap")}</label>
            <div>{gateOut}</div>
          </div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <label>{t("stand_non_cap")}</label>
            <div>{stand}</div>
          </div>
        </div>
        <div className="outline">
          <div className="label-value-pair ">
            <label>{t("destination_non_cap")}</label>
            <div>{destinationAirport}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DispatchInfo;
