import "./styles.css";
import { FLIGHT_INFO_STATUS } from "../../flightUtils";
import { isBlank, isNullOrUndefined } from "../../utils";
import { ReactComponent as Plane } from "../../assets/circle-plane-progress.svg";
import { useEffect, useRef } from "react";

function FlightProgress(props) {
  const { flightInfo } = props;

  const barRef = useRef(null);
  const planeRef = useRef(null);
  useEffect(() => {
    if (
      !isNullOrUndefined(barRef.current) &&
      !isNullOrUndefined(planeRef.current) &&
      !isNullOrUndefined(flightInfo)
    ) {
      const percent = flightInfo.flightProgress || 0;
      const totalWidth =
        barRef.current.parentNode.getBoundingClientRect().width;
      const planeOffset = totalWidth * (percent / 100);
      const iconWidth = 17;
      const iconMid = iconWidth / 2;

      if (planeOffset < iconMid) {
        planeRef.current.style.left = "0px";
      } else if (planeOffset + iconMid > totalWidth) {
        planeRef.current.style.left = `${totalWidth - iconWidth}px`;
      } else {
        planeRef.current.style.left = `${planeOffset - iconMid}px`;
      }
      planeRef.current.style.top = "-6px";

      if (flightInfo.flightInfoStatus === FLIGHT_INFO_STATUS.NOT_DEPARTED) {
        barRef.current.style.width = "0%";
      } else if (flightInfo.flightInfoStatus === FLIGHT_INFO_STATUS.IN_FLIGHT) {
        barRef.current.style.width = `${percent}%`;
      } else if (flightInfo.flightInfoStatus === FLIGHT_INFO_STATUS.LANDED) {
        barRef.current.style.width = "100%";
      }
    }
  }, [barRef, planeRef, flightInfo]);

  const flightDisplayStatus = !isBlank(flightInfo.primaryFlightDisplayStatus)
    ? flightInfo.primaryFlightDisplayStatus
    : flightInfo.displayStatus;

  return (
    <div className="flight-progress">
      <div className="flight-progress-info">
        <div className="flight-airport">
          <div className="flight-airport-badge">
            <div>{flightInfo.originAirport}</div>
          </div>
        </div>
        <div>
          <span>{flightDisplayStatus}</span>
        </div>
        <div className="flight-airport">
          <div className="flight-airport-badge">
            <div>{flightInfo.destinationAirport}</div>
          </div>
        </div>
      </div>

      <div className="flight-progress-status-bar-container">
        <div className="flight-progress-status-bar">
          <div className="flight-progress-status-bar-fill" ref={barRef}></div>
          <div className="plane-icon" ref={planeRef}>
            <Plane />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightProgress;
