import { useState } from "react";
import { deepCopy, isNullOrUndefined } from "../../utils";
import SelectCrewMember from "./SelectCrewMember";
import ToggleSwitch from "../ToggleSwitch";
import CrewInfo from "../CrewInfo";
import { selectInputFix } from "../../styleUtils";
import { DEFAULT_START_TIME_OFFSET } from "../../constants";
import { OperationOffsetType } from "../../turnaroundUtils";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../userUtils";

function DispatchOperation(props) {
  const { t } = useTranslation();
  const {
    operation,
    onOperationChange,
    monitor,
    users,
    positions,
    turnarounds,
  } = props;
  const operationToEdit = deepCopy(operation);
  const [showSelectCrewMember, setShowSelectCrewMember] = useState("");

  let offsetTypeName = null;
  if (OperationOffsetType.INBOUND === operationToEdit.offsetTypeId) {
    offsetTypeName = "Inbound";
  } else {
    offsetTypeName = "Outbound";
  }
  let offsetTypeRelativeTo = null;
  if (OperationOffsetType.INBOUND === operationToEdit.offsetTypeId) {
    offsetTypeRelativeTo = "arrival";
  } else {
    offsetTypeRelativeTo = "departure";
  }

  const [offsetValueModifier, setOffsetValueModifier] = useState(
    operationToEdit.startOffsetMinutes < 0 ? "-1" : "1"
  );
  const [offsetValue, setOffsetValue] = useState(
    Math.abs(operationToEdit.startOffsetMinutes)
  );

  function handleSetOperationEnabled(enabled) {
    const operationToUpdate = deepCopy(operationToEdit);
    operationToUpdate.enabled = enabled;

    // if (!enabled) {
    //   // Clear assignments
    //   const requirements = operationToUpdate.turnaroundRequirements;
    //   for (let i = 0; i < requirements.length; i++) {
    //     const requirement = requirements[i];
    //     const crewAssignments = requirement.crewAssignments;
    //     if (!isNullOrUndefined(crewAssignments)) {
    //       for (let j = 0; j < crewAssignments.length; j++) {
    //         crewAssignments[j] = {
    //           uuid: null,
    //           user: null,
    //           acceptedAt: null,
    //         };
    //       }
    //     }
    //   }
    // }
    onOperationChange(operationToUpdate);
  }
  function handleShowSelectCrewMember(slotId) {
    setShowSelectCrewMember(slotId);
  }

  function handleAssignSelectedUser(requirementUuid, idx, user) {
    const operationToUpdate = deepCopy(operationToEdit);
    const requirements = operationToUpdate.turnaroundRequirements;
    let updated = false;
    for (let i = 0; !updated && i < requirements.length; i++) {
      const requirement = requirements[i];
      if (requirement.uuid === requirementUuid) {
        if (idx < requirement.crewAssignments.length) {
          requirement.crewAssignments[idx] = {
            user: deepCopy(user),
          };
        }
        updated = true;
      }
    }
    onOperationChange(operationToUpdate);
  }

  function handleChangeQuantity(requirementUuid, quantity) {
    const operationToUpdate = deepCopy(operationToEdit);
    const requirements = operationToUpdate.turnaroundRequirements;
    let crewAssignments = null;
    let updated = false;

    for (let i = 0; !updated && i < requirements.length; i++) {
      const requirement = requirements[i];
      if (requirement.uuid === requirementUuid) {
        requirement.quantity = parseInt(quantity);
        crewAssignments = requirement.crewAssignments;

        // match the number of crewAssignments with quantity
        if (crewAssignments.length > quantity) {
          crewAssignments.splice(quantity);
        } else if (crewAssignments.length < quantity) {
          const crewNeeded = quantity - crewAssignments.length;
          for (let i = 0; i < crewNeeded; i++) {
            crewAssignments.push({
              uuid: null,
              user: null,
              acceptedAt: null,
            });
          }
        }
        updated = true;
      }
    }
    onOperationChange(operationToUpdate);
  }

  function handleRemoveCrew(requirementUuid, idx) {
    const operationToUpdate = deepCopy(operationToEdit);
    const requirements = operationToUpdate.turnaroundRequirements;
    let requirementToEdit = null;
    for (
      let i = 0;
      isNullOrUndefined(requirementToEdit) && i < requirements.length;
      i++
    ) {
      const requirement = requirements[i];
      if (requirement.uuid === requirementUuid) {
        requirementToEdit = requirement;
      }
    }
    if (!isNullOrUndefined(requirementToEdit)) {
      requirementToEdit.crewAssignments[idx] = {
        uuid: null,
        user: null,
        acceptedAt: null,
      };
    }
    onOperationChange(operationToUpdate);
  }

  function handleOperationStartTimeOffsetChange(startOffsetMinutes) {
    setOffsetValue(startOffsetMinutes);
    const persistedValue = parseInt(offsetValueModifier) * startOffsetMinutes;
    const operationToUpdate = deepCopy(operationToEdit);
    operationToUpdate.startOffsetMinutes = persistedValue;
    onOperationChange(operationToUpdate);
  }

  function handleOperationStartTimeModifierChange(modifier) {
    setOffsetValueModifier(modifier);
    const persistedValue = parseInt(modifier) * offsetValue;
    const operationToUpdate = deepCopy(operationToEdit);
    operationToUpdate.startOffsetMinutes = persistedValue;
    onOperationChange(operationToUpdate);
  }

  let totalCrewRequired = 0;
  let totalCrewAssigned = 0;
  if (!isNullOrUndefined(operationToEdit)) {
    const requirements = operationToEdit.turnaroundRequirements;

    for (let i = 0; i < requirements.length; i++) {
      const requirement = requirements[i];
      const crewAssignments = requirement.crewAssignments;
      totalCrewRequired = totalCrewRequired + requirement.quantity;
      for (let j = 0; j < crewAssignments.length; j++) {
        const crewAssignment = crewAssignments[j];
        if (!isNullOrUndefined(crewAssignment.user)) {
          totalCrewAssigned = totalCrewAssigned + 1;
        }
      }
    }
  }

  return (
    <div
      className={`dispatch-editor-module${
        !operationToEdit.enabled ? " disabled" : ""
      }`}
    >
      <div className="dispatch-editor-module-header">
        <div className="title">
          <h4>{operationToEdit.name}</h4>
          <div className="badge">{offsetTypeName}</div>
        </div>
        <div>
          <ToggleSwitch
            isSelected={operationToEdit.enabled}
            onChange={(enabled) => {
              handleSetOperationEnabled(enabled);
            }}
          />
        </div>
      </div>
      {operationToEdit.enabled && (
        <div className="dispatch-editor-module-body">
          <div className="dispatch-editor-module-main">
            {operationToEdit.turnaroundRequirements &&
              operationToEdit.turnaroundRequirements.map((requirement) => (
                <div
                  className="dispatch-editor-module-inset"
                  key={requirement.uuid}
                >
                  <div className="dispatch-editor-module-row">
                    <div className="dispatch-editor-module-title">
                      {requirement.certification.name}
                    </div>
                    <div>
                      <select
                        value={requirement.quantity}
                        onChange={(e) => {
                          const requirementUuid = requirement.uuid;
                          handleChangeQuantity(requirementUuid, e.target.value);
                        }}
                        style={{
                          ...selectInputFix,
                          ...{ paddingLeft: "12px", paddingRight: "32px" },
                        }}
                      >
                        <option value="0">
                          {t("crew_members_allocated_web", { value: "0" })}
                        </option>
                        <option value="1">
                          {t("crew_member_allocated_web", { value: "1" })}
                        </option>
                        <option value="2">
                          {t("crew_members_allocated_web", { value: "2" })}
                        </option>
                        <option value="3">
                          {t("crew_members_allocated_web", { value: "3" })}
                        </option>
                        <option value="4">
                          {t("crew_members_allocated_web", { value: "4" })}
                        </option>
                        <option value="5">
                          {t("crew_members_allocated_web", { value: "5" })}
                        </option>
                        <option value="6">
                          {t("crew_members_allocated_web", { value: "6" })}
                        </option>
                      </select>
                    </div>
                  </div>
                  {requirement.crewAssignments &&
                    requirement.crewAssignments.map((crewAssignment, idx) => {
                      const userToRender = crewAssignment?.user;
                      const userInfo = !isNullOrUndefined(userToRender)
                        ? getUserInfo(userToRender, turnarounds, positions)
                        : null;
                      return (
                        <div
                          className={`dispatch-editor-module-assignment${
                            isNullOrUndefined(userToRender) ? " empty-slot" : ""
                          }`}
                          key={`${requirement.uuid}-${idx}`}
                        >
                          {isNullOrUndefined(userToRender) && (
                            <>
                              {showSelectCrewMember ===
                                `${requirement.uuid}-${idx}` && (
                                <SelectCrewMember
                                  onSelect={(user) => {
                                    handleAssignSelectedUser(
                                      requirement.uuid,
                                      idx,
                                      user
                                    );
                                  }}
                                  onCancel={() => {
                                    setShowSelectCrewMember(null);
                                  }}
                                  certification={requirement.certification}
                                  crewAssignments={requirement.crewAssignments}
                                  users={users}
                                  positions={positions}
                                  turnarounds={turnarounds}
                                />
                              )}
                              {showSelectCrewMember !==
                                `${requirement.uuid}-${idx}` && (
                                <div>
                                  <div className="unassigned">
                                    {t("unassigned")}
                                  </div>
                                  <div>
                                    <span>{t("please_add_crew")}</span>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => {
                                        handleShowSelectCrewMember(
                                          `${requirement.uuid}-${idx}`
                                        );
                                      }}
                                      className="outlined"
                                    >
                                      {t("add_crew_member")}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {!isNullOrUndefined(userToRender) && (
                            <div>
                              <CrewInfo
                                user={userToRender}
                                details={getCrewAcceptedStatus(
                                  monitor,
                                  operationToEdit,
                                  requirement,
                                  crewAssignment
                                )}
                                mapStatus={userInfo.mapStatus}
                              />
                              <div>
                                <button
                                  className="danger-outline"
                                  onClick={() => {
                                    handleRemoveCrew(requirement.uuid, idx);
                                  }}
                                >
                                  {t("remove")}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              ))}
          </div>
          <div className="dispatch-editor-module-side">
            <div>
              <div
                className={`label-value-pair${
                  totalCrewAssigned < totalCrewRequired ? " danger" : ""
                }`}
              >
                <label>{t("required_crew_members")}</label>
                <div className="stat">
                  <div>{`${totalCrewAssigned}/${totalCrewRequired}`}</div>
                </div>
              </div>

              <div className="label-value-pair">
                <label>{t("start_time_offset")}</label>
                <div className="field-pair">
                  <input
                    type="text"
                    value={offsetValue}
                    onKeyDown={(e) => {
                      if (e.key.length === 1) {
                        const keyVal = parseInt(e.key);
                        if (isNaN(keyVal)) {
                          e.preventDefault();
                        }
                      }
                    }}
                    onBlur={(e) => {
                      const val = parseInt(e.target.value);
                      if (isNaN(val)) {
                        handleOperationStartTimeOffsetChange(
                          DEFAULT_START_TIME_OFFSET
                        );
                      }
                    }}
                    onChange={(e) => {
                      handleOperationStartTimeOffsetChange(e.target.value);
                    }}
                  />
                  <select
                    style={selectInputFix}
                    value={offsetValueModifier}
                    onChange={(e) => {
                      handleOperationStartTimeModifierChange(e.target.value);
                    }}
                  >
                    <option value="-1">
                      {t("mins_before_web", { name: offsetTypeRelativeTo })}
                    </option>
                    <option value="1">
                      {t("mins_after_web", { name: offsetTypeRelativeTo })}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function getCrewAcceptedStatus(
  monitor,
  operation,
  requirement,
  crewAssignment
) {
  const crewMonitor = getCrewMonitor(
    monitor,
    operation,
    requirement,
    crewAssignment
  );
  let statusText = null;
  if (!isNullOrUndefined(crewMonitor)) {
    statusText = isNullOrUndefined(crewMonitor.acceptedAt)
      ? "Pending"
      : "Accepted";
  }

  return statusText;
}

function getCrewMonitor(monitor, operation, requirement, crewAssignment) {
  if (isNullOrUndefined(monitor)) return null;
  const turnaroundOperations = monitor.turnaroundOperations;
  if (isNullOrUndefined(turnaroundOperations)) return null;

  let turnOperation = null;
  for (
    let i = 0;
    isNullOrUndefined(turnOperation) && i < turnaroundOperations.length;
    i++
  ) {
    const turnaroundOperation = turnaroundOperations[i];
    if (turnaroundOperation.uuid === operation.uuid) {
      turnOperation = turnaroundOperation;
    }
  }
  if (isNullOrUndefined(turnOperation)) return null;
  const turnaroundRequirements = turnOperation.turnaroundRequirements;
  if (isNullOrUndefined(turnaroundRequirements)) return null;
  let turnRequirement = null;
  for (
    let i = 0;
    isNullOrUndefined(turnRequirement) && i < turnaroundRequirements.length;
    i++
  ) {
    const turnaroundRequirement = turnaroundRequirements[i];
    if (turnaroundRequirement.uuid === requirement.uuid) {
      turnRequirement = turnaroundRequirement;
    }
  }
  if (isNullOrUndefined(turnRequirement)) return null;
  const crewList = turnRequirement.crewAssignments;
  if (isNullOrUndefined(crewList)) return null;

  let crewMonitor = null;
  for (let i = 0; isNullOrUndefined(crewMonitor) && i < crewList.length; i++) {
    const crew = crewList[i];
    if (crew.uuid === crewAssignment.uuid) {
      crewMonitor = crew;
    }
  }
  return crewMonitor;
}

export default DispatchOperation;
