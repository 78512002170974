import "./styles.css";
import { ReactComponent as LoadingRing } from "../../assets/ring-resize.svg";
function LoadingIndicator(props) {
  const { overlay } = props;
  return (
    <div className={`loading-indicator${overlay ? " overlay" : ""}`}>
      <LoadingRing />
    </div>
  );
}

export default LoadingIndicator;
