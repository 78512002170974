import { useTranslation } from "react-i18next";
import { METRICS_SORT_FIELDS } from "../../constants";
import { isNullOrUndefined, sortByField } from "../../utils";
import OperationSortBar from "./OperationSortBar";
import TurnaroundOperationTile from "./TurnaroundOperationTile";
import { useState } from "react";

function OperationOnTimePerformance(props) {
  const { t } = useTranslation();
  const {
    chartData,
    wideLayout,
    showSort,
    selectedOperations,
    operationsList,
    includeMissingData,
  } = props;
  const [selectedSort, setSelectedSort] = useState(
    METRICS_SORT_FIELDS.COMPLETED_ONTIME
  );
  const operations = !isNullOrUndefined(chartData) ? chartData.operations : [];

  const filteredList = [];
  const allowedOperations =
    !isNullOrUndefined(selectedOperations) && selectedOperations.length > 0
      ? selectedOperations
      : operationsList.map((item) => item.name);

  for (let i = 0; i < operations?.length; i++) {
    const operationMetric = operations[i];
    if (allowedOperations?.includes(operationMetric.name)) {
      let totalOperationCount =
        operationMetric.completedLateCount +
        operationMetric.completedOnTimeCount +
        operationMetric.partiallyCompletedCount;

      if (includeMissingData) {
        totalOperationCount =
          totalOperationCount + operationMetric.missingCount;
      }
      const dataValues = {
        ...operationMetric,
        totalOperations: totalOperationCount,
        completedLatePercent:
          totalOperationCount > 0
            ? (operationMetric.completedLateCount / totalOperationCount) * 100
            : 0,
        completedOnTimePercent:
          totalOperationCount > 0
            ? (operationMetric.completedOnTimeCount / totalOperationCount) * 100
            : 0,

        partiallyCompletedPercent:
          totalOperationCount > 0
            ? (operationMetric.partiallyCompletedCount / totalOperationCount) *
              100
            : 0,
      };
      if (includeMissingData) {
        dataValues.missingPercent =
          totalOperationCount > 0
            ? (operationMetric.missingCount / totalOperationCount) * 100
            : 0;
      }
      filteredList.push(dataValues);
    }
  }
  const altSortFields = [
    "completedOnTimePercent",
    "completedLatePercent",
    "partiallyCompletedPercent",
    "missingPercent",
  ];
  if (selectedSort === METRICS_SORT_FIELDS.COMPLETED_LATE) {
    sortByField(filteredList, "completedLatePercent", altSortFields);
  } else if (selectedSort === METRICS_SORT_FIELDS.MISSING) {
    sortByField(filteredList, "missingPercent", altSortFields);
  } else if (selectedSort === METRICS_SORT_FIELDS.AVG_DELAY) {
    sortByField(filteredList, "averageDelay", altSortFields);
  } else if (selectedSort === METRICS_SORT_FIELDS.COMPLETED_PARTIALLY) {
    sortByField(filteredList, "partiallyCompletedPercent", altSortFields);
  } else if (selectedSort === METRICS_SORT_FIELDS.COMPLETED_ONTIME) {
    sortByField(filteredList, "completedOnTimePercent", altSortFields);
  }
  filteredList.reverse();

  const layoutClass = wideLayout ? "two" : "three";

  return (
    <div className={`chart-container${showSort ? " sortable" : ""}`}>
      {showSort && (
        <>
          <div className="chart-title-bar">
            <h4>{t("ground_operations_on_time_performance")}</h4>
          </div>
          <OperationSortBar
            selectedSort={selectedSort}
            onChange={(value) => {
              setSelectedSort(value);
            }}
            includeMissingData={includeMissingData}
          />
        </>
      )}
      <div className={`chart-tiles ${layoutClass}`}>
        {filteredList &&
          filteredList.map((item, idx) => (
            <TurnaroundOperationTile
              operation={item}
              key={`module-${idx}`}
              highlightMetric={selectedSort}
              wideLayout={wideLayout}
              includeMissingData={includeMissingData}
            />
          ))}
      </div>
    </div>
  );
}

export default OperationOnTimePerformance;
