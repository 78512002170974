import "./styles.css";
import { ReactComponent as LogoSvg } from "../../assets/moonware_logo.svg";
import { ReactComponent as CaretDownSvg } from "../../assets/caret-down.svg";
import { ReactComponent as Resources } from "../../assets/resources.svg";
import { ReactComponent as Performance } from "../../assets/performance.svg";
import { ReactComponent as Dashboard } from "../../assets/dashboard.svg";
import { ReactComponent as Notifications } from "../../assets/notifications.svg";
import { ReactComponent as Flights } from "../../assets/flights.svg";
import { ReactComponent as Map } from "../../assets/map.svg";
import { ReactComponent as Chat } from "../../assets/chat.svg";
import { ReactComponent as Turnarounds } from "../../assets/turnarounds.svg";
import { ReactComponent as Dispatches } from "../../assets/dispatches.svg";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { REACT_APP_ENV_NAME } from "../../api";
import AlertIndicator from "../AlertIndicator";
import AlertProvider from "../../AlertProvider";
import DispatchesIndicator from "../DispatchesIndicator";
import { useTranslation } from "react-i18next";
import ChatIndicator from "../ChatIndicator";

function MainMenu(props) {
  const { t } = useTranslation();
  const {
    showAdminUsers,
    showAdminGses,
    showPerformance,
    showUtilization,
    showLeaderboard,
    showDispatch,
    showTurnaroundTemplates,
    showGateSchedule,
  } = props;

  const showAdminSection =
    showAdminUsers || showAdminGses || showTurnaroundTemplates;
  const showPerformanceSection =
    showPerformance || showUtilization || showLeaderboard;
  const [isAdminOpen, setIsAdminOpen] = useState(true);
  const [isPerformanceOpen, setIsPerformanceOpen] = useState(true);

  return (
    <>
      <div className="menu-header">
        <NavLink to="/dashboard" className="main-logo">
          <LogoSvg />
        </NavLink>
      </div>
      <div className="menu-container">
        <div className="menu-list">
          <NavLink to="/dashboard" className="menu-item">
            <div className="menu-item-indicator"></div>
            <div className="menu-item-body">
              <div className="menu-item-icon">
                <Dashboard />
              </div>
              {t("dashboard")}
            </div>
          </NavLink>
          <NavLink to="/map" className="menu-item">
            <div className="menu-item-indicator"></div>
            <div className="menu-item-body">
              <div className="menu-item-icon">
                <Map />
              </div>
              {t("map_non_cap")}
            </div>
          </NavLink>
          <NavLink
            to="/chat"
            className="menu-item"
            data-testid="menu-link-chat"
          >
            <div className="menu-item-indicator"></div>
            <div className="menu-item-body indicators">
              <div className="menu-item-icon">
                <Chat />
              </div>
              <span>{t("chat")}</span>
              <div>
                <ChatIndicator />
              </div>
            </div>
          </NavLink>
          <NavLink to="/flights" className="menu-item">
            <div className="menu-item-indicator"></div>
            <div className="menu-item-body">
              <div className="menu-item-icon">
                <Flights />
              </div>
              {t("flights")}
            </div>
          </NavLink>

          <NavLink to="/turnarounds" className="menu-item">
            <div className="menu-item-indicator"></div>
            <div className="menu-item-body">
              <div className="menu-item-icon">
                <Turnarounds />
              </div>
              {t("turnarounds")}
            </div>
          </NavLink>
          {showDispatch && (
            <NavLink to="/allocations" className="menu-item">
              <div className="menu-item-indicator"></div>
              <div className="menu-item-body indicators">
                <div className="menu-item-icon">
                  <Dispatches />
                </div>
                <span>{t("allocations")}</span>

                <div>
                  <DispatchesIndicator />
                </div>
              </div>
            </NavLink>
          )}
          {showAdminSection && (
            <>
              <div
                className={`menu-item toggle ${isAdminOpen ? "open" : ""}`}
                onClick={() => {
                  setIsAdminOpen(!isAdminOpen);
                }}
              >
                <div className="menu-item-indicator"></div>
                <div className="menu-item-body">
                  <div className="menu-item-icon">
                    <Resources />
                  </div>
                  {t("resources")}
                </div>
                <div className="menu-item-toggle">
                  <CaretDownSvg />
                </div>
              </div>
              {isAdminOpen && (
                <>
                  {showAdminUsers && (
                    <NavLink to="/users" className="menu-item">
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon"></div>
                        {t("users")}
                      </div>
                    </NavLink>
                  )}
                  {showAdminGses && (
                    <NavLink to="/vehicles" className="menu-item">
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon"></div>
                        {t("vehicles")}
                      </div>
                    </NavLink>
                  )}
                  {showTurnaroundTemplates && (
                    <NavLink to="/templates" className="menu-item">
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon"></div>
                        {t("turnaround_templates")}
                      </div>
                    </NavLink>
                  )}
                  {showGateSchedule && (
                    <NavLink to="/stand-assignments" className="menu-item">
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon"></div>
                        {t("stand_assignments")}
                      </div>
                    </NavLink>
                  )}
                </>
              )}
            </>
          )}
          {showPerformanceSection && (
            <>
              <div
                className={`menu-item toggle ${
                  isPerformanceOpen ? "open" : ""
                }`}
                onClick={() => {
                  setIsPerformanceOpen(!isPerformanceOpen);
                }}
              >
                <div className="menu-item-indicator"></div>
                <div className="menu-item-body">
                  <div className="menu-item-icon">
                    <Performance />
                  </div>
                  {t("performance")}
                </div>
                <div className="menu-item-toggle">
                  <CaretDownSvg />
                </div>
              </div>
              {isPerformanceOpen && (
                <>
                  {showPerformance && (
                    <NavLink to="/performance" className="menu-item">
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon"></div>
                        <span>{t("on_time_performance")}</span>
                      </div>
                    </NavLink>
                  )}
                  {showUtilization && (
                    <NavLink to="/utilization" className="menu-item">
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon"></div>
                        <span>{t("vehicle_utilization")}</span>
                      </div>
                    </NavLink>
                  )}
                  {showLeaderboard && (
                    <NavLink to="/leaderboard" className="menu-item">
                      <div className="menu-item-indicator"></div>
                      <div className="menu-item-body">
                        <div className="menu-item-icon"></div>
                        <span>{t("crew_leaderboard")}</span>
                      </div>
                    </NavLink>
                  )}
                </>
              )}
            </>
          )}
          <NavLink to="/alerts" className="menu-item">
            <div className="menu-item-indicator"></div>
            <div className="menu-item-body indicators">
              <div className="menu-item-icon">
                <Notifications />
              </div>
              <span>{t("alerts")}</span>
              <div>
                <AlertProvider>
                  <AlertIndicator />
                </AlertProvider>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
      <div className={`menu-footer ${REACT_APP_ENV_NAME}`}>
        <div className="app-status">{REACT_APP_ENV_NAME}</div>
      </div>
    </>
  );
}
export default MainMenu;
