import { TurnaroundOperationStatus } from "../../turnaroundUtils";
import { isNullOrUndefined } from "../../utils";
import { ReactComponent as AlertRed } from "../../assets/alert-small-red.svg";
import { ReactComponent as AlertYellow } from "../../assets/alert-small-yellow.svg";

function TurnaroundProgressBar(props) {
  const { turnaroundInfo, showOperationStatus } = props;

  let statusClass = "not-started";
  if (turnaroundInfo?.percentComplete === 100) {
    statusClass = "completed";
  } else if (turnaroundInfo?.percentComplete > 0) {
    statusClass = "in-service";
  }

  const status = turnaroundInfo?.turnaroundLevelOperationStatus;
  const statusMissingCount = !isNullOrUndefined(status)
    ? status[TurnaroundOperationStatus.MISSING]
    : null;
  const statusDelayedCount = !isNullOrUndefined(status)
    ? status[TurnaroundOperationStatus.IN_PROGRESS_LATE]
    : 0;
  return (
    <div className={`turnaround-progress ${statusClass}`}>
      <div className="progress-info">
        <div>
          <div className="status-badge">{turnaroundInfo.statusDisplayName}</div>
        </div>
        <div>{turnaroundInfo.percentComplete}%</div>
        <div
          className={`operation-status${
            statusMissingCount > 0 && statusDelayedCount ? " double" : ""
          }`}
        >
          {showOperationStatus && statusMissingCount > 0 && (
            <div
              className={`operation-status-badge status-${TurnaroundOperationStatus.MISSING}`}
            >
              <div>
                <div>
                  <AlertRed />
                </div>
                <div>{statusMissingCount}</div>
              </div>
            </div>
          )}
          {showOperationStatus && statusDelayedCount > 0 && (
            <div
              className={`operation-status-badge status-${TurnaroundOperationStatus.IN_PROGRESS_LATE}`}
            >
              <div>
                <div>
                  <AlertYellow />
                </div>
                <div>{statusDelayedCount}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="progress-bar">
        <div
          className="progress-bar-filled"
          style={{
            width: `${turnaroundInfo.percentComplete}%`,
          }}
        ></div>
      </div>
    </div>
  );
}

export default TurnaroundProgressBar;
