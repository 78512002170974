import "./styles.css";
import { ReactComponent as GreenCheck } from "../../assets/timeline/timeline-green-check.svg";
import { ReactComponent as Info } from "../../assets/timeline/timeline-info.svg";
import { ReactComponent as PlaneLanding } from "../../assets/timeline/timeline-plane-landing.svg";
import { ReactComponent as PlaneTakeoff } from "../../assets/timeline/timeline-plane-takeoff.svg";
import { ReactComponent as YellowHazard } from "../../assets/timeline/timeline-yellow-hazard.svg";
import { TIMELINE_EVENTS } from "../../constants";

function TimelineEventIcon(props) {
  const { timelineEventType } = props;

  return (
    <>
      {(timelineEventType === TIMELINE_EVENTS.UNKNOWN ||
        timelineEventType === TIMELINE_EVENTS.SERVICE_INITIATED ||
        timelineEventType === TIMELINE_EVENTS.STAND_UPDATE ||
        timelineEventType === TIMELINE_EVENTS.GATE_IN ||
        timelineEventType === TIMELINE_EVENTS.GATE_OUT ||
        timelineEventType === TIMELINE_EVENTS.OPERATION_INITIATED ||
        timelineEventType === TIMELINE_EVENTS.CREW_DISPATCHED) && <Info />}
      {timelineEventType === TIMELINE_EVENTS.OPERATION_COMPLETED && (
        <GreenCheck />
      )}
      {timelineEventType === TIMELINE_EVENTS.AIRCRAFT_LANDED && (
        <PlaneLanding />
      )}
      {(timelineEventType === TIMELINE_EVENTS.AIRCRAFT_DEPARTED ||
        timelineEventType === TIMELINE_EVENTS.INBOUND_AIRBORNE) && (
        <PlaneTakeoff />
      )}
      {timelineEventType === TIMELINE_EVENTS.OPERATION_DELAYED && (
        <YellowHazard />
      )}
    </>
  );
}

export default TimelineEventIcon;
