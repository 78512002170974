function MapInfo(props) {
  const { valuePairs } = props;
  return (
    <div className="map-info">
      {valuePairs.map((val, idx) => (
        <div key={idx}>
          <div>{val.label}</div>
          <div>{val.value}</div>
        </div>
      ))}
    </div>
  );
}

export default MapInfo;
