import { useEffect, useMemo } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import "./styles.css";
import {
  getAirportDetailByUuid,
  getTurnaroundStandAssignments,
} from "../../api";
import {
  deepCopy,
  isNullOrUndefined,
  sortByDateField,
  sortByField,
} from "../../utils";
import GateScheduleRow from "./GateScheduleRow";
import { useTranslation } from "react-i18next";
import { DEFAULT_TIMEZONE } from "../../constants";

function GateSchedule() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, turnaroundStandAssignments, airportDetail } =
    mainContext;

  useEffect(() => {
    getTurnaroundStandAssignments(dispatch);
    if (!isNullOrUndefined(currentUser)) {
      getAirportDetailByUuid(dispatch, { uuid: currentUser?.airport.uuid });
    }
  }, [dispatch, currentUser]);

  const turnaroundStandAssignmentList = useMemo(() => {
    if (!isNullOrUndefined(turnaroundStandAssignments)) {
      const list = deepCopy(turnaroundStandAssignments);
      sortByDateField(list, "gateInTime");
      return list;
    }
    return [];
  }, [turnaroundStandAssignments]);

  const gatesList = useMemo(() => {
    const list = !isNullOrUndefined(airportDetail?.stands)
      ? airportDetail.stands
      : [];
    sortByField(list, "name");
    return list;
  }, [airportDetail]);

  if (isNullOrUndefined(currentUser)) return null;
  const airport = currentUser?.airport;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  return (
    <div className="gate-schedule">
      <div className="gate-schedule-container">
        <div className="gate-schedule-content">
          <div className="gate-schedule-header">
            <div>
              <h3>{t("stand_assignments")}</h3>
            </div>
          </div>

          <div className="listing">
            <div className="listing-header">
              <div>{t("flight_non_cap")}</div>
              <div>{t("registration")}</div>
              <div>{t("type")}</div>
              <div>{t("gate_in_non_cap")}</div>
              <div>{t("gate_out_non_cap")}</div>
              <div>{t("arrival_stand")}</div>
              <div>{t("reposition_stand")}</div>
              <div>{t("departure_stand")}</div>
              <div>&nbsp;</div>
            </div>
            {turnaroundStandAssignmentList.length === 0 && (
              <div className="listing-empty">
                <div>{t("turnarounds_empty")}</div>
              </div>
            )}
            {turnaroundStandAssignmentList &&
              turnaroundStandAssignmentList.map((turnaroundInfo) => (
                <GateScheduleRow
                  key={turnaroundInfo.uuid}
                  turnaroundStandAssignment={turnaroundInfo}
                  gates={gatesList}
                  airportTimezone={airportTimezone}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default GateSchedule;
