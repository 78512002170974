import { useEffect, useRef } from "react";
import "./styles.css";
import { isNullOrUndefined } from "../../utils";
import { ReactComponent as Download } from "../../assets/download.svg";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useTranslation } from "react-i18next";

function DataExport(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const { handleDataExport } = props;
  const mainContext = useMainContext();
  const { dataExport, dataExportSaving } = mainContext;

  const exportLinkRef = useRef(null);
  useEffect(() => {
    if (
      !isNullOrUndefined(dataExport?.downloadUrl) &&
      !isNullOrUndefined(exportLinkRef?.current)
    ) {
      exportLinkRef.current.setAttribute("href", dataExport.downloadUrl);
      exportLinkRef.current.click();
      dispatch({
        type: "setDataExport",
        value: {},
      });
    }
  }, [dispatch, dataExport, exportLinkRef]);

  return (
    <div className="data-export">
      <button
        className="outlined download-button"
        onClick={() => {
          if (dataExportSaving) return;
          handleDataExport();
        }}
        disabled={dataExportSaving}
      >
        <Download />
        {t("export")}
      </button>
      <a className="download-link" href="/" ref={exportLinkRef} download="">
        {t("export")}
      </a>
    </div>
  );
}
export default DataExport;
