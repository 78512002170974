import Radio from "../Radio";

function BigButton(props) {
  const { label, imageSrc, onSelect, selected } = props;
  const dynamicStyle = {
    backgroundImage: `url(${imageSrc})`,
  };
  return (
    <div
      className={`big-button${selected ? " selected" : ""}`}
      onClick={() => {
        onSelect(!selected);
      }}
    >
      <div>
        <div className="big-button-image" style={dynamicStyle}></div>
        <div className="big-button-detail">
          <div className="big-button-selector">
            <Radio selected={selected} />
          </div>
          <div>{label}</div>
        </div>
      </div>
    </div>
  );
}
export default BigButton;
