import { useEffect, useRef } from "react";
import { useMainDispatchContext } from "../../MainContext";
import { ReactComponent as CaretRight } from "../../assets/caret-right.svg";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import { isNullOrUndefined } from "../../utils";
import BatteryPower from "../BatteryPower";
import { useTranslation } from "react-i18next";

function VehicleInfoModule(props) {
  const { t } = useTranslation();
  const { info, isSelected, isLocateMode } = props;
  const dispatch = useMainDispatchContext();
  const details =
    !isNullOrUndefined(info.turnaroundInfo) &&
    !isNullOrUndefined(info.gseRecord) ? (
      <>
        {info.turnaroundInfo.combinedFlightName}&nbsp;&bull;&nbsp;
        {info.gseRecord?.operationInfo?.name}
      </>
    ) : (
      <>{`${t("last_online")} ${
        !isNullOrUndefined(info?.position?.lastOnline)
          ? info?.position?.lastOnline
          : "-"
      }`}</>
    );

  const selectedRef = useRef(null);
  useEffect(() => {
    if (isSelected && !isNullOrUndefined(selectedRef.current)) {
      // Attempt to scroll the element into view if not already
      const { top, left, bottom, right } =
        selectedRef.current.getBoundingClientRect();
      const { innerHeight, innerWidth } = window;
      const isFullyVisible =
        top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;

      if (!isFullyVisible) {
        document.querySelector(".overlay-panel-content").scrollTo({
          left: 0,
          top: selectedRef.current.offsetTop - 16,
          behavior: "smooth",
        });
      }
    }
  }, [isSelected]);

  return (
    <div
      ref={isSelected ? selectedRef : null}
      className={`overlay-module${isSelected ? " selected" : ""}`}
      data-trackercode={info?.position?.trackerCode}
      data-uuid={info?.uuid}
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.VEHICLE,
            item: {
              uuid: info.uuid,
            },
            isLocateMode: isLocateMode,
          },
        });
      }}
    >
      <div className="overlay-module-box">
        <div className="vehicle-info">
          <div className="vehicle-status">
            <div className={`status-indicator status-${info.status}`}></div>
          </div>
          <div className="vehicle-details">
            <div className="vehicle-name">
              <div>{info.name}</div>
              <div>
                <BatteryPower
                  battery={info?.position?.battery}
                  hasPower={info?.position?.hasPower}
                />
              </div>
            </div>
            <div>{info.type}</div>
            <div>{details}</div>
          </div>
          <div>
            <CaretRight className="arrow-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleInfoModule;
