import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { ANALYTICS_EVENTS } from "../../constants";
import { filterList, isNullOrUndefined } from "../../utils";
import { useTranslation } from "react-i18next";

function DispatchesIndicator() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { turnaroundAllocations, currentUser } = mainContext;

  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    if (isNullOrUndefined(currentUser)) return;
    const turnaroundInfoList = turnaroundAllocations.map(
      (turnaroundAllocation) => turnaroundAllocation
    );
    const criticalDispatches = filterList(
      turnaroundInfoList,
      (item) => item.numUnallocatedCrew > 0
    );

    if (criticalDispatches.length > 0) {
      setShowIndicator(true);
      const turnaroundForDispatch = criticalDispatches[0];
      const messageDetail =
        criticalDispatches.length === 1
          ? `${turnaroundForDispatch.displayName}`
          : `${t("unallocated_turnarounds_web", {
              value: criticalDispatches.length,
            })}`;
      dispatch({
        type: "setNotification",
        notification: {
          message: t("attention_required"),
          messageDetail: messageDetail,
          actionLocation: "/allocations",
          actionLabel: t("view"),
          analyticsEvent: ANALYTICS_EVENTS.ALLOCATIONS_BANNER_CLICK,
        },
      });
    } else {
      // Clear the notification if nothing pending
      setShowIndicator(false);
      dispatch({
        type: "setNotification",
        notification: null,
      });
    }
  }, [dispatch, turnaroundAllocations, currentUser, t]);

  return <>{showIndicator && <div className="status-indicator"></div>}</>;
}
export default DispatchesIndicator;
