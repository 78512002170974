import { useMainContext } from "../../MainContext";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { isNullOrUndefined } from "../../utils";

function Analytics() {
  const mainContext = useMainContext();
  const { dynamicEnv, currentUser, analyticsEvent } = mainContext;
  const posthog = usePostHog();

  useEffect(() => {
    if (
      !isNullOrUndefined(posthog) &&
      !isNullOrUndefined(dynamicEnv?.posthogToken) &&
      !isNullOrUndefined(currentUser)
    ) {
      // Token can be replaced locally if needed (admin)
      const token = dynamicEnv.posthogToken;
      posthog.init(token, {
        api_host: "https://us.i.posthog.com",
      });
      posthog?.identify(currentUser.uuid, {
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: currentUser.email,
        deploymentUserGroupUuid: currentUser.userGroupUuid,
      });
    }
  }, [posthog, dynamicEnv, currentUser]);

  useEffect(() => {
    if (!isNullOrUndefined(analyticsEvent)) {
      posthog.capture(analyticsEvent.eventName, analyticsEvent.properties);
    }
  }, [posthog, analyticsEvent]);
}

export default Analytics;
