import Checkbox from "../Checkbox";

function MapFilterChoice(props) {
  const { onChange, value, display, selected } = props;
  return (
    <div className={`filter-menu-option ${selected ? "selected" : ""}`}>
      <div className="filter-menu-option-box">
        <div>
          <label
            onClick={() => {
              onChange(value);
            }}
          >
            {display}
          </label>
        </div>
        <Checkbox
          value={value}
          changeHandler={(value) => {
            onChange(value);
          }}
          isChecked={selected}
        />
      </div>
    </div>
  );
}

export default MapFilterChoice;
