export class CookieManager {
  static getCookieValue(name) {
    const cookieValues = CookieManager.readCookieString();
    return cookieValues[name];
  }

  static setCookieValue(name, value) {
    document.cookie = `${name}=${encodeURIComponent(value)}`;
  }

  static clearAllCookies() {
    // expires immediately
    const cookieValues = CookieManager.readCookieString();
    for (const name in cookieValues) {
      document.cookie = `${name}=; max-age=0`;
    }
  }

  static readCookieString() {
    const cookieString = document.cookie || "";
    const cookieParts = cookieString.split(";");
    const cookieValues = {};
    for (let i = 0; i < cookieParts.length; i++) {
      const cookiePart = cookieParts[i];
      const nameValuePair = cookiePart.split("=");
      const name = nameValuePair.length > 0 ? nameValuePair[0].trim() : "";
      const value =
        nameValuePair.length > 1
          ? decodeURIComponent(nameValuePair[1].trim())
          : null;
      cookieValues[name] = value;
    }
    return cookieValues;
  }
}
