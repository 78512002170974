import "./styles.css";
import moment from "moment-timezone";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useEffect, useState } from "react";
import { isNullOrUndefined } from "../../utils";
import { DEFAULT_TIMEZONE, MIN_DATE } from "../../constants";
import { getGseUtilizationExport, getVehicleUtilization } from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import DataTable, { ColumnNames } from "../DataTable";
import RangePicker from "../ButtonDatePicker/RangePicker";
import DataExport from "../DataExport";
import { useTranslation } from "react-i18next";

function Utilization() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, vehicleUtilization, vehicleUtilizationLoading } =
    mainContext;
  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const mMinDate = moment(MIN_DATE).tz(airportTimezone).startOf("day");
  const mToday = moment().tz(airportTimezone).startOf("minute");
  const mThirtyDaysAgo = moment(mToday).add(-30, "day");
  const [selectedStartDate, setSelectedStartDate] = useState(mThirtyDaysAgo);
  const [selectedEndDate, setSelectedEndDate] = useState(mToday);

  useEffect(() => {
    // convert to UTC
    const criteria = {
      startTime: moment.utc(selectedStartDate).format(),
      endTime: moment.utc(moment(selectedEndDate).endOf("day")).format(),
    };
    getVehicleUtilization(dispatch, criteria);
  }, [dispatch, airportTimezone, selectedStartDate, selectedEndDate]);

  const handleChangeDateRange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const utilizationList = !isNullOrUndefined(vehicleUtilization)
    ? vehicleUtilization
    : [];

  function handleDataExport() {
    const criteria = {
      startTime: moment.utc(selectedStartDate).format(),
      endTime: moment.utc(moment(selectedEndDate).endOf("day")).format(),
    };
    getGseUtilizationExport(dispatch, criteria);
  }

  return (
    <div className="utilization">
      <div className="utilization-container">
        <div className="metrics-filters">
          <div>
            <h3>{t("vehicle_utilization")}</h3>
          </div>
          <div>
            <RangePicker
              today={mToday}
              airportTimezone={airportTimezone}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              minDate={mMinDate}
              maxDate={mToday}
              maxRange={35}
              onChange={(startValue, endValue) => {
                handleChangeDateRange(startValue, endValue);
              }}
            />
          </div>
          <DataExport handleDataExport={handleDataExport} />
        </div>
        {vehicleUtilizationLoading && <LoadingIndicator />}
        {!vehicleUtilizationLoading && (
          <DataTable
            dataList={utilizationList}
            columnNames={[
              ColumnNames.VehicleName,
              ColumnNames.VehicleType,
              ColumnNames.VehicleAvgServiceTime,
              ColumnNames.MapVehicle,
            ]}
          />
        )}
      </div>
    </div>
  );
}
export default Utilization;
