import { useMainDispatchContext } from "../../MainContext";
import { ReactComponent as CaretRight } from "../../assets/caret-right.svg";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import { isNullOrUndefined } from "../../utils";
import CrewInfo from "../CrewInfo";

function CrewInfoModule(props) {
  const { info, isSelected } = props;
  const dispatch = useMainDispatchContext();
  const details =
    !isNullOrUndefined(info.activeTurnaround) &&
    !isNullOrUndefined(info.activeOperation) ? (
      <>
        {info.activeTurnaround.combinedFlightName}&nbsp;&bull;&nbsp;
        {info.activeOperation.name}
      </>
    ) : (
      info.certNames
    );

  return (
    <div
      className={`overlay-module${isSelected ? " selected" : ""}`}
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.USER,
            item: {
              uuid: info.uuid,
            },
          },
        });
      }}
    >
      <div className="overlay-module-box">
        <div className="crew-member-info">
          <div>
            <CrewInfo
              user={info.user}
              details={details}
              mapStatus={info.mapStatus}
            />
          </div>
          <div>
            <CaretRight className="arrow-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrewInfoModule;
