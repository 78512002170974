import { OperationOffsetType } from "./turnaroundUtils";
import { isNullOrUndefined, sortByField } from "./utils";

export const MAX_TIMELINE_DURATION_MINS = 4 * 60;

export function getTimelineFromTemplate(templateToEdit, targetBlockTime) {
  const preArrival = [];
  const postArrival = [];
  const preDeparture = [];
  const postDeparture = [];
  if (isNullOrUndefined(templateToEdit)) return null;

  let maxPreArrival = 0;
  let maxPostArrival = 0;
  let maxPreDeparture = 0;
  let maxPostDeparture = 0;
  for (let i = 0; i < templateToEdit.turnaroundOperations.length; i++) {
    const turnaroundOperation = templateToEdit.turnaroundOperations[i];
    if (
      turnaroundOperation.enabled &&
      turnaroundOperation.offsetTypeId === OperationOffsetType.INBOUND
    ) {
      if (turnaroundOperation.startOffsetMinutes < 0) {
        preArrival.push(turnaroundOperation);
        const duration = turnaroundOperation.duration || 0;
        const preArrivalAmt = turnaroundOperation.startOffsetMinutes * -1;
        if (preArrivalAmt > maxPreArrival) {
          maxPreArrival = preArrivalAmt;
        }
        const postArrivalAmt =
          turnaroundOperation.startOffsetMinutes + duration;
        if (postArrivalAmt > maxPostArrival) {
          maxPostArrival = postArrivalAmt;
        }
      } else {
        postArrival.push(turnaroundOperation);
        const duration = turnaroundOperation.duration || 0;
        const postArrivalAmt =
          turnaroundOperation.startOffsetMinutes + duration;
        if (postArrivalAmt > maxPostArrival) {
          maxPostArrival = postArrivalAmt;
        }
      }
    } else if (
      turnaroundOperation.enabled &&
      turnaroundOperation.offsetTypeId === OperationOffsetType.OUTBOUND
    ) {
      if (turnaroundOperation.startOffsetMinutes < 0) {
        preDeparture.push(turnaroundOperation);
        const duration = turnaroundOperation.duration || 0;
        const preDepartureAmt = turnaroundOperation.startOffsetMinutes * -1;
        if (preDepartureAmt > maxPreDeparture) {
          maxPreDeparture = preDepartureAmt;
        }
        const postDepartureAmt =
          turnaroundOperation.startOffsetMinutes + duration;
        if (postDepartureAmt > maxPostDeparture) {
          maxPostDeparture = postDepartureAmt;
        }
      } else {
        postDeparture.push(turnaroundOperation);
        const duration = turnaroundOperation.duration || 0;
        const postDepartureAmt =
          turnaroundOperation.startOffsetMinutes + duration;
        if (postDepartureAmt > maxPostDeparture) {
          maxPostDeparture = postDepartureAmt;
        }
      }
    }
  }
  sortByField(preArrival, "startOffsetMinutes", ["name"]);
  sortByField(postArrival, "startOffsetMinutes", ["name"]);
  sortByField(preDeparture, "startOffsetMinutes", ["name"]);
  sortByField(postDeparture, "startOffsetMinutes", ["name"]);

  let targetBlockTimeValue = !isNaN(parseInt(targetBlockTime))
    ? parseInt(targetBlockTime)
    : 0;
  if (targetBlockTimeValue > MAX_TIMELINE_DURATION_MINS) {
    targetBlockTimeValue = MAX_TIMELINE_DURATION_MINS;
  }
  const minInnerDuration = Math.max(
    maxPostArrival,
    maxPreDeparture,
    targetBlockTimeValue
  );
  const minOuterDuration = maxPreArrival + minInnerDuration + maxPostDeparture;

  const timelineOperations = [];

  for (let i = 0; i < preArrival.length; i++) {
    const operation = preArrival[i];
    const item = {
      operation: operation,
      timeFromAbsStart: maxPreArrival - Math.abs(operation.startOffsetMinutes),
    };
    timelineOperations.push(item);
  }
  for (let i = 0; i < postArrival.length; i++) {
    const operation = postArrival[i];
    const item = {
      operation: operation,
      timeFromAbsStart: maxPreArrival + operation.startOffsetMinutes,
    };
    timelineOperations.push(item);
  }
  for (let i = 0; i < preDeparture.length; i++) {
    const operation = preDeparture[i];
    const item = {
      operation: operation,
      timeFromAbsStart:
        maxPreArrival +
        minInnerDuration -
        Math.abs(operation.startOffsetMinutes),
    };
    timelineOperations.push(item);
  }
  for (let i = 0; i < postDeparture.length; i++) {
    const operation = postDeparture[i];
    const item = {
      operation: operation,
      timeFromAbsStart:
        maxPreArrival +
        minInnerDuration +
        Math.abs(operation.startOffsetMinutes),
    };
    timelineOperations.push(item);
  }

  return {
    minInnerDuration: minInnerDuration,
    minOuterDuration: minOuterDuration,
    maxPreArrival,
    maxPostArrival,
    maxPreDeparture,
    maxPostDeparture,
    preArrival: preArrival,
    postArrival: postArrival,
    preDeparture: preDeparture,
    postDeparture: postDeparture,
    operationsToRender: timelineOperations,
  };
}
