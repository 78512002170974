import { ReactComponent as TurnaroundCircle } from "../../assets/turnaround-circle.svg";
import { ReactComponent as TurnaroundCircleDark } from "../../assets/turnaround-circle-dark.svg";
import { ReactComponent as GroupCircle } from "../../assets/group-circle.svg";
import { ReactComponent as GroupCircleDark } from "../../assets/group-circle-dark.svg";
import { isNullOrUndefined } from "../../utils";
import CrewInfo from "../CrewInfo";
import { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import { useTranslation } from "react-i18next";

function ChannelInfo(props) {
  const { t } = useTranslation();
  const { channelInfo, isSelected } = props;

  return (
    <div className="channel-info">
      {channelInfo.isTurnaround && (
        <div className="channel-info-badge">
          <div>
            {!isSelected && <TurnaroundCircleDark />}
            {isSelected && <TurnaroundCircle />}
          </div>
          <div className="channel-info-badge-name">
            <div>{channelInfo.name}</div>
            <span>{t("turnaround_chat")}</span>
          </div>
        </div>
      )}
      {channelInfo.isGroup && (
        <div className="channel-info-badge">
          <div>
            {!isSelected && <GroupCircleDark />}
            {isSelected && <GroupCircle />}
          </div>
          <div className="channel-info-badge-name">
            <div>{channelInfo.name}</div>
          </div>
        </div>
      )}
      {channelInfo.isDirect &&
        !isNullOrUndefined(channelInfo.commsUserInfo) && (
          <CrewInfo
            user={channelInfo.commsUserInfo?.user}
            details={channelInfo.commsUserInfo?.user.userGroup}
            mapStatus={channelInfo.commsUserInfo?.mapStatus}
            userPicSize={UserPicSize.LARGE}
            userPicTheme={isSelected ? UserPicTheme.DEFAULT : UserPicTheme.DARK}
          />
        )}
    </div>
  );
}
export default ChannelInfo;
