import "./styles.css";
import { ReactComponent as RadioOn } from "../../assets/radio_on.svg";
import { ReactComponent as RadioOff } from "../../assets/radio_off.svg";
import { isNullOrUndefined } from "../../utils";

function Radio(props) {
  const { selected, onSelect } = props;

  return (
    <div
      className="radio-control"
      onClick={() => {
        if (!isNullOrUndefined(onSelect)) {
          onSelect(!selected);
        }
      }}
    >
      {selected && <RadioOn />}
      {!selected && <RadioOff />}
    </div>
  );
}
export default Radio;
