import "./styles.css";
import { selectInputFix } from "../../styleUtils";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants";

function Language() {
  const { t, i18n } = useTranslation();

  const languages = Object.keys(LANGUAGES);
  return (
    <div className="language">
      <div>
        <div className="settings-form-header">
          <h3>{t("choose_language")}</h3>
        </div>
        <div>
          <div className="form-container">
            <div className="field-row">
              <label>{t("language")}</label>

              <select
                value={i18n.language}
                onChange={(e) => {
                  i18n.changeLanguage(e.target.value);
                }}
                style={selectInputFix}
              >
                {languages &&
                  languages.map((lang) => (
                    <option value={lang} key={lang}>
                      {LANGUAGES[lang]}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language;
