import "./styles.css";
import { ReactComponent as AlertRed } from "../../assets/alert-small-red.svg";

export const BADGE_TYPE = {
  CRITICAL: "critical",
};

function NumberIconBadge(props) {
  const { type, value } = props;
  return (
    <div className={`number-icon-badge ${type}`}>
      <div>
        <div>
          {type === BADGE_TYPE.CRITICAL && <AlertRed width={16} height={16} />}
        </div>
        <div>{value}</div>
      </div>
    </div>
  );
}

export default NumberIconBadge;
