import { InfoType, getLanding, getStand, getTakeoff } from "./turnaroundUtils";
import moment from "moment-timezone";
import { getByFieldValue, isNullOrUndefined } from "./utils";
import { getDistanceInMiles } from "./mapUtils";

export const FLIGHT_INFO_STATUS = {
  NOT_DEPARTED: "not_departed",
  IN_FLIGHT: "in_air",
  LANDED: "landed",
};

export function getPositionByRegistration(positions, registration) {
  if (
    isNullOrUndefined(positions?.aircrafts) ||
    isNullOrUndefined(registration)
  )
    return null;
  return getByFieldValue(positions.aircrafts, "registration", registration);
}

// Use only with a full TurnaroundDetails
export function getFlightInfo(
  flight,
  baseAirportIata,
  position,
  airportTimezone
) {
  const flightInfo = {
    _infoType: InfoType.FLIGHT,
    uuid: flight?.uuid,
  };
  flightInfo.flight = flight;
  flightInfo.destinationAirport = flight?.destinationAirport?.iata;
  flightInfo.originAirport = flight?.originAirport?.iata;

  flightInfo.isInbound = flight?.destinationAirport?.iata === baseAirportIata;
  flightInfo.isOutbound = flight?.originAirport?.iata === baseAirportIata;
  flightInfo.airlineIata = flight?.airline?.iata;

  flightInfo.flightNumber = flight?.flightNumber;
  flightInfo.flightName = `${flightInfo.airlineIata} ${flightInfo.flightNumber}`;
  flightInfo.registration = flight?.aircraft?.registration;

  flightInfo.landing = getLanding(flight);
  flightInfo.takeoff = getTakeoff(flight);
  if (flightInfo.isInbound) {
    airportTimezone = flight?.destinationAirport?.timezone;
  } else if (flightInfo.isOutbound) {
    airportTimezone = flight?.originAirport?.timezone;
  }
  const mNow = moment().tz(airportTimezone);
  const mDepartedTime = moment(flightInfo.takeoff).tz(airportTimezone);
  const mArrivalTime = moment(flightInfo.landing).tz(airportTimezone);
  const mTakeOff = moment(flightInfo.takeoff).tz(airportTimezone);
  const mLanding = moment(flightInfo.landing).tz(airportTimezone);
  flightInfo.minsTotal =
    !isNullOrUndefined(mArrivalTime) && !isNullOrUndefined(mDepartedTime)
      ? parseInt(
          moment.duration(mArrivalTime.diff(mDepartedTime)).as("minutes")
        )
      : 0;

  if (mNow.isBefore(mTakeOff)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.NOT_DEPARTED;
    flightInfo.minsRemaining = flightInfo.minsTotal;
    flightInfo.flightProgress = 0;
    flightInfo.minsToDeparture = parseInt(
      moment.duration(mDepartedTime.diff(mNow)).as("minutes")
    );
  } else if (mNow.isAfter(mTakeOff) && mNow.isBefore(mLanding)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.IN_FLIGHT;
    flightInfo.minsRemaining = parseInt(
      moment.duration(mArrivalTime.diff(mNow)).as("minutes")
    );
    flightInfo.minsDeparted = parseInt(
      moment.duration(mNow.diff(mDepartedTime)).as("minutes")
    );
    flightInfo.flightProgress = Math.round(
      ((flightInfo.minsTotal - flightInfo.minsRemaining) /
        flightInfo.minsTotal) *
        100
    );
  } else if (mNow.isAfter(mLanding)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.LANDED;
    flightInfo.minsRemaining = 0;
    flightInfo.flightProgress = 100;
    flightInfo.minsLanded = parseInt(
      moment.duration(mNow.diff(mArrivalTime)).as("minutes")
    );
    flightInfo.minsDeparted = parseInt(
      moment.duration(mNow.diff(mDepartedTime)).as("minutes")
    );
  }
  // Basically replaces any need to calculate relative arrival/departure
  flightInfo.displayStatus = flight?.displayStatus;

  // Deprecated
  flightInfo.standId = flight?.stand?.uuid;
  flightInfo.stand = getStand(flight);

  if (flightInfo.isInbound && !isNullOrUndefined(flight?.arrivalStand)) {
    flightInfo.standDisplay = flight.arrivalStand.name;
  } else if (
    flightInfo.isOutbound &&
    !isNullOrUndefined(flight?.departureStand)
  ) {
    flightInfo.standDisplay = flight.departureStand.name;
  }

  flightInfo.position = position;
  const aircraftCoordinates = !isNullOrUndefined(position)
    ? [position.longitude, position.latitude]
    : null;
  const arrivalAirportCoordinates = !isNullOrUndefined(
    flight?.destinationAirport
  )
    ? getAirportCoordinates(flight.destinationAirport)
    : null;

  // These distances are raw distances not based on aircraft flight path
  flightInfo.distanceToArrival =
    !isNullOrUndefined(aircraftCoordinates) &&
    !isNullOrUndefined(arrivalAirportCoordinates)
      ? getDistanceInMiles(aircraftCoordinates, arrivalAirportCoordinates)
      : null;
  return flightInfo;
}

// NOTE: flight is refactored to turnaroundsSummary
export function getFlightInfoForTurnaroundSummary(
  flight,
  baseAirportIata,
  position,
  airportTimezone
) {
  const flightInfo = {
    _infoType: InfoType.FLIGHT,
    uuid: flight.uuid,
  };
  flightInfo.flight = flight;
  flightInfo.registration = flight.registration;

  // NOTE: for each summary pair only one flight is used
  const primaryFlightSummary =
    flight?.inboundFlightSummary?.progressPercent < 100 ||
    isNullOrUndefined(flight.outboundFlightSummary)
      ? flight.inboundFlightSummary
      : flight.outboundFlightSummary;

  flightInfo.primaryFlightDisplayStatus = primaryFlightSummary?.displayStatus;

  flightInfo.isInbound =
    primaryFlightSummary?.destAirportIata === baseAirportIata;
  flightInfo.isOutbound =
    primaryFlightSummary?.originAirportIata === baseAirportIata;

  flightInfo.destinationAirport = primaryFlightSummary?.destAirportIata;
  flightInfo.originAirport = primaryFlightSummary?.originAirportIata;

  flightInfo.airlineIata = primaryFlightSummary.airlineIata;

  flightInfo.flightNumber = primaryFlightSummary.flightNumber;
  flightInfo.flightName = `${primaryFlightSummary.airlineIata} ${primaryFlightSummary.flightNumber}`;

  flightInfo.landing = primaryFlightSummary.landingTime;
  flightInfo.takeoff = primaryFlightSummary.takeoffTime;
  flightInfo.airportTimezone = airportTimezone;

  const mNow = moment().tz(flightInfo.airportTimezone);
  const mDepartedTime = moment(flightInfo.takeoff).tz(
    flightInfo.airportTimezone
  );
  const mArrivalTime = moment(flightInfo.landing).tz(
    flightInfo.airportTimezone
  );
  const mTakeOff = moment(flightInfo.takeoff).tz(flightInfo.airportTimezone);
  const mLanding = moment(flightInfo.landing).tz(flightInfo.airportTimezone);
  flightInfo.minsTotal =
    !isNullOrUndefined(mArrivalTime) && !isNullOrUndefined(mDepartedTime)
      ? parseInt(
          moment.duration(mArrivalTime.diff(mDepartedTime)).as("minutes")
        )
      : 0;

  if (mNow.isBefore(mTakeOff)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.NOT_DEPARTED;
    flightInfo.minsRemaining = flightInfo.minsTotal;
    flightInfo.flightProgress = 0;
    flightInfo.minsToDeparture = parseInt(
      moment.duration(mDepartedTime.diff(mNow)).as("minutes")
    );
  } else if (mNow.isAfter(mTakeOff) && mNow.isBefore(mLanding)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.IN_FLIGHT;
    flightInfo.minsRemaining = parseInt(
      moment.duration(mArrivalTime.diff(mNow)).as("minutes")
    );
    flightInfo.minsDeparted = parseInt(
      moment.duration(mNow.diff(mDepartedTime)).as("minutes")
    );
    flightInfo.flightProgress = Math.round(
      ((flightInfo.minsTotal - flightInfo.minsRemaining) /
        flightInfo.minsTotal) *
        100
    );
  } else if (mNow.isAfter(mLanding)) {
    flightInfo.flightInfoStatus = FLIGHT_INFO_STATUS.LANDED;
    flightInfo.minsRemaining = 0;
    flightInfo.flightProgress = 100;
    flightInfo.minsLanded = parseInt(
      moment.duration(mNow.diff(mArrivalTime)).as("minutes")
    );
    flightInfo.minsDeparted = parseInt(
      moment.duration(mNow.diff(mDepartedTime)).as("minutes")
    );
  }

  flightInfo.snappedStand = flight.snappedStand;
  // Show the assigned stand
  flightInfo.standDisplay = primaryFlightSummary.assignedStandName;
  flightInfo.position = position;

  // NOTE: Distance is not a user-facing feature and not supported with turnaround summary
  /**
  const aircraftCoordinates = !isNullOrUndefined(position)
    ? [position.longitude, position.latitude]
    : null;
  const arrivalAirportCoordinates = !isNullOrUndefined(
    flight?.destinationAirport
  )
    ? getAirportCoordinates(flight.destinationAirport)
    : null;

  // These distances are raw distances not based on aircraft flight path
  flightInfo.distanceToArrival =
    !isNullOrUndefined(aircraftCoordinates) &&
    !isNullOrUndefined(arrivalAirportCoordinates)
      ? getDistanceInMiles(aircraftCoordinates, arrivalAirportCoordinates)
      : null;
      **/
  return flightInfo;
}

function getAirportCoordinates(airport) {
  if (isNullOrUndefined(airport)) return null;
  if (
    isNullOrUndefined(airport.westLongitude) ||
    isNullOrUndefined(airport.eastLongitude) ||
    isNullOrUndefined(airport.southLatitude) ||
    isNullOrUndefined(airport.northLatitude)
  )
    return null;
  return [
    (airport.westLongitude + airport.eastLongitude) / 2,
    (airport.northLatitude + airport.southLatitude) / 2,
  ];
}
