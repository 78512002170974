import { registerTheme } from "echarts";
import ReactECharts from "echarts-for-react";
import MoonwareTheme from "../../assets/themes/moonwareTheme.json";
import moment from "moment-timezone";
import {
  formatDate,
  formatDuration,
  formatHoursMins,
  isNullOrUndefined,
} from "../../utils";
import { useTranslation } from "react-i18next";

function TurnaroundPerformance(props) {
  const { t } = useTranslation();
  const { airportTimezone, chartData, startTime, endTime } = props;
  registerTheme("Moonware", MoonwareTheme.theme);
  const dateValues = Object.keys(chartData);
  dateValues.sort((a, b) => {
    const val1 = new Date(a);
    const val2 = new Date(b);
    if (val1 === val2) return 0;
    return val1 < val2 ? -1 : 1;
  });
  const buckets = [];
  if (
    !isNullOrUndefined(chartData.buckets) &&
    chartData.buckets.length > 0 &&
    !isNullOrUndefined(endTime)
  ) {
    const dateCounter = moment(startTime).tz(airportTimezone).startOf("day");
    const lastDate = endTime.startOf("day");
    const bucketsByDate = {};
    chartData.buckets.reduce((acc, value) => {
      acc[value.label] = value;
      return acc;
    }, bucketsByDate);
    while (dateCounter.isBefore(lastDate) || dateCounter.isSame(lastDate)) {
      const label = dateCounter.format("yyyy-MM-DD");
      const dataPoint = !isNullOrUndefined(bucketsByDate[label])
        ? bucketsByDate[label]
        : {
            label: label,
            averageGroundMins: 0,
            averageTotalMins: 0,
          };
      buckets.push(dataPoint);
      dateCounter.add(1, "day");
    }
  }
  const chartDateValues = [];
  const chartSeries1 = [];
  const chartSeries2 = [];
  let maxValue = 60;
  for (let i = 0; i < buckets.length; i++) {
    const bucket = buckets[i];
    chartDateValues.push(bucket.label);
    chartSeries1.push(bucket.averageGroundMins);
    chartSeries2.push(bucket.averageTotalMins);
    if (bucket.averageGroundMins > maxValue) {
      maxValue = bucket.averageGroundMins;
    }
    if (bucket.averageTotalMins > maxValue) {
      maxValue = bucket.averageTotalMins;
    }
  }
  maxValue = Math.floor(maxValue / 60) * 60 + 60; // make max an even 15min interval

  const yAxisInterval = maxValue / 60 > 12 ? Math.ceil(maxValue / 12) : 60;

  // Aggregate metrics
  const aggGroundAverage = chartData.averageGroundMins;
  const aggTotalAverage = chartData.averageTotalMins;

  const option = {
    axisPointer: {
      shadowStyle: {
        color: "rgba(23, 121, 218, 0.25)",
      },
      label: {
        backgroundColor: "rgba(23, 121, 218, 0.5)",
      },
    },
    tooltip: {
      borderColor: "#424959",
      backgroundColor: "#161721",
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "rgba(23, 121, 218, .75)",
        },
        label: {
          formatter: (params) => {
            let percentDiff = "";
            if (
              !isNullOrUndefined(params.seriesData) &&
              params.seriesData.length > 1
            ) {
              const val1 = parseFloat(params.seriesData[0].data);
              const val2 = parseFloat(params.seriesData[1].data);
              const percentOfTotal = val2 === 0 ? 0 : (val1 / val2) * 100;
              const diffPercent = val2 === 0 ? 0 : ((val1 - val2) / val2) * 100;
              percentDiff = `${percentOfTotal.toFixed(2)}% (${
                diffPercent > 0 ? "+" : ""
              }${diffPercent.toFixed(2)}%)`;
            }

            return typeof params.value === "string"
              ? `${formatDate(
                  moment(params.value).tz(airportTimezone).toDate(),
                  airportTimezone
                )}\n${percentDiff}`
              : formatHoursMins(params.value);
          },
        },
      },
      textStyle: {
        color: "#ffffff",
      },
    },
    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        magicType: { show: false, type: ["line", "bar"] },
        restore: { show: false },
        saveAsImage: { show: false },
      },
      right: "64px",
    },
    legend: {
      data: [t("ground"), t("total")],
      top: "4px",
      right: "96px",
      icon: "circle",
    },
    xAxis: [
      {
        type: "category",
        data: chartDateValues,
        axisPointer: {
          type: "shadow",
        },
        axisLabel: {
          formatter: (value) => {
            return `${formatDate(
              moment(value).tz(airportTimezone).toDate(),
              airportTimezone
            )}`;
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "",
        min: 0,
        max: maxValue,
        interval: yAxisInterval,
        axisLabel: {
          formatter: (value) => {
            return formatDuration(value * 60 * 1000);
          },
        },
        splitLine: {
          lineStyle: {
            color: "#858d9d",
          },
        },
      },
    ],
    series: [
      {
        name: t("ground"),
        type: "bar",
        barGap: "25%",
        barWidth: "8px",
        tooltip: {
          valueFormatter: function (value) {
            return formatHoursMins(value);
          },
        },
        data: chartSeries1,
        itemStyle: {
          borderRadius: 5,
          opacity: "0.75",
        },
        emphasis: {
          itemStyle: {
            opacity: "1.0",
          },
        },
        markLine: {
          data: [{ name: t("average"), yAxis: aggGroundAverage?.toFixed(2) }],
          symbol: "none",
          label: {
            show: false,
          },
          lineStyle: {
            color: "rgba(23, 121, 218, 0.25)",
          },
          emphasis: {
            disabled: true,
          },
        },
      },
      {
        name: t("total"),
        type: "bar",
        barGap: "25%",
        barWidth: "8px",
        tooltip: {
          valueFormatter: function (value) {
            return formatHoursMins(value);
          },
        },
        data: chartSeries2,
        itemStyle: {
          borderRadius: 4,
          opacity: "0.75",
        },
        emphasis: {
          itemStyle: {
            opacity: "1.0",
          },
        },
      },
    ],
  };
  return (
    <div className="chart-container">
      <div className="chart-title-bar">
        <h4>{t("ground_vs_total")}</h4>
        {chartDateValues.length !== 0 && (
          <div className="chart-title-metrics">
            <div className="primary">
              <label>{t("avg_ground")}</label>
              <h3>{`${formatHoursMins(aggGroundAverage)}`}</h3>
            </div>
            <div>
              <label>{t("avg_total")}</label>
              <h3>{`${formatHoursMins(aggTotalAverage)}`}</h3>
            </div>
          </div>
        )}
      </div>
      {chartDateValues.length === 0 && (
        <div className="empty-state">{t("no_results_for_selected_range")}</div>
      )}
      {chartDateValues.length > 0 && (
        <ReactECharts
          option={option}
          style={{ height: "480px", width: "100%" }}
          theme="Moonware"
        />
      )}
    </div>
  );
}

export default TurnaroundPerformance;
