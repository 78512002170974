import { useEffect } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getAllGseRecordsForTurnaround } from "../../gseUtils";
import { getPositionByTrackerCode, getVehicleInfoForMap } from "../../mapUtils";
import { isNullOrUndefined, sortByField } from "../../utils";
import VehicleInfoModule from "../Map/VehicleInfoModule";
import { useTranslation } from "react-i18next";

function LocatePanel(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { availableGsesToLocate } = mainContext;

  const {
    locateGseRequirement,
    vehicles,
    positions,
    turnarounds,
    selectedTurnaround,
    selectedVehicleUuid,
  } = props;

  useEffect(() => {
    const gsesForTurnaroundByUuid = {};
    const allGseRecordsForTurnaround = getAllGseRecordsForTurnaround(
      selectedTurnaround._source
    );
    if (!isNullOrUndefined(allGseRecordsForTurnaround)) {
      for (let i = 0; i < allGseRecordsForTurnaround.length; i++) {
        const gseRecordsForTurnaround = allGseRecordsForTurnaround[i];
        const groundVehicle = gseRecordsForTurnaround.groundVehicle;
        if (!isNullOrUndefined(groundVehicle)) {
          gsesForTurnaroundByUuid[groundVehicle.uuid] = groundVehicle;
        }
      }
    }
    const availableGses = [];
    if (!isNullOrUndefined(vehicles)) {
      for (let i = 0; i < vehicles.length; i++) {
        const vehicle = vehicles[i];
        const vehiclePosition = getPositionByTrackerCode(
          positions,
          vehicle.trackerCode
        );
        if (
          vehicle.gseType.uuid === locateGseRequirement.vehicleTypeUuid &&
          !(vehicle.uuid in gsesForTurnaroundByUuid) &&
          !isNullOrUndefined(vehiclePosition)
        ) {
          const vehicleInfo = getVehicleInfoForMap(
            vehicle,
            vehiclePosition,
            turnarounds
          );
          availableGses.push(vehicleInfo);
        }
      }
      sortByField(availableGses, "name");
      dispatch({
        type: "setAvailableGsesToLocate",
        value: availableGses,
      });
    }
  }, [
    dispatch,
    locateGseRequirement,
    vehicles,
    positions,
    turnarounds,
    selectedTurnaround,
  ]);

  return (
    <div>
      <div className="locator-panel-summary">
        <div>{t("locate_gse_text")}</div>
      </div>
      <div>
        <div className="context-panel-section-header">
          {locateGseRequirement.name}
        </div>
      </div>
      <div className="locator-panel-items">
        {availableGsesToLocate &&
          availableGsesToLocate.map((info) => (
            <VehicleInfoModule
              key={info.uuid}
              info={info.mapInfo}
              isLocateMode={true}
              isSelected={selectedVehicleUuid === info.uuid}
            />
          ))}
      </div>
    </div>
  );
}
export default LocatePanel;
