import "./styles.css";
import { ReactComponent as CheckboxEmpty } from "../../assets/checkbox/CheckboxEmpty.svg";
import { ReactComponent as CheckboxFilled } from "../../assets/checkbox/CheckboxFilled.svg";
import { ReactComponent as CheckboxSome } from "../../assets/checkbox/CheckboxSome.svg";
function Checkbox(props) {
  const { isChecked, isPartialChecked, isDisabled, changeHandler, value } =
    props;
  return (
    <div
      className={`checkbox-container${isDisabled ? " disabled" : ""}`}
      onClick={(e) => {
        if (!isDisabled) {
          changeHandler(value);
        }
        e.stopPropagation();
      }}
      data-testid="checkbox"
    >
      {isChecked && <CheckboxFilled />}
      {isPartialChecked && <CheckboxSome />}
      {!isChecked && !isPartialChecked && <CheckboxEmpty />}
    </div>
  );
}
export default Checkbox;
