import { useTranslation } from "react-i18next";
import { ReactComponent as AlertRed } from "../../assets/alert-small-red.svg";
import { isBlank } from "../../utils";

function CriticalMessages(props) {
  const { turnaroundAllocation } = props;
  const { t } = useTranslation();

  const numUnallocatedCrew = turnaroundAllocation?.numUnallocatedCrew || 0;

  const validationMessage =
    numUnallocatedCrew === 1
      ? t("crew_member_required_web", {
          value: turnaroundAllocation?.numUnallocatedCrew,
        })
      : numUnallocatedCrew > 1
      ? t("crew_members_required_web", {
          value: turnaroundAllocation?.numUnallocatedCrew,
        })
      : null;

  const isCritical = turnaroundAllocation?.isCritical;

  return (
    <div className="critical-messages">
      {!isCritical && <span>{t("fully_allocated")}</span>}
      {!isBlank(validationMessage) && (
        <CriticalMessage message={validationMessage} />
      )}
    </div>
  );
}

function CriticalMessage(props) {
  const { message } = props;
  return (
    <div className="critical-message">
      <div>
        <AlertRed width={18} height={18} />
      </div>
      <div>{message}</div>
    </div>
  );
}

export default CriticalMessages;
