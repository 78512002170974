import CaretDown from "./assets/caret-down.svg";
export const selectInputFix = {
  appearance: "none",
  backgroundImage: `url(${CaretDown})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 8px center",
  outline: "none",
  fontFamily: "Inter",
};

export const tooltipStyles = {
  tooltip: {
    sx: {
      cursor: "default",
      fontSize: "12px",
      fontFamily: "Inter",
      background: "rgba(0,0,0,0.75)",
      color: "#a3a9b6",
      "& .MuiTooltip-arrow": {
        color: "rgba(0,0,0,0.75)",
      },
    },
  },
};
export const tooltipSolidStyles = {
  tooltip: {
    sx: {
      cursor: "default",
      fontSize: "12px",
      fontFamily: "Inter",
      background: "rgba(0,0,0,0.90)",
      textAlign: "center",
      padding: "8px",
      color: "#a3a9b6",
      "& .MuiTooltip-arrow": {
        color: "rgba(0,0,0,0.90)",
      },
    },
  },
};
