import "./styles.css";
import { getByFieldValue, isNullOrUndefined } from "../../utils";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { useTranslation } from "react-i18next";
import SelectCrewMember from "../Dispatches/SelectCrewMember";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useEffect, useState } from "react";
import { getUsers, patchTurnaroundProfile } from "../../api";

function AllocationsModule(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { positions, users, turnarounds } = mainContext;

  const {
    selectedTurnaroundInfo,
    selectedOperation,
    selectedUserUuid,
    onClose,
    onAssignedUser,
  } = props;

  const [isSavingAssignment, setIsSavingAssignment] = useState(false);

  useEffect(() => {
    if (!isNullOrUndefined(selectedUserUuid)) {
      getUsers(dispatch);
    }
  }, [dispatch, selectedUserUuid]);

  // The selectedUserUuid is not really a userUuid since this is a slot that needs to be filled
  // It is a composite of the requirement UUID and an index
  const originalProfile = selectedTurnaroundInfo?.originalProfile;
  const operationToCheck = !isNullOrUndefined(originalProfile)
    ? getByFieldValue(
        originalProfile.turnaroundOperations,
        "uuid",
        selectedOperation?.uuid
      )
    : null;
  const requirementUuid = selectedUserUuid?.split("_")[0];
  const requirementToAssign =
    !isNullOrUndefined(operationToCheck?.turnaroundRequirements) &&
    !isNullOrUndefined(requirementUuid)
      ? getByFieldValue(
          operationToCheck.turnaroundRequirements,
          "uuid",
          requirementUuid
        )
      : null;
  const requirementName = requirementToAssign?.certification?.name;
  if (isNullOrUndefined(requirementToAssign)) return null;

  async function handleAssignUser(user) {
    if (isNullOrUndefined(user)) return;
    setIsSavingAssignment(true);
    // If there were other assignments then add this one
    const userUuids = !isNullOrUndefined(requirementToAssign.crewAssignments)
      ? requirementToAssign.crewAssignments.map((item) => item.userUuid)
      : [];
    userUuids.push(user.uuid);
    const patchRequest = {
      uuid: originalProfile.uuid,
      operations: [],
      requirements: [
        {
          userUuids: userUuids,
          uuid: requirementToAssign.uuid,
          quantity: requirementToAssign.quantity,
        },
      ],
      parentLabelUuidsToApply: [],
      labelUuidsToRemove: [],
    };
    const result = await patchTurnaroundProfile(dispatch, patchRequest);
    if (result) {
      if (!isNullOrUndefined(onAssignedUser)) {
        // Function to call when the user has been assigned
        onAssignedUser();
      }
      let dispatchMessage = `${t("saved_web", {
        name: selectedTurnaroundInfo.combinedFlightName,
      })}`;
      dispatch({
        type: "setAlertMessage",
        alertMessage: dispatchMessage,
      });
    }
    onClose();
    setIsSavingAssignment(false);
  }

  return (
    <div className="allocations-module">
      <div className="allocations-module-header">
        <div>
          <div className="allocations-module-header-title">{`${t(
            "assign"
          )} ${requirementName}`}</div>
          <div className="allocations-module-header-actions">
            <div className="close-btn" onClick={onClose}>
              <CloseBtn />
            </div>
          </div>
        </div>
      </div>
      <div className="allocations-module-body">
        <div className="allocations-module-content">
          <SelectCrewMember
            onSelect={(user) => {
              // Assign the user to this requirement
              handleAssignUser(user);
            }}
            onCancel={() => {
              // No-op
            }}
            certification={requirementToAssign?.certification}
            crewAssignments={requirementToAssign.crewAssignments}
            users={users}
            positions={positions}
            turnarounds={turnarounds}
            disabled={isSavingAssignment}
            isAssignMode={true}
          />
        </div>
      </div>
    </div>
  );
}
export default AllocationsModule;
