import "./styles.css";
import { ReactComponent as LogoSvg } from "../../assets/moonware_logo.svg";
import { ReactComponent as Eye } from "../../assets/eye.svg";
import { ReactComponent as EyeHidden } from "../../assets/eye-hidden.svg";
import {
  login,
  resetPassword,
  sendCode,
  signUp,
  verifySignUp,
} from "../../api";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  isBlank,
  isNullOrUndefined,
  isValidEmail,
  isValidPassword,
} from "../../utils";
import Checkbox from "../Checkbox";
import { useTranslation } from "react-i18next";

function Login() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, codeSent, passwordReset, signedUp, verifiedSignUp } =
    mainContext;
  const navigate = useNavigate();

  const [loginMode, setLoginMode] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordToggle, setPasswordToggle] = useState("password");
  const [terms, setTerms] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const confirmationCodeRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const passwordInputRef = useRef(null);

  const isLogin = loginMode === "login";
  const isSignup = loginMode === "signup";
  const isForgot = loginMode === "forgot";
  const isReset = loginMode === "reset";
  const isConfirmation = loginMode === "confirmation";

  useEffect(() => {
    if (isNullOrUndefined(currentUser)) return () => {};
    dispatch({
      type: "setAlertMessage",
      alertMessage: t("welcome_back", { name: currentUser.firstName }),
    });
    navigate("/dashboard");
  }, [dispatch, currentUser, navigate, t]);

  useEffect(() => {
    // when a code is sent, we want to switch to the change password mode
    if (codeSent) {
      setLoginMode("reset");
      dispatch({
        type: "setCodeSent",
        codeSent: false,
      });
    }
  }, [dispatch, codeSent]);

  useEffect(() => {
    // when the password is reset, we want to return to the login
    if (passwordReset) {
      setLoginMode("login");
      // clear the password so the user must re-enter it
      setPassword("");
      dispatch({
        type: "setPasswordReset",
        passwordReset: false,
      });
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("password_reset_msg"),
      });
    }
  }, [dispatch, passwordReset, t]);

  useEffect(() => {
    // when the user signs up, they must enter the confirmation code
    if (signedUp) {
      setLoginMode("confirmation");
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("verification_code_sent_msg"),
      });
    }
  }, [dispatch, signedUp, t]);

  useEffect(() => {
    // when the verification is confirmed, we want to return to the login
    if (verifiedSignUp) {
      setLoginMode("login");
      // clear the password so the user must re-enter it
      setPassword("");
      dispatch({
        type: "setVerifiedSignUp",
        verifiedSignUp: false,
      });
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("signup_complete_msg"),
      });
    }
  }, [dispatch, verifiedSignUp, t]);

  const handleLogin = () => {
    if (!isValid()) return;
    login(
      {
        email,
        password,
      },
      dispatch
    );
  };

  const handleSendCode = () => {
    if (!isValid()) return;
    sendCode(
      {
        email,
        password,
      },
      dispatch
    );
  };

  const handleSignup = (e) => {
    e.preventDefault();
    if (!isValid()) return;
    dispatch({
      type: "setSignedUp",
      signedUp: false,
    });
    signUp(
      {
        email,
        password,
      },
      dispatch
    );
  };

  const handleConfirmSignup = () => {
    if (!isValid()) return;
    verifySignUp(
      {
        email,
        confirmationCode: confirmationCode.join(""),
      },
      dispatch
    );
  };
  const handleResetPassword = () => {
    if (!isValid()) return;
    resetPassword(
      {
        email,
        newPassword: password,
        confirmationCode: confirmationCode.join(""),
      },
      dispatch
    );
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      if (isLogin) {
        handleLogin();
      } else if (isForgot) {
        handleSendCode();
      } else if (isReset) {
        handleResetPassword();
      }
    }
  };

  const handleToggleMode = (e, mode) => {
    e.preventDefault();
    setPassword(""); // clear the password when toggling modes
    setLoginMode(mode);
  };

  const isValid = () => {
    const emailValid = isValidEmail(email);
    // validate the format if resetting
    const passwordValid = isReset
      ? isValidPassword(password)
      : !isNullOrUndefined(password) && password.length >= 8;

    const confirmationCodeStr = confirmationCode.join("");
    const confirmationCodeValid =
      !isBlank(confirmationCodeStr) && confirmationCodeStr.length === 6;
    const hasAgreedToTerms = terms;
    if (isForgot) {
      return emailValid;
    }
    if (isReset) {
      return emailValid && passwordValid && confirmationCodeValid;
    }
    if (isSignup) {
      return emailValid && passwordValid && hasAgreedToTerms;
    }
    if (isConfirmation) {
      return emailValid && confirmationCodeValid;
    }
    return emailValid && passwordValid;
  };

  const togglePassword = () => {
    setPasswordToggle(passwordToggle === "password" ? "text" : "password");
  };

  let passwordPlaceholder = t("password_non_cap");
  if (isReset) {
    passwordPlaceholder = t("new_password");
  } else if (isSignup) {
    passwordPlaceholder = t("create_password");
  }

  return (
    <div className="login">
      <div className="login-left">
        <div className="login-splash-img"></div>
      </div>
      <div className="login-right">
        <div className="login-container">
          <div className="login-header"></div>
          <div className="login-body">
            <div className="login-form">
              <div className="login-form-header">
                <div>
                  <LogoSvg className="logo" />
                </div>
                {isLogin && <h2>{t("sign_in")}</h2>}
                {(isSignup || isConfirmation) && <h2>{t("sign_up")}</h2>}
                {(isForgot || isReset) && (
                  <h2>{t("forgot_password_non_cap")}</h2>
                )}
                {isForgot && <span>{t("please_enter_email")}</span>}
              </div>
              {(isLogin || isForgot || isSignup) && (
                <div className="login-form-row">
                  <div className="label-container">
                    <label>{t("work_email")}</label>
                  </div>
                  <input
                    type="text"
                    placeholder={t("work_email")}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyUp={handleKeyUp}
                    data-testid="email"
                  />
                </div>
              )}
              {(isReset || isConfirmation) && (
                <div className="login-form-row">
                  <div className="label-container no-cols">
                    <label>{t("please_enter_code")}</label>
                  </div>
                </div>
              )}
              {(isReset || isConfirmation) && (
                <>
                  <div className="login-confirmation-code">
                    <div>
                      <input
                        type="text"
                        size={1}
                        maxLength={1}
                        value={confirmationCode[0]}
                        ref={confirmationCodeRefs[0]}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          const val = [...confirmationCode];
                          val[0] = e.target.value;
                          setConfirmationCode(val);
                          confirmationCodeRefs[1].current.focus();
                        }}
                        data-testid="code-1"
                      />
                      <input
                        type="text"
                        size={1}
                        maxLength={1}
                        ref={confirmationCodeRefs[1]}
                        value={confirmationCode[1]}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          const val = [...confirmationCode];
                          val[1] = e.target.value;
                          setConfirmationCode(val);
                          confirmationCodeRefs[2].current.focus();
                        }}
                        data-testid="code-2"
                      />
                      <input
                        type="text"
                        size={1}
                        maxLength={1}
                        value={confirmationCode[2]}
                        ref={confirmationCodeRefs[2]}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          const val = [...confirmationCode];
                          val[2] = e.target.value;
                          setConfirmationCode(val);
                          confirmationCodeRefs[3].current.focus();
                        }}
                        data-testid="code-3"
                      />
                      <input
                        type="text"
                        size={1}
                        maxLength={1}
                        value={confirmationCode[3]}
                        ref={confirmationCodeRefs[3]}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          const val = [...confirmationCode];
                          val[3] = e.target.value;
                          setConfirmationCode(val);
                          confirmationCodeRefs[4].current.focus();
                        }}
                        data-testid="code-4"
                      />
                      <input
                        type="text"
                        size={1}
                        maxLength={1}
                        value={confirmationCode[4]}
                        ref={confirmationCodeRefs[4]}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          const val = [...confirmationCode];
                          val[4] = e.target.value;
                          setConfirmationCode(val);
                          confirmationCodeRefs[5].current.focus();
                        }}
                        data-testid="code-5"
                      />
                      <input
                        type="text"
                        size={1}
                        maxLength={1}
                        value={confirmationCode[5]}
                        ref={confirmationCodeRefs[5]}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          const val = [...confirmationCode];
                          val[5] = e.target.value;
                          setConfirmationCode(val);
                          if (isReset) passwordInputRef.current.focus();
                        }}
                        data-testid="code-6"
                      />
                    </div>
                  </div>
                  {isReset && (
                    <div className="login-form-row">
                      <div className="label-container">
                        <label>{t("then_new_pass")}</label>
                      </div>
                    </div>
                  )}
                </>
              )}
              {(isLogin || isReset || isSignup) && (
                <div className="login-form-row">
                  <div className="label-container">
                    <label>{t("password_non_cap")}</label>
                    {isLogin && (
                      <div className="label-container-right">
                        <a
                          href="/"
                          title={t("forgot_password_non_cap")}
                          tabIndex={-1}
                          onClick={(e) => {
                            handleToggleMode(e, "forgot");
                          }}
                        >
                          {t("forgot_password_non_cap")}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="password-input">
                    <input
                      type={passwordToggle}
                      ref={passwordInputRef}
                      placeholder={passwordPlaceholder}
                      value={password}
                      maxLength={99}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      onKeyUp={handleKeyUp}
                      data-testid="password"
                    />
                    <div
                      className="password-toggle"
                      title={
                        passwordToggle === "password"
                          ? t("show_password")
                          : t("hide_password")
                      }
                      onClick={togglePassword}
                    >
                      {passwordToggle === "password" ? <Eye /> : <EyeHidden />}
                    </div>
                  </div>

                  <div>
                    <span
                      className={`login-form-info${
                        password.length >= 8 &&
                        !isValidPassword(password) &&
                        (isReset || isSignup)
                          ? " show"
                          : ""
                      }`}
                    >
                      {t("password_requirements")}
                    </span>
                  </div>
                  {isSignup && (
                    <div className="terms-checkbox">
                      <div>
                        <Checkbox
                          isChecked={terms}
                          changeHandler={() => {
                            setTerms(!terms);
                          }}
                        />
                      </div>
                      <label
                        onClick={() => {
                          setTerms(!terms);
                        }}
                      >
                        {t("i_agree_to")}&nbsp;
                        <a
                          href="https://moonware.com/privacy/"
                          target="_blank"
                          rel="noreferrer"
                          title={t("terms_and_conditions")}
                        >
                          {t("terms_and_conditions")}
                        </a>
                      </label>
                    </div>
                  )}
                </div>
              )}
              <div className="login-form-row">
                {isLogin && (
                  <button
                    className="normal"
                    onClick={handleLogin}
                    disabled={!isValid()}
                    data-testid="signin-btn"
                  >
                    {t("sign_in")}
                  </button>
                )}
                {isForgot && (
                  <button
                    className="normal"
                    onClick={handleSendCode}
                    disabled={!isValid()}
                    data-testid="sendcode-btn"
                  >
                    {t("send_code")}
                  </button>
                )}
                {isReset && (
                  <button
                    className="normal"
                    onClick={handleResetPassword}
                    disabled={!isValid()}
                    data-testid="updatepwd-btn"
                  >
                    {t("update_pass")}
                  </button>
                )}
                {isSignup && (
                  <button
                    className="normal"
                    onClick={handleSignup}
                    disabled={!isValid()}
                    data-testid="signup-btn"
                  >
                    {t("sign_up")}
                  </button>
                )}
                {isConfirmation && (
                  <button
                    className="normal"
                    onClick={handleConfirmSignup}
                    disabled={!isValid()}
                  >
                    {t("confirm_non_cap")}
                  </button>
                )}
              </div>

              {(isForgot || isReset) && (
                <div className="login-form-row">
                  <div className="login-form-note">
                    {t("back_to")}&nbsp;
                    <a
                      href="/"
                      title={t("sign_in")}
                      tabIndex={-1}
                      onClick={(e) => {
                        handleToggleMode(e, "login");
                      }}
                    >
                      {t("sign_in")}
                    </a>
                  </div>
                </div>
              )}
              {isLogin && (
                <div className="login-form-row">
                  <div className="login-form-note">
                    {t("dont_have_account")}&nbsp;
                    <a
                      href="/"
                      title={t("sign_up")}
                      tabIndex={-1}
                      onClick={(e) => {
                        handleToggleMode(e, "signup");
                      }}
                    >
                      {t("sign_up")}
                    </a>
                  </div>
                </div>
              )}
              {isSignup && (
                <div className="login-form-row">
                  <div className="login-form-note">
                    {t("have_an_account")}&nbsp;
                    <a
                      href="/"
                      title={t("sign_in")}
                      tabIndex={-1}
                      onClick={(e) => {
                        handleToggleMode(e, "login");
                      }}
                    >
                      {t("sign_in")}
                    </a>
                  </div>
                </div>
              )}
              {isConfirmation && (
                <div className="login-form-row">
                  <div className="login-form-note">
                    {t("have_not_received")}&nbsp;
                    <a
                      href="/"
                      title={t("send_new_code")}
                      tabIndex={-1}
                      onClick={(e) => {
                        handleSignup(e);
                      }}
                    >
                      {t("send_new_code")}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
