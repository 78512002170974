import "./styles.css";
import * as React from "react";

import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { ReactComponent as Calendar } from "../../assets/calendar.svg";

function ButtonField(props) {
  const {
    setOpen,
    open,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
    label,
  } = props;

  return (
    <Button
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => {
        setOpen?.((prev) => !prev);
      }}
      startIcon={<Calendar />}
      sx={{
        bgcolor: open ? "var(--medium-bg-color)" : "transparent",
        color: "#FFF",
        "&:hover": {
          backgroundColor: open ? "var(--medium-bg-color)" : "transparent",
        },
        border: "1px solid #4A4C56",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "600",
        letterSpacing: "normal",
        textTransform: "none",
        textWrap: "nowrap",
        borderRadius: "8px",
        ".MuiButton-startIcon": {
          marginLeft: "0",
          marginRight: "4px",
        },
      }}
    >
      {label}
    </Button>
  );
}

export default function ButtonDatePicker(props) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{
        field: { setOpen, open },
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}
