import "./styles.css";
import { useEffect } from "react";

import { useMainContext, useMainDispatchContext } from "../../MainContext";
import MainMenu from "../MainMenu";
import UserBadge from "../UserBadge";
import Crew from "../Crew";
import Dashboard from "../Dashboard";
import Vehicles from "../Vehicles";
import Alerts from "../Alerts";
import { getDynamicEnv, getUser, logout } from "../../api";
import { isBlank, isNullOrUndefined } from "../../utils";
import Flights from "../Flights";
import Turnarounds from "../Turnarounds";
import Performance from "../Performance";
import {
  ResourceActions,
  userHasAnyResourceAction,
  userHasResourceAction,
} from "../../resourceActionsUtils";
import Utilization from "../Utilization";
import Settings from "../Settings";
import SectionToolbar from "../SectionToolbar";
import Map from "../Map";
import { CookieManager } from "../../cookies";
import Dispatches from "../Dispatches";
import Templates from "../Templates";
import Leaderboard from "../Leaderboard";
import GateSchedule from "../GateSchedule";
import ActionBanner from "../ActionBanner";
import ConsoleDataHelper from "./ConsoleDataHelper";
import MoveAircraftReminder from "../MoveAircraftReminder";
import Chat from "../Chat";

function MainConsole(props) {
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { currentUser, dynamicEnv, showAircraftTypeSelector } = mainContext;
  const { section } = props;

  useEffect(() => {
    getDynamicEnv(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // check if we can refresh the login user
    if (isNullOrUndefined(currentUser)) {
      // const accessToken = localStorage.getItem("accessToken");
      const accessToken = CookieManager.getCookieValue("accessToken");
      if (!isBlank(accessToken)) {
        getUser(dispatch);
        return () => {};
      }
      logout();
    }
  }, [currentUser, dispatch]);

  const hasEditAdminUsers = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminUsers
  );
  const hasEditAdminGse = userHasResourceAction(
    currentUser,
    ResourceActions.EditAdminGse
  );
  const hasViewOnTimePerformance = userHasResourceAction(
    currentUser,
    ResourceActions.ViewOnTimePerformance
  );
  const hasViewLeaderboard = userHasResourceAction(
    currentUser,
    ResourceActions.ViewUserLeaderboard
  );
  const hasViewGseUtilization = userHasResourceAction(
    currentUser,
    ResourceActions.ViewGseUtilization
  );
  const hasEditTurnProfile = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditTurnProfile
  );
  const hasViewTurnTemplate = userHasAnyResourceAction(
    currentUser,
    ResourceActions.ViewTurnaroundTemplate
  );
  const hasEditGateSchedule = userHasAnyResourceAction(
    currentUser,
    ResourceActions.EditStandAssignment
  );

  const showMap = !isNullOrUndefined(dynamicEnv?.mapBoxToken);
  const showDispatch = hasEditTurnProfile;
  const showTurnaroundTemplates = hasEditTurnProfile || hasViewTurnTemplate;
  return (
    <div className="main-console">
      <div className="main-menu">
        <MainMenu
          showAdminUsers={hasEditAdminUsers}
          showAdminGses={hasEditAdminGse}
          showLeaderboard={hasViewLeaderboard}
          showPerformance={hasViewOnTimePerformance}
          showUtilization={hasViewGseUtilization}
          showDispatch={showDispatch}
          showTurnaroundTemplates={showTurnaroundTemplates}
          showGateSchedule={hasEditGateSchedule}
        />
      </div>
      <div className="main-container">
        <div className="main-header">
          <div className="header-left">
            {section === "turnarounds" && <SectionToolbar />}
            {section === "flights" && <SectionToolbar />}
            {section === "users" && <SectionToolbar />}
            {section === "vehicles" && <SectionToolbar />}
            {section === "map" && <SectionToolbar />}
            {section === "templates" && showAircraftTypeSelector && (
              <SectionToolbar />
            )}
          </div>
          <div className="header-center">
            <ActionBanner />
          </div>
          <div className="header-right">
            <UserBadge
              user={currentUser}
              menuEnabled={true}
              secondaryInfoField="email"
            />
          </div>
        </div>
        <div className="main-content-box">
          {section === "dashboard" && <Dashboard />}
          {section === "flights" && <Flights />}
          {section === "map" && showMap && <Map />}
          {section === "chat" && <Chat />}
          {section === "turnarounds" && <Turnarounds />}
          {section === "allocations" && <Dispatches />}
          {section === "users" && hasEditAdminUsers && <Crew />}
          {section === "vehicles" && hasEditAdminGse && <Vehicles />}
          {section === "templates" && showTurnaroundTemplates && <Templates />}
          {section === "stand-assignments" && hasEditGateSchedule && (
            <GateSchedule />
          )}
          {section === "alerts" && <Alerts />}
          {section === "settings" && <Settings />}
          {section === "performance" && hasViewOnTimePerformance && (
            <Performance />
          )}
          {section === "utilization" && hasViewGseUtilization && (
            <Utilization />
          )}
          {section === "leaderboard" && hasViewLeaderboard && <Leaderboard />}
        </div>
      </div>
      <ConsoleDataHelper />
      {hasEditGateSchedule && <MoveAircraftReminder />}
    </div>
  );
}

export default MainConsole;
