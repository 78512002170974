import { useReducer } from "react";
import { CommsContext, CommsDispatchContext } from "./CommsContext";
import { deepCopy, getByFieldValue, isNullOrUndefined } from "./utils";

export const initialState = {
  selectedAttachment: null,
  channels: [],
  channelsLoading: false,
  commsUsers: [],
  commsUsersLoading: false,
  positions: {},
  positionsSaving: false,
  commsChannelPost: null,
  commsChannelAttachments: [],
  commsChannelAttachmentsLoading: false,
  commsAttachmentUpload: null,
  commsAttachmentUploadLoading: false,
  commsChannelRead: null,
  commsChannelReadSaving: false,
  commsChannelMessagePostSaving: false,
};

export function commsReducer(state, action) {
  switch (action.type) {
    case "setChannels":
      if (state.commsChannelMessagePostSaving) {
        // ignore
        return {
          ...state,
        };
      }
      return {
        ...state,
        channels: action.value,
      };
    case "setChannelsLoading":
      return {
        ...state,
        channelsLoading: action.value,
      };
    case "setCommsUsers":
      return {
        ...state,
        commsUsers: action.value,
      };
    case "setCommsUsersLoading":
      return {
        ...state,
        commsUsersLoading: action.value,
      };
    case "setPositions":
      return {
        ...state,
        positions: action.value,
      };
    case "setPositionsSaving":
      return {
        ...state,
        positionsSaving: action.value,
      };
    case "setCommsChannelPost":
      return {
        ...state,
        commsChannelPost: action.value,
      };
    case "setCommsAttachmentUpload":
      return {
        ...state,
        commsAttachmentUpload: action.value,
      };
    case "setCommsAttachmentUploadLoading":
      return {
        ...state,
        commsAttachmentUploadLoading: action.value,
      };
    case "setCommsChannelAttachments":
      const updatedState = deepCopy(state.commsChannelAttachments);
      if (!isNullOrUndefined(action.value)) {
        for (let i = 0; i < action.value.length; i++) {
          const val = action.value[i];
          const existing = updatedState.find((item) => item.uuid === val.uuid);
          if (isNullOrUndefined(existing)) {
            updatedState.push(val);
          }
        }
      }
      return {
        ...state,
        commsChannelAttachments: updatedState,
      };
    case "setCommsChannelAttachmentsLoading":
      return {
        ...state,
        commsChannelAttachmentsLoading: action.value,
      };
    case "setCommsChannelRead":
      return {
        ...state,
        commsChannelRead: action.value,
      };
    case "setCommsChannelReadSaving":
      return {
        ...state,
        commsChannelReadSaving: action.value,
      };
    case "setSelectedAttachment":
      return {
        ...state,
        selectedAttachment: action.value,
      };
    case "setTempChannelMessage": {
      const date = new Date();

      const message = {
        id: `temp-message-${date.getTime()}`,
        content: {
          text: action.value,
          attachments: [],
        },
        sentBy: action.sentBy,
        sentAt: date.toISOString(),
        isTemp: true,
      };

      const updatedChannels = deepCopy(state.channels);
      appendMessageToChannel(updatedChannels, action.channelId, message);

      return {
        ...state,
        channels: updatedChannels,
      };
    }
    case "setCommsChannelMessagePostSaving":
      return {
        ...state,
        commsChannelMessagePostSaving: action.value,
      };
    case "setGlobalError":
    case "setAnalyticsEvent":
    case "setCommsChannelPostSaving":
    case "setCommsChannelPutSaving":
    case "setCurrentUser":
      // no-op
      return {
        ...state,
      };

    default:
      console.log(`Unknown action: ${action.type}`);
      //throw Error(`Unknown action: ${action.type}`);
      return {
        ...state,
      };
  }
}

export default function CommsProvider({ children }) {
  const [main, dispatch] = useReducer(commsReducer, initialState);
  return (
    <CommsContext.Provider value={main}>
      <CommsDispatchContext.Provider value={dispatch}>
        {children}
      </CommsDispatchContext.Provider>
    </CommsContext.Provider>
  );
}

function appendMessageToChannel(channelGroups, channelId, message) {
  if (isNullOrUndefined(channelGroups)) return;
  for (let i = 0; i < channelGroups.length; i++) {
    const channelGroup = channelGroups[i];
    const channels = channelGroup.channels;
    for (let j = 0; j < channels.length; j++) {
      const channel = channels[j];
      if (channel.id === channelId) {
        channel.messages.push(message);
        const member = getByFieldValue(
          channel.members,
          "userUuid",
          message.sentBy
        );
        if (!isNullOrUndefined(member)) {
          member.lastRead = message.sentAt;
        }
      }
    }
  }
}
