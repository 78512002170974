import { useTranslation } from "react-i18next";
import "./styles.css";
import { MAPINFO_SUB_FILTER } from "../../mapUtils";
import {
  getTurnaroundInfoForTurnaroundSummary,
  hasCriticalTurnarounds,
} from "../../turnaroundUtils";
function ButtonSwitch(props) {
  const { t } = useTranslation();

  const {
    selectedOption,
    onChange,
    labelValuePairs,
    wideMode,
    lightMode,
    disabled,
    mapFilters,
    turnarounds,
  } = props;

  const firstOptionEnabled = selectedOption === labelValuePairs[0].value;
  const turnaroundInfoList = turnarounds?.map((item) =>
    getTurnaroundInfoForTurnaroundSummary(item)
  );
  const hasCritical = hasCriticalTurnarounds(turnaroundInfoList);
  const showSubvaluesForCritical = firstOptionEnabled && hasCritical;

  return (
    <div
      className={`button-switch${disabled ? " disabled" : ""}${
        wideMode ? " wide" : ""
      }${lightMode ? " light" : ""}`}
    >
      <div className="button-switch-box">
        {labelValuePairs &&
          labelValuePairs.map((labelValuePair, idx) => (
            <div
              key={idx}
              className={`button-switch-option ${
                selectedOption === labelValuePair.value ? "selected" : ""
              }${idx === 0 && showSubvaluesForCritical ? " subvalues" : ""}`}
              onClick={() => {
                if (disabled) return;
                if (idx === 0 && firstOptionEnabled) return;
                onChange(labelValuePair.value);
              }}
            >
              <span>{t(labelValuePair.value)}</span>
              {idx === 0 && showSubvaluesForCritical && (
                <div className="button-switch-subvalues">
                  <div>
                    <div
                      className={`button-switch-subvalue critical${
                        mapFilters.turnaroundSubFilter ===
                        MAPINFO_SUB_FILTER.CRITICAL
                          ? " selected"
                          : ""
                      }`}
                      onClick={() => {
                        if (disabled) return;
                        onChange(
                          labelValuePair.value,
                          MAPINFO_SUB_FILTER.CRITICAL
                        );
                      }}
                    >
                      <div>{t("critical")}</div>
                    </div>
                    <div
                      className={`button-switch-subvalue active${
                        mapFilters.turnaroundSubFilter ===
                        MAPINFO_SUB_FILTER.ACTIVE
                          ? " selected"
                          : ""
                      }`}
                      onClick={() => {
                        if (disabled) return;
                        onChange(
                          labelValuePair.value,
                          MAPINFO_SUB_FILTER.ACTIVE
                        );
                      }}
                    >
                      <div>{t("active")}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
export default ButtonSwitch;
