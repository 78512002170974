import "./styles.css";
import { useEffect, useState } from "react";
import { isNullOrUndefined } from "../../utils";
import PageToolbar from "../PageToolbar";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import DataTable, { ColumnNames } from "../DataTable";
import { getFlights } from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import {
  ANALYTICS_EVENTS,
  DEFAULT_TIMEZONE,
  SEARCH_QUERY_PLACEHOLDERS,
} from "../../constants";
import { useTranslation } from "react-i18next";

function Flights() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const [selectedFlight] = useState(null);
  const { flights, flightsLoading, currentUser, searchQuery } = mainContext;
  const [flightList, setFlightList] = useState([]);

  useEffect(() => {
    setFlightList([]);
    getFlights(dispatch);
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_FLIGHTS,
      },
    });
    dispatch({
      type: "setAnalyticsEvent",
      value: {
        eventName: ANALYTICS_EVENTS.VIEWED_FULL_FLIGHT_LIST,
      },
    });
  }, [dispatch]);

  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  useEffect(() => {
    // Need to process the flight data for presentation
    const flightsData = !isNullOrUndefined(flights)
      ? flights.map((flightData) => {
          const { airline, originAirport, destinationAirport } = flightData;
          // TODO confirm this, these are our interpretations of the flight aware fields for display here
          return {
            flightName: `${airline.iata} ${flightData.flightNumber}`,
            flightStatus: flightData.displayStatus || "ON TIME",
            airportTimezone: airportTimezone,
            origin: originAirport.iata,
            destination: destinationAirport.iata,
            aircraftType: flightData?.aircraft?.aircraftVariant?.displayName,
            departureTerminal: flightData.scheduledDepartureTerminal,
            departureGate: flightData.scheduledDepartureGate,
            departureTime: flightData.filedDepartureTime,
            arrivalTerminal: flightData.scheduledArrivalTerminal,
            arrivalGate: flightData.scheduledArrivalGate,
            arrivalTime: flightData.filedArrivalTime,
          };
        })
      : null;
    setFlightList(flightsData);
  }, [flights, airportTimezone]);

  return (
    <div className="flights">
      <div className="flights-container">
        <PageToolbar title={t("flights")} />
        {flightsLoading && <LoadingIndicator />}
        {!flightsLoading && (
          <DataTable
            dataList={flightList}
            selectedUuid={
              !isNullOrUndefined(selectedFlight) ? selectedFlight.uuid : null
            }
            searchQuery={searchQuery}
            columnNames={[
              ColumnNames.FlightName,
              ColumnNames.FlightStatus,
              ColumnNames.Origin,
              ColumnNames.Destination,
              ColumnNames.Aircraft,
              ColumnNames.DepartureTerminal,
              ColumnNames.DepartureGate,
              ColumnNames.DepartureTime,
              ColumnNames.ArrivalTerminal,
              ColumnNames.ArrivalGate,
              ColumnNames.ArrivalTime,
            ]}
          />
        )}
      </div>
    </div>
  );
}
export default Flights;
