import { useEffect } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { isNullOrUndefined } from "../../utils";
import { useTranslation } from "react-i18next";

function MoveAircraftReminder() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    turnaroundsSummary: turnaroundsForDispatch,
    currentUser,
    moveAircraftReminder,
  } = mainContext;

  useEffect(() => {
    if (
      isNullOrUndefined(currentUser) ||
      isNullOrUndefined(turnaroundsForDispatch) ||
      turnaroundsForDispatch.length === 0
    )
      return () => {};

    // Refactored to use turnaroundSummary
    const turnaroundsToMove = turnaroundsForDispatch.filter(
      (turnaroundToCheck) => {
        return (
          turnaroundToCheck.needsRepositionToHardStand ||
          turnaroundToCheck.needsRepositionToDepartureStand
        );
      }
    );
    const turnaroundToMove =
      turnaroundsToMove?.length > 0 ? turnaroundsToMove[0] : null;
    if (!isNullOrUndefined(turnaroundToMove)) {
      // Set a reminder if there is a turnaround that needs to be repositioned
      dispatch({
        type: "setMoveAircraftReminder",
        value: turnaroundToMove,
      });
    } else if (!isNullOrUndefined(moveAircraftReminder)) {
      // Clear the reminder if no turnarounds need to be repositioned
      dispatch({
        type: "setMoveAircraftReminder",
        value: null,
      });
    }
  }, [dispatch, turnaroundsForDispatch, moveAircraftReminder, currentUser, t]);

  return <></>;
}
export default MoveAircraftReminder;
