import "./styles.css";
import moment from "moment-timezone";
import TurnaroundPerformance from "./TurnaroundPerformance";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useMemo, useEffect, useState } from "react";
import { deepCopy, isBlank, isNullOrUndefined, sortByField } from "../../utils";
import { DATE_GRANULARITY, DEFAULT_TIMEZONE, MIN_DATE } from "../../constants";
import {
  getOnTimePerformanceExport,
  getTurnaroundTemplates,
  getTurnaroundsFlightNumberPairs,
  getTurnaroundsPerformance,
} from "../../api";
import LoadingIndicator from "../LoadingIndicator";
import OperationOnTimePerformance from "./TurnaroundOperationPerformance";
import RangePicker from "../ButtonDatePicker/RangePicker";
import MetricsFilter from "../MetricsFilter";
import TurnaroundOperationsTrend from "./TurnaroundOperationsTrend";
import Checkbox from "../Checkbox";
import CheckboxFilter from "../CheckboxFilter";
import DataExport from "../DataExport";
import { useTranslation } from "react-i18next";

function Performance() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    currentUser,
    turnaroundsPerformance,
    turnaroundsPerformanceSaving,
    turnaroundsFlightNumberPairs,
    turnaroundsFlightNumberPairsLoading,
    turnaroundTemplates,
    turnaroundTemplateLoading,
  } = mainContext;
  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const mMinDate = moment(MIN_DATE).tz(airportTimezone).startOf("day");
  const mToday = moment().tz(airportTimezone).startOf("minute");
  const mThirtyDaysAgo = moment(mToday).add(-30, "day").tz(airportTimezone);
  const [selectedStartDate, setSelectedStartDate] = useState(mThirtyDaysAgo);
  const [selectedEndDate, setSelectedEndDate] = useState(mToday);
  const [selectedTurnaroundPairs, setSelectedTurnaroundPairs] = useState([]);
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [includeMissingData, setIncludeMissingData] = useState(false);

  function handleSelectTurnaroundPair(updatedValues) {
    const updatedPairs = updatedValues.map((id) => {
      return turnaroundsById[id];
    });
    setSelectedTurnaroundPairs(updatedPairs);
  }

  function makeTurnaroundId(pair) {
    return `${pair.airlineIata}-${pair.inboundFlightNumber}-${pair.outboundFlightNumber}`;
  }

  function makeTurnaroundName(pair) {
    return `${pair.airlineIata} ${pair.inboundFlightNumber} - ${pair.airlineIata} ${pair.outboundFlightNumber}`;
  }

  // Turnaround pairs that can be used for filterings
  const [turnaroundsById, turnaroundsList] = useMemo(() => {
    const valuesList = [];
    const valuesMap = [];
    if (!isNullOrUndefined(turnaroundsFlightNumberPairs)) {
      for (let i = 0; i < turnaroundsFlightNumberPairs.length; i++) {
        const pair = turnaroundsFlightNumberPairs[i];
        if (!isBlank(pair.outboundFlightNumber)) {
          const turnaroundId = makeTurnaroundId(pair);
          const turnaroundName = makeTurnaroundName(pair);
          valuesList.push({
            turnaroundId,
            turnaroundName,
            ...pair,
          });
          valuesMap[turnaroundId] = pair;
        }
      }
      sortByField(valuesList, "turnaroundName");
    }
    return [valuesMap, valuesList];
  }, [turnaroundsFlightNumberPairs]);

  // Operations list is the unique set of operation names across all templates
  const [operationsByName, operationsList] = useMemo(() => {
    const valuesMap = {};
    const valuesList = [];
    if (!isNullOrUndefined(turnaroundTemplates)) {
      for (let i = 0; i < turnaroundTemplates.length; i++) {
        const template = turnaroundTemplates[i];
        const operations = template?.turnaroundOperations;
        for (let j = 0; j < operations.length; j++) {
          const operation = operations[j];
          const operationName = operation.name;
          if (isNullOrUndefined(valuesMap[operationName])) {
            valuesMap[operationName] = operation;
            valuesList.push({
              key: operationName,
              name: operationName,
            });
          }
        }
      }
      sortByField(valuesList, "name");
    }
    return [valuesMap, valuesList];
  }, [turnaroundTemplates]);

  useEffect(() => {
    getTurnaroundTemplates(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const criteria = {
      startTime: moment(selectedStartDate).startOf("day").format(),
      endTime: moment(selectedEndDate).endOf("day").format(),
    };
    getTurnaroundsFlightNumberPairs(dispatch, criteria);
  }, [dispatch, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (
      // isNullOrUndefined(selectedOperations) ||
      operationsByName.length === 0 ||
      turnaroundsFlightNumberPairsLoading ||
      turnaroundTemplateLoading
    )
      return () => {};
    const criteria = {
      startTime: moment(selectedStartDate).startOf("day").format(),
      endTime: moment(selectedEndDate).endOf("day").format(),
      dateGranularityId: DATE_GRANULARITY.DAY,
    };

    if (selectedTurnaroundPairs.length > 0) {
      criteria.flightPairs = selectedTurnaroundPairs;
    }
    criteria.includeMissingOps = includeMissingData;
    getTurnaroundsPerformance(dispatch, criteria);
  }, [
    dispatch,
    selectedStartDate,
    selectedEndDate,
    selectedTurnaroundPairs,
    // selectedOperations,
    operationsByName,
    turnaroundsFlightNumberPairsLoading,
    turnaroundTemplateLoading,
    includeMissingData,
  ]);

  const handleChangeDateRange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const selectedTurnaroundFilters = selectedTurnaroundPairs.map((pair) =>
    makeTurnaroundId(pair)
  );

  function handleSidePanelSelectedOperations(value) {
    const updatedOperations = deepCopy(selectedOperations);
    const idx = updatedOperations.indexOf(value);
    if (idx > -1) {
      updatedOperations.splice(idx, 1);
    } else if (value === MetricsFilter.ALL_METRICS_FILTER_VALUE) {
      // If all, remove everything else
      updatedOperations.splice(0);
    } else {
      updatedOperations.push(value);
    }
    setSelectedOperations(updatedOperations);
  }

  const tilesWideLayout = selectedOperations.length === 1;
  const showTilesSort =
    selectedOperations.length === 0 || selectedOperations.length > 1;

  function handleDataExport() {
    const criteria = {
      startTime: moment(selectedStartDate).startOf("day").format(),
      endTime: moment(selectedEndDate).endOf("day").format(),
      dateGranularityId: DATE_GRANULARITY.DAY,
    };

    if (selectedTurnaroundPairs.length > 0) {
      criteria.flightPairs = selectedTurnaroundPairs;
    }
    criteria.includeMissingOps = includeMissingData;
    getOnTimePerformanceExport(dispatch, criteria);
  }
  return (
    <div className="performance">
      <div className="performance-container">
        <div className="performance-toolbar">
          <div>
            <h2>{t("on_time_performance")}</h2>
          </div>
          <div></div>
          <div className="metrics-filters">
            <div>
              <div>
                <CheckboxFilter
                  changeHandler={() => {
                    setIncludeMissingData(!includeMissingData);
                  }}
                  isChecked={includeMissingData}
                  label={t("show_missing_data")}
                />
              </div>
              <div>
                <MetricsFilter
                  iconName={"turnarounds"}
                  filterName={t("turnarounds")}
                  filterItems={turnaroundsList}
                  selectedFilters={selectedTurnaroundFilters}
                  changeHandler={(value) => {
                    handleSelectTurnaroundPair(value);
                  }}
                  filterItemIdField="turnaroundId"
                  filterItemNameField="turnaroundName"
                  menuPosition="right"
                />
              </div>
              <div>
                <RangePicker
                  today={mToday}
                  airportTimezone={airportTimezone}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  minDate={mMinDate}
                  maxDate={mToday}
                  maxRange={35}
                  onChange={(startValue, endValue) => {
                    handleChangeDateRange(startValue, endValue);
                  }}
                />
              </div>
              <DataExport handleDataExport={handleDataExport} />
            </div>
          </div>
        </div>

        <div className="charts-container">
          <div className="charts-side-panel-content">
            <div className="charts-side-panel">
              <div className="charts-side-panel-container">
                <div className="charts-side-panel-module">
                  <div
                    className={`charts-side-panel-item${
                      selectedOperations.length === 0 ? " selected" : ""
                    }`}
                    onClick={() => {
                      handleSidePanelSelectedOperations(
                        MetricsFilter.ALL_METRICS_FILTER_VALUE
                      );
                    }}
                  >
                    <div>{t("all_operations")}</div>
                    <div>
                      <Checkbox
                        isChecked={selectedOperations.length === 0}
                        changeHandler={() => {
                          handleSidePanelSelectedOperations(
                            MetricsFilter.ALL_METRICS_FILTER_VALUE
                          );
                        }}
                      />
                    </div>
                  </div>
                  {operationsList &&
                    operationsList.map((operation) => (
                      <div
                        className={`charts-side-panel-item${
                          selectedOperations.includes(operation.name)
                            ? " selected"
                            : ""
                        }`}
                        key={operation.key}
                        onClick={() => {
                          handleSidePanelSelectedOperations(operation.key);
                        }}
                      >
                        <div>{operation.name}</div>
                        <div>
                          <Checkbox
                            isChecked={selectedOperations.includes(
                              operation.name
                            )}
                            changeHandler={() => {
                              handleSidePanelSelectedOperations(operation.key);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="charts-content">
            <div className="chart-module mainchart">
              {turnaroundsPerformanceSaving && <LoadingIndicator />}
              {!turnaroundsPerformanceSaving && (
                <div>
                  <TurnaroundOperationsTrend
                    airportTimezone={airportTimezone}
                    chartData={turnaroundsPerformance}
                    startTime={selectedStartDate}
                    endTime={selectedEndDate}
                    selectedOperations={selectedOperations}
                  />
                </div>
              )}
            </div>
            {!turnaroundsPerformanceSaving && (
              <div className="chart-module transparent">
                <OperationOnTimePerformance
                  chartData={turnaroundsPerformance}
                  selectedOperations={selectedOperations}
                  wideLayout={tilesWideLayout}
                  showSort={showTilesSort}
                  operationsList={operationsList}
                  includeMissingData={includeMissingData}
                />
              </div>
            )}
            {selectedOperations.length === 0 && (
              <div className="chart-module mainchart">
                {turnaroundsPerformanceSaving && <LoadingIndicator />}
                {!turnaroundsPerformanceSaving && (
                  <div>
                    <TurnaroundPerformance
                      airportTimezone={airportTimezone}
                      chartData={turnaroundsPerformance}
                      startTime={selectedStartDate}
                      endTime={selectedEndDate}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Performance;
