import { useEffect, useCallback } from "react";
import { isNullOrUndefined } from "../../utils";
function Keys(props) {
  const { ctrlKeyActions } = props;
  const handleKeyPress = useCallback(
    (event) => {
      if (event.ctrlKey === true) {
        const action = ctrlKeyActions[event.key];
        if (!isNullOrUndefined(action)) {
          action();
        }
      }
    },
    [ctrlKeyActions]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return <></>;
}

export default Keys;
