import { useEffect, useRef, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { isBlank, isNullOrUndefined } from "../../utils";
import { getProfilePictureUpload } from "../../api";
import { ReactComponent as Upload } from "../../assets/upload.svg";
import { useTranslation } from "react-i18next";

function ProfileImageUpload() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { profilePictureUpload } = mainContext;
  const fileRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  function handleUploadFile() {
    setIsUploading(true);
    getProfilePictureUpload(dispatch);
  }

  useEffect(() => {
    if (!isNullOrUndefined(profilePictureUpload)) {
      if (!isBlank(profilePictureUpload.uploadUrl)) {
        var file = fileRef.current.files[0];
        var reader = new FileReader();

        function handleComplete(message) {
          setIsUploading(false);
          dispatch({
            type: "setAlertMessage",
            alertMessage: message,
          });
          setIsUpdating(true);
          // Attempt to reload with new profile pic
          setTimeout(() => {
            const cb = Math.round(Date.now() / 1000);
            dispatch({
              type: "setProfilePictureCacheBuster",
              value: cb,
            });
            setIsUpdating(false);
          }, 5000);
        }

        async function upload(uploadUrl, fileData, fileType) {
          try {
            const response = await fetch(uploadUrl, {
              method: "PUT",
              headers: {
                "Content-Type": fileType,
                "Cache-Control": "no-cache",
              },
              body: fileData,
            });
            await response.blob();
            if (response.ok) {
              handleComplete(t("uploaded_image_message"), true);
            } else {
              handleComplete(t("uploaded_image_error"));
            }
          } catch (error) {
            handleComplete(t("uploaded_image_error"));
          }
        }
        const fileType = file.type;
        reader.onload = function () {
          var arrayBuffer = reader.result;
          var bytes = new Uint8Array(arrayBuffer);
          upload(profilePictureUpload.uploadUrl, bytes, fileType);
        };
        reader.readAsArrayBuffer(file);
      }

      dispatch({
        type: "setProfilePictureUpload",
        value: null,
      });
    }
  }, [dispatch, profilePictureUpload, t]);

  return (
    <div className="profile-image-upload">
      <label>{t("change_picture")}</label>
      <div>
        <button
          className="alternate"
          onClick={() => {
            fileRef.current.click();
          }}
          disabled={isUploading || isUpdating}
        >
          <Upload style={{ marginRight: "8px" }} />
          {!isUploading && !isUpdating && <>{t("upload_from_device")}</>}
          {isUploading && <i>{t("uploading")}</i>}
          {isUpdating && <i>{t("updating")}</i>}
        </button>
        <input
          className="not-visible"
          type="file"
          ref={fileRef}
          accept=".png,.jpeg,.jpg"
          onChange={handleUploadFile}
        />
      </div>
    </div>
  );
}

export default ProfileImageUpload;
