import { Trans, useTranslation } from "react-i18next";
import MapDynamicStyle from "../../assets/map-dynamic-style.gif";
import MapStandardStyle from "../../assets/map-standard-style.jpg";
import BigButton from "../BigButton";
import { SettingNames, SettingsManager } from "../../SettingsManager";
import { MapStyles } from "../../constants";
import { useState } from "react";
import { useMainDispatchContext } from "../../MainContext";

function MapStyle() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();

  const mapStyle = SettingsManager.getSetting(SettingNames.MAP_STYLE.name);
  const [selectedMapStyle, setSelectedMapStyle] = useState(mapStyle);
  const [hasChanges, setHasChanges] = useState(false);

  function handleUpdateSettings(newValue) {
    setSelectedMapStyle(newValue);
    const origValue = SettingsManager.getSetting(SettingNames.MAP_STYLE.name);
    setHasChanges(origValue !== newValue);
  }
  function saveSettings() {
    SettingsManager.setSetting(SettingNames.MAP_STYLE.name, selectedMapStyle);
    setHasChanges(false);

    dispatch({
      type: "setAlertMessage",
      alertMessage: t("saved_web", { name: t("map_style") }),
    });
  }

  return (
    <div className="map-style">
      <div>
        <div className="map-style-header">
          <h3>{t("map_style")}</h3>
          <span>{t("map_style_subheading")}</span>
        </div>

        <div className="map-style-content">
          <div className="map-style-content-row">
            <div>
              <BigButton
                label={t("dynamic_lighting_style")}
                imageSrc={MapDynamicStyle}
                selected={selectedMapStyle === MapStyles.DYNAMIC}
                onSelect={(selectedValue) => {
                  if (selectedMapStyle === MapStyles.DYNAMIC) return;
                  handleUpdateSettings(
                    selectedValue ? MapStyles.DYNAMIC : MapStyles.STANDARD
                  );
                }}
              />
            </div>
            <div>
              <Trans
                i18nKey={"dynamic_light_style_description_web"}
                values={{ name: t("dynamic_lighting_style") }}
              />
            </div>
          </div>
          <div className="map-style-content-row">
            <div>
              <BigButton
                label={t("standard_style")}
                imageSrc={MapStandardStyle}
                selected={selectedMapStyle === MapStyles.STANDARD}
                onSelect={(selectedValue) => {
                  if (selectedMapStyle === MapStyles.STANDARD) return;
                  handleUpdateSettings(
                    selectedValue ? MapStyles.STANDARD : MapStyles.DYNAMIC
                  );
                }}
              />
            </div>
            <div>
              <Trans
                i18nKey={"standard_style_description_web"}
                values={{ name: t("standard_style") }}
              />
            </div>
          </div>
          <div className="map-style-actions">
            <button
              className="primary"
              disabled={!hasChanges}
              onClick={saveSettings}
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapStyle;
