import { useCallback, useEffect, useRef, useState } from "react";
import { isNullOrUndefined } from "../../utils";
import { getTimelineFromTemplate } from "../../templateUtils";
import { useTranslation } from "react-i18next";

const TIMELINE_INTERVAL = 10; // mins
const DEFAULT_TIMELINE_WIDTH = 900; // pixels

function TemplateTimeline(props) {
  const { t } = useTranslation();
  const {
    template,
    onSelect,
    selectedOperationUuid,
    enableSelect,
    targetBlockTime,
  } = props;
  const containerRef = useRef(null);
  const timelineBarsRef = useRef(null);
  const [timelineWidth, setTimelineWidth] = useState(DEFAULT_TIMELINE_WIDTH);
  const [gridHeight, setGridHeight] = useState(null);
  const [showReferenceMarkers, setShowReferenceMarkers] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      containerRef.current.getBoundingClientRect();
      const width = containerRef.current.getBoundingClientRect().width;
      setTimelineWidth(width);

      timelineBarsRef.current.getBoundingClientRect();
      const height = timelineBarsRef.current.getBoundingClientRect().height;
      setGridHeight(height);
    };
    setTimeout(() => {
      handleResize();
      setShowReferenceMarkers(true);
    }, 100);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef, timelineBarsRef]);

  useEffect(() => {
    if (!isNullOrUndefined(containerRef.current)) {
      containerRef.current.getBoundingClientRect();
      const width = containerRef.current.getBoundingClientRect().width;
      setTimelineWidth(width);
    }
  }, [containerRef]);

  useEffect(() => {
    if (!isNullOrUndefined(timelineBarsRef.current)) {
      timelineBarsRef.current.getBoundingClientRect();
      const height = timelineBarsRef.current.getBoundingClientRect().height;
      setGridHeight(height);
    }
  }, [timelineBarsRef]);

  const resizeElements = useCallback(() => {
    containerRef.current.getBoundingClientRect();
    const width = containerRef.current.getBoundingClientRect().width;
    setTimelineWidth(width);

    timelineBarsRef.current.getBoundingClientRect();
    const height = timelineBarsRef.current.getBoundingClientRect().height;
    setGridHeight(height);
  }, [containerRef, timelineBarsRef]);

  useEffect(() => {
    resizeElements();
  }, [resizeElements, selectedOperationUuid]);

  if (isNullOrUndefined(template)) return null;

  const timelineInfo = getTimelineFromTemplate(template, targetBlockTime);

  const displayIntervalsPreArrival = Math.ceil(
    timelineInfo.maxPreArrival / TIMELINE_INTERVAL
  );
  const bufferFromStart =
    TIMELINE_INTERVAL +
    (displayIntervalsPreArrival * TIMELINE_INTERVAL -
      timelineInfo.maxPreArrival);
  const displayIntervalsTurn = Math.ceil(
    timelineInfo.minInnerDuration / TIMELINE_INTERVAL
  );
  const displayIntervalsPostDeparture = Math.ceil(
    timelineInfo.maxPostDeparture / TIMELINE_INTERVAL
  );

  const displayIntervals =
    displayIntervalsPreArrival +
    displayIntervalsTurn +
    displayIntervalsPostDeparture;

  // Add 2 for the start/end padding
  const intervalWidth = timelineWidth / (displayIntervals + 2);
  const timelineMinuteInPixels =
    timelineWidth / ((displayIntervals + 2) * TIMELINE_INTERVAL);

  const intervals = [];
  let maxLabel = 0;
  for (let i = 0; i < displayIntervalsPreArrival; i++) {
    const label = `${
      -1 * TIMELINE_INTERVAL * (displayIntervalsPreArrival - i)
    }`;
    intervals.push(label);
  }
  for (let i = 0; i < displayIntervalsTurn; i++) {
    const value = TIMELINE_INTERVAL * i;
    const label = `${value}`;
    intervals.push(label);
    maxLabel = value;
  }
  for (let i = 0; i < displayIntervalsPostDeparture; i++) {
    const value = TIMELINE_INTERVAL * (displayIntervalsTurn + i);
    const label = `${value}`;
    intervals.push(label);
    maxLabel = value;
  }
  const endLabel = maxLabel + TIMELINE_INTERVAL;

  const columnStyle = {
    gridTemplateColumns: `repeat(${intervals.length + 2}, minmax(0, 1fr))`,
  };

  const gridStyle = !isNullOrUndefined(gridHeight)
    ? {
        height: `${gridHeight}px`,
      }
    : {};
  const arrivalTimeStyle = {
    marginLeft: `${
      ((bufferFromStart + timelineInfo.maxPreArrival) / TIMELINE_INTERVAL) *
        intervalWidth -
      1
    }px`,
  };
  const departureTimeStyle = {
    marginLeft: `${
      ((bufferFromStart +
        timelineInfo.maxPreArrival +
        timelineInfo.minInnerDuration) /
        TIMELINE_INTERVAL) *
        intervalWidth -
      2
    }px`,
  };
  return (
    <div className="template-timeline">
      {timelineInfo.operationsToRender.length === 0 && (
        <div className="empty-state">{t("no_operations")}</div>
      )}
      {timelineInfo.operationsToRender.length > 0 && (
        <div className="template-timeline-panels">
          <div className="template-timeline-side-panel">
            <div>
              {timelineInfo.operationsToRender &&
                timelineInfo.operationsToRender.map((operationInfo) => (
                  <div
                    className="template-timeline-left-label"
                    key={operationInfo.operation.uuid}
                  >
                    {operationInfo.operation.name}
                  </div>
                ))}
            </div>
          </div>

          <div ref={containerRef}>
            <div className="timeline-content">
              <div className="timeline-bars" ref={timelineBarsRef}>
                {showReferenceMarkers && (
                  <>
                    <div className="timeline-ref-line" style={arrivalTimeStyle}>
                      <div>
                        <div className="timeline-ref-label">
                          <div>{t("arrival")}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="timeline-ref-line"
                      style={departureTimeStyle}
                    >
                      <div>
                        <div className="timeline-ref-label">
                          <div>{t("departure")}</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="timeline-bar-top"></div>
                {timelineInfo.operationsToRender &&
                  timelineInfo.operationsToRender.map((operationInfo) => {
                    const barStyle = {
                      marginLeft: `${
                        ((operationInfo.timeFromAbsStart + bufferFromStart) /
                          TIMELINE_INTERVAL) *
                          intervalWidth -
                        1
                      }px`,
                    };
                    const hasDuration = !isNullOrUndefined(
                      operationInfo.operation.duration
                    );
                    if (hasDuration) {
                      barStyle.width = `${
                        operationInfo.operation.duration *
                        timelineMinuteInPixels
                      }px`;
                    }
                    return (
                      <div
                        className={`timeline-bar${
                          !hasDuration ? " no-duration" : ""
                        }${
                          selectedOperationUuid === operationInfo.operation.uuid
                            ? " selected"
                            : ""
                        }`}
                        style={barStyle}
                        key={operationInfo.operation.uuid}
                        onClick={() => {
                          if (!enableSelect) return;
                          onSelect(operationInfo.operation);
                        }}
                      >
                        {!hasDuration && (
                          <div className="timeline-marker"></div>
                        )}
                        <div className="timeline-label">
                          {isNullOrUndefined(operationInfo.operation.duration)
                            ? ""
                            : `${operationInfo.operation.duration}m`}
                        </div>
                      </div>
                    );
                  })}
                <div className="timeline-bar-bottom"></div>
              </div>
            </div>
            <div className="timeline-grid" style={gridStyle}>
              {/* Label row for the grid header with buffer at start/end */}
              <div className="timeline-grid-row" style={columnStyle}>
                <div className="timeline-grid-cell">
                  <span className="start" title="Minutes">
                    &nbsp;
                  </span>
                </div>
                {intervals &&
                  intervals.map((interval, idx) => (
                    <div key={idx} className="timeline-grid-cell">
                      <span>{`${interval}m`}</span>
                    </div>
                  ))}
                <div className="timeline-grid-cell">
                  <span>{`${endLabel}m`}</span>
                </div>
              </div>
              {/* Empty row for the grid body with buffer at start/end */}
              <div className="timeline-grid-row" style={columnStyle}>
                <div className="timeline-grid-cell">
                  <span></span>
                </div>
                {intervals &&
                  intervals.map((interval, idx) => (
                    <div
                      key={idx}
                      className="timeline-grid-cell"
                      data-interval={interval}
                    >
                      <span></span>
                    </div>
                  ))}
                <div className="timeline-grid-cell">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TemplateTimeline;
