import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getAlertPreferences, saveAlertPreferences } from "../../api";
import {
  deepCopy,
  filterList,
  getByFieldValue,
  isBlank,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import ToggleSwitch from "../ToggleSwitch";

function AlertPreferences() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { alertPreferences } = mainContext;

  const [currentSettings, setCurrentSettings] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    getAlertPreferences(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const settingsList = !isNullOrUndefined(alertPreferences)
      ? deepCopy(alertPreferences)
      : [];
    sortByField(settingsList, "name");
    const filteredList = filterList(
      settingsList,
      (item) => !isBlank(item.name)
    );
    setCurrentSettings(filteredList);
    setIsModified(false);
    setIsSaving(false);
  }, [alertPreferences]);

  function handleChange(alertType, isEnabled) {
    const updatedSettings = deepCopy(currentSettings);
    const settingToUpdate = getByFieldValue(
      updatedSettings,
      "alertType",
      alertType
    );
    settingToUpdate.enabled = isEnabled;
    setCurrentSettings(updatedSettings);
    setIsModified(true);
  }

  function handleToggleAll(isEnabled) {
    const updatedSettings = deepCopy(currentSettings);
    for (let i = 0; i < updatedSettings.length; i++) {
      const settingToUpdate = updatedSettings[i];
      settingToUpdate.enabled = isEnabled;
      setCurrentSettings(updatedSettings);
    }
    setIsModified(true);
  }
  function checkAllSelected() {
    for (let i = 0; i < currentSettings.length; i++) {
      if (!currentSettings[i].enabled) return false;
    }
    return true;
  }

  async function handleSave() {
    setIsSaving(true);
    const enabledSettings = filterList(currentSettings, (item) => item.enabled);
    const enabledList = enabledSettings.map((item) => item.alertType);
    const result = await saveAlertPreferences(dispatch, {
      enabled: enabledList,
    });

    if (result) {
      dispatch({
        type: "setAlertMessage",
        alertMessage: t("saved_web", { name: t("alerts") }),
      });
      // Refetch
      await getAlertPreferences(dispatch);
    }
    setIsSaving(false);
  }

  const buttonDisabled = isSaving || !isModified;
  const isAllSelected = checkAllSelected();

  return (
    <div className="notifications-settings">
      <div>
        <div className="notifications-settings-header">
          <h3>{t("alerts")}</h3>
          <span>{t("update_alerts_notification")}</span>
        </div>
        <div className="notifications-settings-content">
          {currentSettings.length > 0 && (
            <div className="notifications-settings-content-row">
              <div>{t("show_all_alerts")}</div>
              <div>
                <ToggleSwitch
                  isSelected={isAllSelected}
                  onChange={() => {
                    handleToggleAll(!isAllSelected);
                  }}
                />
              </div>
            </div>
          )}
          {currentSettings &&
            currentSettings.map((item) => (
              <div
                className="notifications-settings-content-row"
                key={item.alertType}
              >
                <div>{item.name}</div>
                <div>
                  <ToggleSwitch
                    isSelected={item.enabled}
                    onChange={() => {
                      handleChange(item.alertType, !item.enabled);
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="notifications-settings-actions">
          <button onClick={handleSave} disabled={buttonDisabled}>
            {t("save")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AlertPreferences;
