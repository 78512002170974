import { isNullOrUndefined } from "./utils";

export function isAlertListSame(a, b) {
  const aList = !isNullOrUndefined(a) ? a.map((i) => new Alert(i)) : null;
  const bList = !isNullOrUndefined(b) ? b.map((i) => new Alert(i)) : null;
  if (aList === null && bList === null) return true;
  if (
    (aList !== null && bList === null) ||
    (aList === null && bList !== null) ||
    aList.length !== bList.length
  ) {
    return false;
  }
  const hasDifferences = aList.some((item, idx) => {
    return !item.isEqual(bList[idx]);
  });
  return !hasDifferences;
}

export function hasUnreadAlerts(alerts) {
  if (isNullOrUndefined(alerts)) return false;
  return alerts.some((alert) => !alert.read);
}

class Alert {
  constructor(item) {
    this.timestamp = item.timestamp;
    this.title = item.title;
    this.message = item.message;
    this.userAlertTypeId = item.userAlertTypeId;
    this.turnaroundUuid = item.turnaroundUuid;
    this.read = item.read;
  }
  isEqual(other) {
    return (
      this.timestamp === other.timestamp &&
      this.title === other.title &&
      this.message === other.message &&
      this.userAlertTypeId === other.userAlertTypeId &&
      this.turnaroundUuid === other.turnaroundUuid &&
      this.read === other.read
    );
  }
}
