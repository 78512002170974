import "./styles.css";
import CompanyBG from "../../assets/ornament.png";

import { useMainContext, useMainDispatchContext } from "../../MainContext";
import PageToolbar from "../PageToolbar";
import { useEffect, useRef, useState } from "react";
import { isNullOrUndefined } from "../../utils";
import {
  getFlights,
  getPositions,
  getTurnaroundsSummary,
  getUsers,
  getVehicles,
  getWeather,
} from "../../api";
import TurnaroundChartWidget from "./TurnaroundChartWidget";
import TurnaroundCardsWidget from "./TurnaroundCardsWidget";
import WeatherWidget from "./WeatherWidget";
import LoadingIndicator from "../LoadingIndicator";
import { usePostHog } from "posthog-js/react";
import { DEFAULT_TIMEZONE, FEATURE_FLAGS } from "../../constants";
import { useFeatureFlagPayload } from "posthog-js/react";
import ReleaseNotes from "./ReleaseNotes";
import { useTranslation } from "react-i18next";
import ResourcesWidget from "./ResourcesWidget";

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const posthog = usePostHog();
  const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(true);
  const {
    currentUser,
    weather,
    flights,
    vehicles,
    turnaroundsSummary: turnarounds,
    flightsLoading,
    weatherLoading,
    dynamicEnv,
    users,
    positions,
  } = mainContext;
  const hasFlights = !isNullOrUndefined(flights) && flights.length > 0;
  const hasWeather = !isNullOrUndefined(weather);
  const chartBoxRef = useRef(null);

  useEffect(() => {
    getWeather(dispatch);
    getVehicles(dispatch);
    getTurnaroundsSummary(dispatch);
    getFlights(dispatch);
    getUsers(dispatch);
    getPositions(dispatch, {});
  }, [dispatch]);

  const releaseNotes = useFeatureFlagPayload(FEATURE_FLAGS.WEB_RELEASE_NOTES);
  const webVersion = dynamicEnv?.webVersion;
  function handleCloseReleaseNotes() {
    setIsReleaseNotesOpen(false);
    posthog?.identify(currentUser.uuid, {
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
      deploymentUserGroupUuid: currentUser.userGroupUuid,
      dismissedWebReleaseNotes: webVersion,
    });
  }

  if (isNullOrUndefined(currentUser)) return null;
  const { airport, company } = currentUser;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  const welcomeMsg = t("welcome_back", { name: currentUser.firstName });

  return (
    <div className=" dashboard">
      <div className="dashboard-container">
        <PageToolbar title={welcomeMsg} />
        <div className="dashboard-widgets">
          {isReleaseNotesOpen && releaseNotes && (
            <ReleaseNotes
              releaseNotes={releaseNotes}
              onClose={handleCloseReleaseNotes}
            />
          )}
          <div
            className="widget company-info"
            style={{
              backgroundImage: `url(${CompanyBG})`,
            }}
          >
            <span>{t("organization")}</span>
            <span>{t("airport")}</span>
            <span>{t("terminal")}</span>
            <h3>{company.name}</h3>
            <h3>{airport.iata}</h3>
            <h3>&mdash;</h3>
          </div>
          <div className="widget weather-info">
            {weatherLoading && <LoadingIndicator />}
            {!weatherLoading && hasWeather && (
              <WeatherWidget weather={weather} />
            )}
          </div>
          <div className="widget vehicle-info">
            <div className="widget-header">
              <h2>{t("resources")}</h2>
            </div>
            <ResourcesWidget
              vehicles={vehicles}
              users={users}
              positions={positions}
            />
          </div>
          <div className="widget turnaround-info">
            <div className="widget-header">
              <h2>{t("peak_hours")}</h2>
            </div>
            <div className="chart" ref={chartBoxRef}>
              {flightsLoading && <LoadingIndicator />}
              {!flightsLoading && hasFlights && (
                <TurnaroundChartWidget
                  flights={flights}
                  airportTimezone={airportTimezone}
                />
              )}
            </div>
          </div>
          <div className="widget turnaround-cards">
            <TurnaroundCardsWidget
              turnarounds={turnarounds}
              airportTimezone={airportTimezone}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
