import { useTranslation } from "react-i18next";
import { useMainDispatchContext } from "../../MainContext";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { ReactComponent as Turnaround } from "../../assets/turnarounds/turnaround.svg";
import { EMPTY_STAND, EMPTY_TIME } from "../../constants";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import { formatTime, isBlank, isNullOrUndefined } from "../../utils";
import TurnaroundProgressBar from "../Turnarounds/TurnaroundProgressBar";

function TurnaroundInfoModule(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const { turnaroundInfo } = props;
  const airportTimezone = turnaroundInfo.airportTimezone;

  const inboundName = !isNullOrUndefined(turnaroundInfo.inboundFlightName)
    ? turnaroundInfo.inboundFlightName
    : "-";
  const outboundName = !isNullOrUndefined(turnaroundInfo.outboundFlightName)
    ? turnaroundInfo.outboundFlightName
    : "-";
  const landingTime = !isNullOrUndefined(turnaroundInfo.landing)
    ? formatTime(turnaroundInfo.landing, airportTimezone, true)
    : EMPTY_TIME;

  const takeoffTime = !isNullOrUndefined(turnaroundInfo.takeoff)
    ? formatTime(turnaroundInfo.takeoff, airportTimezone, true)
    : EMPTY_TIME;

  const arrivalStand = !isBlank(turnaroundInfo?.arrivalStand?.name)
    ? turnaroundInfo?.arrivalStand.name
    : EMPTY_STAND;

  const departureStand = !isBlank(turnaroundInfo?.departureStand?.name)
    ? turnaroundInfo?.departureStand.name
    : EMPTY_STAND;

  const hasOutbound = !isBlank(turnaroundInfo.outboundFlightName);

  const turnaroundLevelOperationStatus =
    turnaroundInfo?.turnaroundLevelOperationStatus;

  return (
    <div
      className={`overlay-module${
        !isBlank(turnaroundLevelOperationStatus?.primaryStatus)
          ? ` status-${turnaroundLevelOperationStatus?.primaryStatus}`
          : ""
      }`}
      data-id={turnaroundInfo.uuid}
      data-inbound-id={turnaroundInfo?.inboundFlightId}
      data-outbound-id={turnaroundInfo?.outboundFlightId}
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
            item: {
              aircraft: {
                registration: turnaroundInfo.registration,
              },
            },
          },
        });
      }}
      onMouseOver={(e) => {
        e.target.classList.add("hovered");
      }}
      onMouseOut={(e) => {
        e.target.classList.remove("hovered");
      }}
    >
      <div className="overlay-module-box">
        <div className="turnaround-flight-info">
          <div>
            <Arrival />
          </div>
          <div>
            <div className="value-pair">
              <label>{t("inbound")}</label>
              <div className="value">{inboundName}</div>
            </div>
          </div>
          <div>
            <div className="value-pair">
              <label>{t("arrival")}</label>
              <div className="value">{landingTime}</div>
            </div>
          </div>
          <div>
            <div className="value-pair">
              <label>{t("stand_non_cap")}</label>
              <div className="value">{arrivalStand}</div>
            </div>
          </div>
        </div>
        <div className="turnaround-info">
          <div>
            <Turnaround />
          </div>
          <div>
            <TurnaroundProgressBar
              turnaroundInfo={turnaroundInfo}
              turnaroundLevelOperationStatus={true}
              showOperationStatus={true}
            />
          </div>
        </div>

        {hasOutbound && (
          <div className="turnaround-flight-info">
            <div>
              <Departure />
            </div>
            <div>
              <div className="value-pair">
                <label>{t("outbound")}</label>
                <div className="value">{outboundName}</div>
              </div>
            </div>
            <div>
              <div className="value-pair">
                <label>{t("departure")}</label>
                <div className="value">{takeoffTime}</div>
              </div>
            </div>
            <div>
              <div className="value-pair">
                <label>{t("stand_non_cap")}</label>
                <div className="value">{departureStand}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default TurnaroundInfoModule;
