import { useRef } from "react";
import { registerTheme } from "echarts";
import ReactECharts from "echarts-for-react";
import MoonwareTheme from "../../assets/themes/moonwareTheme.json";
import moment from "moment-timezone";
import { formatDate, isNullOrUndefined } from "../../utils";
import {
  CHART_COLOR_ALL,
  CHART_SERIES_COLORS,
  METRICS_TERMS,
} from "../../constants";
import { useTranslation } from "react-i18next";

function TurnaroundOperationsTrend(props) {
  const { t } = useTranslation();
  const { airportTimezone, chartData, startTime, endTime, selectedOperations } =
    props;
  registerTheme("Moonware", MoonwareTheme.theme);
  const chartRef = useRef();
  const dateValues = Object.keys(chartData);
  dateValues.sort((a, b) => {
    const val1 = new Date(a);
    const val2 = new Date(b);
    if (val1 === val2) return 0;
    return val1 < val2 ? -1 : 1;
  });
  const buckets = [];
  const aggregateBuckets = chartData?.operationsAggregate?.buckets;

  if (
    !isNullOrUndefined(chartData.buckets) &&
    chartData.buckets.length > 0 &&
    !isNullOrUndefined(endTime)
  ) {
    const dateCounter = moment(startTime).tz(airportTimezone).startOf("day");
    const lastDate = endTime.startOf("day");
    const bucketsByDate = {};
    chartData.buckets.reduce((acc, value) => {
      acc[value.label] = value;
      return acc;
    }, bucketsByDate);

    const allOperations = !isNullOrUndefined(chartData.operations)
      ? chartData.operations
      : [];
    while (dateCounter.isBefore(lastDate) || dateCounter.isSame(lastDate)) {
      const label = dateCounter.format("yyyy-MM-DD");
      const dataPoint = {
        label: label,
      };
      dataPoint[METRICS_TERMS.ALL_OPERATIONS] = 0;
      // Add in a data point for each operation for the given day
      for (let i = 0; i < allOperations.length; i++) {
        const operation = allOperations[i];
        const bucket = operation.buckets.find(
          (bucket) => bucket.label === label
        );
        dataPoint[operation.name] = !isNullOrUndefined(bucket)
          ? Math.round(bucket.onTimePercent)
          : "-";
      }
      // Also update the aggregate
      const aggBucket = aggregateBuckets.find(
        (bucket) => bucket.label === label
      );
      dataPoint[METRICS_TERMS.ALL_OPERATIONS] = !isNullOrUndefined(aggBucket)
        ? Math.round(aggBucket.onTimePercent)
        : "-";
      buckets.push(dataPoint);
      dateCounter.add(1, "day");
    }
  }
  const chartDateValues = [];
  const bucketsByOperationName = {};
  bucketsByOperationName[METRICS_TERMS.ALL_OPERATIONS] = [];

  for (let i = 0; i < buckets.length; i++) {
    const bucket = buckets[i];
    chartDateValues.push(bucket.label);
    bucketsByOperationName[METRICS_TERMS.ALL_OPERATIONS].push(
      bucket[METRICS_TERMS.ALL_OPERATIONS]
    );

    const allOperations = !isNullOrUndefined(chartData.operations)
      ? chartData.operations
      : [];
    for (let i = 0; i < allOperations.length; i++) {
      const operation = allOperations[i];
      const operationName = operation.name;
      const bucketForOperation = bucketsByOperationName[operationName];
      if (isNullOrUndefined(bucketForOperation)) {
        bucketsByOperationName[operationName] = [];
      }
      bucketsByOperationName[operationName].push(bucket[operationName]);
    }
  }
  const all = generateSeriesConfig(
    METRICS_TERMS.ALL_OPERATIONS,
    bucketsByOperationName[METRICS_TERMS.ALL_OPERATIONS]
  );
  // Include which series to show
  const series = [all];
  if (!isNullOrUndefined(selectedOperations)) {
    for (let i = 0; i < selectedOperations.length; i++) {
      const selectedOperation = selectedOperations[i];
      series.push(
        generateSeriesConfig(
          selectedOperation,
          bucketsByOperationName[selectedOperation],
          i
        )
      );
    }
  }
  const option = {
    axisPointer: {
      shadowStyle: {
        color: "rgba(23, 121, 218, 0.25)",
      },
      label: {
        backgroundColor: "rgba(23, 121, 218, 0.5)",
      },
    },
    tooltip: {
      borderColor: "#424959",
      backgroundColor: "#161721",
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "rgba(23, 121, 218, .75)",
        },
        label: {
          formatter: (params) => {
            return typeof params.value === "string"
              ? `${formatDate(
                  moment(params.value).tz(airportTimezone).toDate(),
                  airportTimezone
                )}\n`
              : `${Math.round(params.value)}%`;
          },
        },
      },
      textStyle: {
        color: "#ffffff",
      },
    },

    legend: {
      show: false,
    },
    xAxis: [
      {
        type: "category",
        data: chartDateValues,
        axisPointer: {
          type: "shadow",
        },
        axisLabel: {
          formatter: (value) => {
            return `${formatDate(
              moment(value).tz(airportTimezone).toDate(),
              airportTimezone
            )}`;
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: "",
        min: 0,
        max: 100,
        interval: 20,
        axisLabel: {
          formatter: (value) => {
            return `${value}%`;
          },
        },
        splitLine: {
          lineStyle: {
            color: "#4A4C56",
            type: [1, 5],
            width: 2,
            cap: "round",
          },
        },
      },
    ],
    series: series,
    animationDuration: 250,
  };

  if (!isNullOrUndefined(chartRef.current)) {
    chartRef.current.getEchartsInstance();
  }

  return (
    <div className="chart-container">
      <div className="chart-title-bar">
        <h4>{t("operation_on_time_performance")}</h4>
      </div>
      {chartDateValues.length === 0 && (
        <div className="empty-state">{t("no_results_for_selected_range")}</div>
      )}
      {chartDateValues.length > 0 && (
        <div className="chart-component">
          <ReactECharts
            ref={chartRef}
            option={option}
            style={{ height: "380px", width: "100%" }}
            theme="Moonware"
            notMerge={true}
          />
        </div>
      )}
    </div>
  );
}

function generateSeriesConfig(name, data, idx) {
  const itemStyle = {
    borderRadius: 5,
    opacity: "1.0",
  };
  if (isNullOrUndefined(idx)) {
    itemStyle.color = CHART_COLOR_ALL;
  } else if (idx < CHART_SERIES_COLORS.length) {
    itemStyle.color = CHART_SERIES_COLORS[idx];
  }
  return {
    name: name,
    type: "line",
    smooth: 0.25,
    tooltip: {
      valueFormatter: function (value) {
        return value === "-" ? value : `${value}%`;
      },
    },
    data: data,
    itemStyle: itemStyle,
    emphasis: {
      itemStyle: {
        opacity: "1.0",
      },
    },
    lineStyle: {
      width: 3,
    },
    symbolSize: 8,
    showSymbol: false,
  };
}
export default TurnaroundOperationsTrend;
