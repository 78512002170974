import { useEffect, useState, useRef, useMemo } from "react";
import {
  filterBySearchQuery,
  isBlank,
  isNullOrUndefined,
  listWithMaxLength,
  sortByField,
} from "../../utils";
import CrewInfo from "../CrewInfo";
import { ReactComponent as Search } from "../../assets/search.svg";
import { ReactComponent as Cross } from "../../assets/circle-cross-dark.svg";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../userUtils";
import { UserDispatchStatus } from "../../constants";

const MAX_RESULTS = 6;

function SelectCrewMember(props) {
  const {
    onSelect,
    onCancel,
    certification,
    crewAssignments,
    users,
    positions,
    turnarounds,
    isAssignMode,
    disabled,
  } = props;
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsUnavailable, setSearchResultsUnavailable] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const [availableCrew, unavailableCrew] = useMemo(() => {
    const userInfoList = [];
    const availableCrewList = [];
    const unavailableCrewList = [];
    if (!isNullOrUndefined(users)) {
      const excludedUsers = crewAssignments?.map((item) => item.user);
      // Filter out excludedUsers
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        if (!excludedUsers?.some((item) => item?.uuid === user.uuid)) {
          const userInfo = getUserInfo(user, turnarounds, positions);
          userInfoList.push(userInfo);
        }
      }
    }

    // Include only certified crew members
    for (let i = 0; i < userInfoList.length; i++) {
      const userInfo = userInfoList[i];
      const user = userInfo.user;
      if (
        user.certifications.some((item) => item.uuid === certification.uuid)
      ) {
        // Certifications OK, bucket by available
        if (
          userInfo.dispatchStatus === UserDispatchStatus.AVAILABLE ||
          userInfo.dispatchStatus ===
            UserDispatchStatus.AVAILABLE_DURING_TURNAROUND
        ) {
          availableCrewList.push(userInfo);
        } else {
          unavailableCrewList.push(userInfo);
        }
      }
    }
    sortByField(availableCrewList, "fullName", ["dispatchStatus"]);
    sortByField(unavailableCrewList, "fullName", ["dispatchStatus"]);
    return [availableCrewList, unavailableCrewList];
  }, [users, positions, turnarounds, certification, crewAssignments]);

  const handleSearchUsers = (value) => {
    setSearchTerm(value);

    const results = filterBySearchQuery(
      value,
      availableCrew,
      "fullName",
      MAX_RESULTS
    );
    setSearchResults(results);

    const resultsUnavailable = filterBySearchQuery(
      value,
      unavailableCrew,
      "fullName",
      MAX_RESULTS
    );
    setSearchResultsUnavailable(resultsUnavailable);
  };

  const showSuggested = isBlank(searchTerm);
  const availableList = showSuggested
    ? listWithMaxLength(availableCrew, MAX_RESULTS)
    : searchResults;
  const unavailableList = showSuggested
    ? listWithMaxLength(unavailableCrew, MAX_RESULTS)
    : searchResultsUnavailable;
  const availableSectionTitle = showSuggested
    ? t("recommended_certified_crew")
    : t("certified_available_crew");

  const buttonLabel = isAssignMode ? t("assign") : t("add_non_cap");

  return (
    <div className="select-crew">
      <div className="select-crew-search">
        <div className="search-input">
          <div className="select-crew-container">
            <div>
              <Search />
            </div>
            <div>
              <input
                ref={inputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  handleSearchUsers(e.target.value);
                }}
                placeholder={t("search_crew_member")}
              />
            </div>
            <div>
              <Cross
                onClick={() => {
                  handleSearchUsers("");
                  inputRef.current.focus();
                  if (!isNullOrUndefined(onCancel)) {
                    onCancel();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="select-crew-results">
        {availableList?.length === 0 && unavailableList?.length === 0 && (
          <div>
            <div className="de-emphasize">No results</div>
          </div>
        )}
        {availableList?.length > 0 && (
          <>
            <div>
              <div className="section-label">{availableSectionTitle}</div>
            </div>
            {availableList &&
              availableList.map((info) => (
                <div
                  className="select-crew-result-row"
                  key={info.uuid}
                  onClick={() => {
                    const user = info.user;
                    onSelect(user);
                  }}
                >
                  <div>
                    <CrewInfo
                      user={info.user}
                      details={t(info.dispatchStatus)}
                      mapStatus={info.mapStatus}
                    />
                    <div>
                      <button
                        className="semiblue"
                        onClick={() => {
                          onSelect(info);
                          inputRef.current.focus();
                        }}
                        disabled={disabled}
                      >
                        {buttonLabel}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
        {unavailableList?.length > 0 && (
          <>
            <div>
              <div className="section-label">
                {t("certified_unavailable_crew")}
              </div>
            </div>
            {unavailableList &&
              unavailableList.map((info) => (
                <div
                  className="select-crew-result-row not-available"
                  key={info.uuid}
                  onClick={() => {
                    const user = info.user;
                    onSelect(user);
                  }}
                >
                  <div>
                    <CrewInfo
                      user={info.user}
                      details={t(info.dispatchStatus)}
                      mapStatus={info.mapStatus}
                    />
                    <div>
                      <button
                        className="semiblue"
                        onClick={() => {
                          onSelect(info);
                          inputRef.current.focus();
                        }}
                        disabled={disabled}
                      >
                        {buttonLabel}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}

export default SelectCrewMember;
