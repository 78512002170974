import { useMainContext } from "../../MainContext";
import { isNullOrUndefined } from "../../utils";
import "./styles.css";

import { useLocation } from "react-router-dom";
import CriticalBanner from "./CritcalBanner";
import MoveAircraftReminderBanner from "./MoveAircraftReminderBanner";

// Persistent type of banner that has a CTA
function ActionBanner() {
  const mainContext = useMainContext();
  const location = useLocation();
  const isOnAllocationsPage = location?.pathname === "/allocations";

  const { notification, moveAircraftReminder, isMoveAircraftPanelOpen } =
    mainContext;

  const showCriticalBanner =
    !isNullOrUndefined(notification) && !isOnAllocationsPage;
  const showReminderBanner =
    !isNullOrUndefined(moveAircraftReminder) && !isMoveAircraftPanelOpen;

  if (!showCriticalBanner && !showReminderBanner) return null;
  return (
    <div className="action-banner">
      <div className="action-banner-content">
        {showCriticalBanner && <CriticalBanner notification={notification} />}
        {!showCriticalBanner && showReminderBanner && (
          <MoveAircraftReminderBanner />
        )}
      </div>
    </div>
  );
}
export default ActionBanner;
