import Checkbox from "../Checkbox";
import "./styles.css";
function CheckboxFilter(props) {
  const { isChecked, changeHandler, label } = props;
  return (
    <div
      className={`checkbox-filter${isChecked ? " active" : ""}`}
      onClick={() => {
        changeHandler();
      }}
    >
      <div>
        <div>
          <Checkbox isChecked={isChecked} changeHandler={changeHandler} />
        </div>
        <div className="checkbox-filter-label">{label}</div>
      </div>
    </div>
  );
}

export default CheckboxFilter;
