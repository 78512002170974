import { useTranslation } from "react-i18next";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { formatDatetime, formatTime, isBlank } from "../../utils";

function TurnaroundSection(props) {
  const {
    type,
    flightName,
    gate,
    gateTime,
    landing,
    takeoff,
    origin,
    destination,
    timezone,
  } = props;
  const isInboundFlight = type === "inboundFlight";

  const { t } = useTranslation();

  return (
    <div className="turnaround-section">
      <div className="icon">
        {type === "inboundFlight" && <Arrival />}
        {type === "outboundFlight" && <Departure />}
      </div>
      <div className="info-box">
        <span>{`${
          isInboundFlight ? t("arriving_non_cap") : t("departing_non_cap")
        }`}</span>
        <h3>{flightName}</h3>
      </div>
      <div className="info-box">
        <div>
          <span>{`${
            isInboundFlight ? t("arrival_gate_abbr") : t("departure_gate_abbr")
          }`}</span>
        </div>
        <h3>{`${!isBlank(gate) ? gate : "-"}`}</h3>
      </div>
      <div className="info-box outlined">
        <span>{`${
          isInboundFlight ? t("coming_from_non_cap") : t("heading_to_non_cap")
        }`}</span>

        {isInboundFlight && (
          <h3 title={origin}>{`${!isBlank(origin) ? origin : "-"}`}</h3>
        )}
        {!isInboundFlight && (
          <h3 title={destination}>
            {`${!isBlank(destination) ? destination : "-"}`}
          </h3>
        )}
      </div>
      <div className="info-box outlined">
        <span>{`${
          isInboundFlight ? t("gate_in_non_cap") : t("gate_out_non_cap")
        }`}</span>
        <h3
          title={`${
            !isBlank(gateTime) ? formatDatetime(gateTime, timezone) : "-"
          }`}
        >
          {`${!isBlank(gateTime) ? formatTime(gateTime, timezone, true) : "-"}`}
        </h3>
      </div>
      <div className="info-box outlined">
        <span>{`${
          isInboundFlight ? t("landing_non_cap") : t("takeoff_non_cap")
        }`}</span>
        <h3
          title={`${
            !isBlank(landing) ? formatDatetime(landing, timezone) : "-"
          }`}
        >{`${
          !isBlank(takeoff) ? formatTime(takeoff, timezone, true) : "-"
        }`}</h3>
      </div>
    </div>
  );
}

export default TurnaroundSection;
