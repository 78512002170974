import { useEffect, useState } from "react";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  getPositions,
  getTurnaroundAllocations,
  getTurnaroundsSummary,
} from "../../api";
import { POLLING_INTERVALS } from "../../constants";
import { useCommsContext, useCommsDispatchContext } from "../../CommsContext";
import { getChannels } from "../../commsApi";
import { getCriteriaForPositions } from "../../mapUtils";
import { useLocation } from "react-router-dom";

/* Component that just polls/fetches data that can be used globally by components */
function ConsoleDataHelper() {
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    turnaroundsSummaryLoading,
    positionsSaving,
    turnaroundAllocationsLoading,
    isRefreshPaused,
    vehicles,
    flightsToRender,
    showMapInfo,
    showMapRoutes,
  } = mainContext;
  const commsDispatch = useCommsDispatchContext();
  const commsContext = useCommsContext();
  const { channelsLoading, commsChannelMessagePostSaving } = commsContext;
  const location = useLocation();

  const [refreshPositionsRequested, setRefreshPositionsRequested] =
    useState(false);
  const [commsChannelsRequested, setCommsChannelsRequested] = useState(false);
  const [
    refreshTurnaroundsSummaryRequested,
    setRefreshTurnaroundsSummaryRequested,
  ] = useState(false);
  const [
    refreshTurnaroundAllocationsRequested,
    setRefreshTurnaroundAllocationsRequested,
  ] = useState(false);

  // Refresh channels
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (
      !channelsLoading &&
      !commsChannelMessagePostSaving &&
      commsChannelsRequested
    ) {
      getChannels(commsDispatch);
      setCommsChannelsRequested(false);
    }
  }, [
    commsDispatch,
    commsChannelsRequested,
    channelsLoading,
    commsChannelMessagePostSaving,
    isRefreshPaused,
  ]);

  // Refresh positions for dispatch
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!refreshPositionsRequested) return () => {};
    if (positionsSaving) return () => {};
    const criteria = getCriteriaForPositions(
      vehicles,
      flightsToRender,
      showMapInfo,
      showMapRoutes
    );
    getPositions(dispatch, criteria);
    setRefreshPositionsRequested(false);
  }, [
    dispatch,
    positionsSaving,
    refreshPositionsRequested,
    isRefreshPaused,
    location,
    vehicles,
    flightsToRender,
    showMapInfo,
    showMapRoutes,
  ]);

  // Refresh TurnarnoundsSummary (previously Flights to Render)
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (turnaroundsSummaryLoading) return () => {};
    if (!refreshTurnaroundsSummaryRequested) return () => {};
    getTurnaroundsSummary(dispatch);
    setRefreshTurnaroundsSummaryRequested(false);
  }, [
    dispatch,
    turnaroundsSummaryLoading,
    refreshTurnaroundsSummaryRequested,
    isRefreshPaused,
  ]);

  // Refresh turnaround allocations
  useEffect(() => {
    if (isRefreshPaused) return () => {};
    if (!refreshTurnaroundAllocationsRequested) return () => {};
    if (!turnaroundAllocationsLoading) {
      getTurnaroundAllocations(dispatch);
      setRefreshTurnaroundAllocationsRequested(false);
    }
  }, [
    dispatch,
    turnaroundAllocationsLoading,
    refreshTurnaroundAllocationsRequested,
    isRefreshPaused,
  ]);

  // Setup intervals here (only once)
  useEffect(() => {
    const intervals = [];

    if (POLLING_INTERVALS.MAP_POSITIONS > 0) {
      const interval = setInterval(() => {
        setRefreshPositionsRequested(true);
      }, POLLING_INTERVALS.MAP_POSITIONS);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.COMMS > 0) {
      const interval = setInterval(() => {
        setCommsChannelsRequested(true);
      }, POLLING_INTERVALS.COMMS);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.TURNAROUNDS_SUMMARY > 0) {
      const interval = setInterval(() => {
        setRefreshTurnaroundsSummaryRequested(true);
      }, POLLING_INTERVALS.TURNAROUNDS_SUMMARY);
      intervals.push(interval);
    }

    if (POLLING_INTERVALS.ALLOCATIONS > 0) {
      const interval = setInterval(() => {
        setRefreshTurnaroundAllocationsRequested(true);
      }, POLLING_INTERVALS.ALLOCATIONS);
      intervals.push(interval);
    }
    return () => {
      for (let i = 0; i < intervals.length; i++) {
        clearInterval(intervals[i]);
      }
    };
  }, []);

  return <></>;
}
export default ConsoleDataHelper;
